import { Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IAverageWidget } from '../../../../../shared-resources/INTERFACES';
import { AverageItem } from './AverageItem';

interface Props {
  data?: IAverageWidget;
}

export function AverageWidgetTopBar(props: Props): React.ReactElement | null {
  const { data } = props;
  const { t } = useTranslation();

  if (!data) return null;

  const {
    averageBankAccumulationPerMonth,
    averageExpensesPerMonth,
    averageIncomesPerMonth,
    averageSavingsPerMonth,
    averageNonPeriodicalExpensesPerMonth,
    averagePeriodicalExpensesPerMonth,
  } = data;
  const totalItems = 6;

  return (
    <Card>
      <CardContent>
        <Grid container>
          <AverageItem
            title={t('translations:widgets.averageWidget.averageIncomes')}
            value={averageIncomesPerMonth}
            totalItems={totalItems}
          />
          <AverageItem
            title={t('translations:widgets.averageWidget.averageExpenses')}
            value={averageExpensesPerMonth}
            totalItems={totalItems}
          />
          <AverageItem
            title={t('translations:common.nonPeriodicalExpenses')}
            value={averageNonPeriodicalExpensesPerMonth}
            totalItems={totalItems}
          />
          <AverageItem
            title={t('translations:common.periodicalExpenses')}
            value={averagePeriodicalExpensesPerMonth}
            totalItems={totalItems}
          />

          <AverageItem
            title={t('translations:widgets.averageWidget.averageSavings')}
            value={averageSavingsPerMonth}
            totalItems={totalItems}
          />
          <AverageItem
            title={t('translations:widgets.averageWidget.averageBankAccumulation')}
            value={averageBankAccumulationPerMonth}
            totalItems={totalItems}
          />
        </Grid>
      </CardContent>
    </Card>
  );
}
