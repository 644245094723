import {
  CircularProgress,
  CircularProgressProps,
  LinearProgress,
} from '@mui/material'
import * as React from 'react'

interface IProps {
  isCircular?: boolean
  circularSize?: number
}
export default function CustomLoading(
  props: IProps & CircularProgressProps
): React.ReactElement {
  const { isCircular, circularSize = 20 } = props
  return isCircular ? (
    <CircularProgress size={circularSize} {...props} />
  ) : (
    <LinearProgress sx={{ width: '100%' }} {...props} />
  )
}
