import { AppBar, Box, IconButton, hexToRgb } from '@mui/material';
import { useTheme } from '@mui/system';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { MainContext } from 'src/context/MainContext';
import { convertToRgbaBackground } from 'utils/themeUtils';
import { APP_BAR_HEIGHT, DRAWER_WIDTH } from '../../utils/constants';
import { COMMON_ICONS } from '../../utils/icons';
import AuthRenderGuard from '../AuthRenderGuard';
import CustomIcon from '../CustomIcon';
import CustomSpacer from '../CustomSpacer';
import PreserveLtr from '../PreserveLtr';
import { CustomResponsiveDrawer } from './CustomResponsiveDrawer';

type Props = {
  children: React.ReactNode;
};

export default function Layout(props: Props): React.ReactElement {
  const { children } = props;
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const { isLoggedIn } = React.useContext(MainContext);
  const muiTheme = useTheme();
  const rgbValues = hexToRgb(muiTheme.palette.background.paper);
  const rgbaBackground = convertToRgbaBackground(rgbValues, 0.7);

  React.useEffect(() => {
    setOpen(false);
  }, [location]);

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100%',
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          width: isLoggedIn ? { md: `calc(100% - ${DRAWER_WIDTH}px)` } : '100%',
          ml: { md: `${DRAWER_WIDTH}px` },
          bgcolor: rgbaBackground,
          backdropFilter: 'blur(4px)',
          padding: 2,
          height: APP_BAR_HEIGHT,
        }}
      >
        <PreserveLtr
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
            width: '100%',
          }}
        >
          <AuthRenderGuard>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={toggleDrawer}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <CustomIcon
                  size="xs"
                  style={{ width: '20px', height: '20px' }}
                  icon={COMMON_ICONS.MENU}
                />
              </IconButton>
            </Box>
          </AuthRenderGuard>
          <Box
            sx={{
              typography: 'h5',
              color: (theme) => theme.palette.primary.main,
            }}
          >
            Fany
          </Box>
        </PreserveLtr>
      </AppBar>
      <AuthRenderGuard>
        <Box component="nav" sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}>
          <CustomResponsiveDrawer
            handleDrawerToggle={toggleDrawer}
            open={open}
            onCloseDrawer={() => setOpen(false)}
          />
        </Box>
      </AuthRenderGuard>
      <CustomSpacer vertical customSize={10} />
      <Box
        sx={{
          px: { xs: 0, xl: 2 },
          width: (theme) => theme.breakpoints.values.xl,
          maxWidth: '100%',
          my: 0,
          mx: 'auto',
          pt: 8,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
