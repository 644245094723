import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import CustomIcon from 'components/CustomIcon';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from 'src/context/MainContext';
import { COMMON_ICONS } from 'utils/icons';
import { useAppSelector } from '../../hooks/useReactRedux';
import CustomButton from '../CustomButton';
import { CustomUserAvatar } from '../CustomUserAvatar';

export function CurrentUser() {
  const theme = useTheme();
  const { setLocalSettings, localSettings } = useContext(MainContext);
  const { user, currentHousehold } = useAppSelector((state) => state.initialData);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMaskAmountChange = () => {
    setLocalSettings((prev) => ({ ...prev, maskAmount: !prev.maskAmount }));
    handleClose();
  };

  return (
    <Stack
      sx={{
        p: 2,
      }}
    >
      <CustomButton
        sx={{
          backgroundColor: theme.palette.background.default,
          // m: 2,
          p: 2,
          borderRadius: 2,
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          textTransform: 'none',
        }}
        fullWidth
        onClick={handleClick}
      >
        <Stack direction="row" alignItems="center" width="100%" justifyContent="space-evenly">
          <CustomUserAvatar size="large" />
          <Stack direction="column" alignItems="center">
            <Typography variant="body1" fontWeight={500}>
              {user?.fullName}
            </Typography>
            <Typography color="gray" variant="body2">
              {currentHousehold?.name}
            </Typography>
          </Stack>
        </Stack>
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleMaskAmountChange} sx={{ display: 'flex', alignItems: 'center' }}>
          <ListItemIcon>
            <CustomIcon icon={COMMON_ICONS.MASK} />
          </ListItemIcon>
          <ListItemText>{t('translations:navigation.userMenu.hideAmount')}</ListItemText>
          <Switch size="small" checked={localSettings.maskAmount} />
        </MenuItem>
      </Menu>
    </Stack>
  );
}
