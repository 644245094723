import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box, lighten } from '@mui/system';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IOverallWidget } from '../../../../../../shared-resources/INTERFACES';
import CustomSpacer from '../../../../components/CustomSpacer';
import PreserveLtr from '../../../../components/PreserveLtr';
import { useCurrency } from '../../../../hooks/useCurrency';
import { ExpensesIndicator } from './ExpensesIndicator';

type TypoWrapperProps = {
  amount: number;
  title: string;
  colored?: boolean;
};

function TypoWrapper(props: TypoWrapperProps) {
  const { title, amount, colored } = props;
  const theme = useTheme();
  const { formatAmount } = useCurrency();

  return (
    <Stack direction="column" alignItems="flex-start">
      <Typography variant="caption" color="textSecondary" noWrap>
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color:
            // eslint-disable-next-line no-nested-ternary
            colored
              ? // eslint-disable-next-line no-nested-ternary
                amount === 0
                ? theme.palette.warning.main
                : amount > 0
                ? theme.palette.success.main
                : theme.palette.error.main
              : theme.palette.text.primary,
        }}
      >
        <PreserveLtr>{formatAmount(amount)}</PreserveLtr>
      </Typography>
    </Stack>
  );
}

interface SummaryProps {
  totalIncomes: number;
  bankAccountInEndOfPeriod: number;
  bankAccountBalances: number;
  upcomingExpenses: number;
  totalExpenses: number;
  bankAccountAccumulated: number;
  notRealizedExpenses: number;
  totalPeriodicalExpenses: number;
  totalNonPeriodicalExpenses: number;
  totalSavingsExpenses: number;
  widgetData?: IOverallWidget;
}
export default function Summary(props: SummaryProps): React.ReactElement {
  const {
    totalExpenses,
    bankAccountInEndOfPeriod,
    bankAccountAccumulated,
    notRealizedExpenses,
    totalPeriodicalExpenses,
    totalNonPeriodicalExpenses,
    totalSavingsExpenses,
    widgetData,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const balancesToMap = [
    {
      title: t('translations:period.summary.totalExpenses'),
      amount: totalExpenses,
      showIndicator: true,
    },
    {
      title: t('translations:common.totalNotExecute'),
      amount: notRealizedExpenses,
    },
    {
      title: t('translations:common.nonPeriodicalExpenses'),
      amount: totalNonPeriodicalExpenses,
    },
    {
      title: t('translations:common.periodicalExpenses'),
      amount: totalPeriodicalExpenses,
    },
    {
      title: t('translations:common.savings'),
      amount: totalSavingsExpenses,
    },
    {
      title: t('translations:period.summary.endOfPeriod'),
      amount: bankAccountInEndOfPeriod,
      colored: true,
    },
    {
      title: t('translations:period.summary.bankAccountAccumulated'),
      amount: bankAccountAccumulated,
      isAccumulated: true,
      colored: true,
    },
  ];

  return (
    <Stack
      direction="row"
      justifyContent={isMobile ? 'flex-start' : 'space-around'}
      flex={1}
      flexWrap={isMobile ? 'wrap' : 'nowrap'}
      flexGrow={1}
      alignItems={isMobile ? 'flex-start' : 'center'}
      sx={{ p: 0, height: '100%' }}
      gap={1}
    >
      {balancesToMap.map((balance) => (
        <Box
          key={balance.title}
          sx={{
            px: 2,
            py: isMobile ? 0.5 : 1,
            backgroundColor: lighten(theme.palette.background.default, 0.1),
            borderRadius: theme.spacing(1),
            flexGrow: 1,
            flexBasis: 0,
            maxWidth: '100%',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="flex-start">
            <TypoWrapper {...balance} />
            {balance.showIndicator && (
              <>
                <CustomSpacer horizontal size="small" />
                <ExpensesIndicator
                  totalExpenses={totalExpenses}
                  totalAverageExpenses={
                    widgetData?.averageWidget?.averageExpensesPerMonth || totalExpenses
                  }
                />
              </>
            )}
          </Stack>
        </Box>
      ))}
    </Stack>
  );
}
