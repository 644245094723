import { Avatar } from '@mui/material'
import { useAppSelector } from '../hooks/useReactRedux'

export enum SIZE_DICTIONARY {
  xsmall = 20,
  small = 30,
  medium = 40,
  large = 50,
}

interface BaseProps {
  size?: keyof typeof SIZE_DICTIONARY
}

interface ExternalUserMode extends BaseProps {
  profilePicURL?: string
  isExternalUser: true
  alt?: string
}

interface CurrentUserMode extends BaseProps {
  profilePicURL?: never
  isExternalUser?: never
  alt?: never
}

export const CustomUserAvatar = (props: CurrentUserMode | ExternalUserMode) => {
  const { size, profilePicURL, alt, isExternalUser: externalProfilePic } = props
  const { user } = useAppSelector((state) => state.initialData)
  const avatarSize = SIZE_DICTIONARY[size || 'small']
  const src = externalProfilePic ? profilePicURL : user?.profilePic
  const altText = externalProfilePic ? alt : user?.fullName

  return (
    <Avatar alt={altText} src={src} sx={{ width: avatarSize, height: avatarSize }}>
      {user?.fullName[0].toLocaleUpperCase()}
    </Avatar>
  )
}
