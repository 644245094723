import { Chip, Stack, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { NOTE_MODELS } from '../../../../../shared-resources/CONSTANTS'
import { IPopulatedNote } from '../../../../../shared-resources/INTERFACES'
import { CustomCard } from '../../../components/CustomCard'
import { CustomCardContent } from '../../../components/CustomCardContent'
import CustomSpacer from '../../../components/CustomSpacer'
import { CustomUserAvatar } from '../../../components/CustomUserAvatar'
import { i18n } from '../../../i18n/i18n'
import { APP_ROUTES, MANAGEMENT_TABS } from '../../../utils/constants'
import { getDate } from '../../../utils/helpers'

interface IProps {
  note: IPopulatedNote
  disableRedirect?: boolean
}

const modalNameDictionary = {
  [NOTE_MODELS.EXECUTION_UPDATE]: i18n.t('translations:common.executeUpdate'),
  [NOTE_MODELS.EXPENSE_SNAPSHOT]: i18n.t('translations:common.expense'),
}

export default function p({ note, disableRedirect }: IProps): React.ReactElement {
  const navigate = useNavigate()
  const theme = useTheme()

  const handleClick = () => {
    if (disableRedirect) return
    navigate(`/${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.NOTES}/${note._id}`)
  }

  return (
    <CustomCard
      onClick={handleClick}
      sx={{
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          cursor: 'pointer',
        },
      }}
    >
      <CustomCardContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="space-between">
            <div>
              <Chip
                size="small"
                label={modalNameDictionary[note.modalName]}
                sx={{
                  backgroundColor: theme.chip?.[note.modalName],
                  color: theme.palette.getContrastText(
                    theme.chip?.[note.modalName] || theme.palette.common.white
                  ),
                }}
              />
            </div>
            <CustomSpacer vertical size="small" />
            <Typography>{note.content}</Typography>
            <CustomSpacer vertical size="medium" />
            <Stack direction="row" alignItems="flex-end">
              <CustomUserAvatar
                profilePicURL={note.creatorRef?.profilePic}
                alt={note.creatorRef.fullName}
                isExternalUser
              />
              <CustomSpacer horizontal size="small" />
              <Typography variant="body2">{note.creatorRef.fullName}</Typography>
            </Stack>
            <CustomSpacer vertical size="small" />
            <Typography variant="body2">{getDate({ date: note.createdAt })}</Typography>
          </Stack>
        </Stack>
      </CustomCardContent>
    </CustomCard>
  )
}
