import { Box } from '@mui/material'
import { SxProps, Theme } from '@mui/system'
import * as React from 'react'

type Props = {
  children: React.ReactNode
  sx?: SxProps<Theme> | undefined
}
export default function PreserveLtr(props: Props): React.ReactElement {
  const { children, sx, ...rest } = props
  return (
    <Box component="div" dir="ltr" {...rest} sx={sx}>
      {children}
    </Box>
  )
}
