import { Typography } from '@mui/material'
import { useCurrency } from 'hooks/useCurrency'
import CustomTypography from './CustomTypography'
import PreserveLtr from './PreserveLtr'

interface Props extends React.ComponentProps<typeof Typography> {
  children: number
  searchValue?: string
}

export const AmountTypography = (props: Props) => {
  const { children, searchValue, ...rest } = props
  const { formatAmount } = useCurrency()

  return (
    <PreserveLtr>
      <CustomTypography {...rest} searchValue={searchValue}>
        {formatAmount(children)}
      </CustomTypography>
    </PreserveLtr>
  )
}
