/* eslint-disable no-unused-vars */
import mongoose from "mongoose";

import {
  AutoExecutedFileTypes,
  BALANCE_TYPES,
  COMMON_STATUES,
  EXPANSE_TYPES,
  NOTE_MODELS,
  SUPPORTED_CURRENCIES,
  USER_ROLE,
} from "./CONSTANTS";

export interface IUser extends DbBaseDoc {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  householdsRef: (mongoose.Types.ObjectId | string)[];
  preferredLanguage: string;
  status: COMMON_STATUES;
  role: USER_ROLE;
  fUid: string;
  profilePic?: string;
}

export interface IBalance extends DbBaseDoc {
  name: string;
  type: BALANCE_TYPES;
  householdRef: mongoose.Types.ObjectId | string;
  color: string;
}

export interface IBalanceSnapshot extends DbBaseDoc {
  balanceRef: IBalance | string;
  amount: number;
  householdRef: mongoose.Types.ObjectId | string;
  periodRef: mongoose.Types.ObjectId | string;
  realized?: boolean;
  ownerRefs?: (mongoose.Types.ObjectId | string)[];
}
export interface IPeriod extends DbBaseDoc {
  startDate: Date | string;
  endDate: Date | string;
  householdRef: mongoose.Types.ObjectId | string;
}
export interface IExpense extends DbBaseDoc {
  name: string;
  balanceRef?: mongoose.Types.ObjectId | string;
  householdRef: mongoose.Types.ObjectId | string;
  isPeriodical: boolean;
  type: EXPANSE_TYPES;
  businessNames?: string[];
}

export interface IExpenseSnapshot extends DbBaseDoc {
  expenseRef: mongoose.Types.ObjectId | string | IExpense;
  amount: number;
  householdRef: mongoose.Types.ObjectId | string;
  duoDate: Date | string;
  executionUpdatesRef?:
    | mongoose.Types.ObjectId[]
    | string[]
    | IExecutionUpdate[];
  periodRef: mongoose.Types.ObjectId | string;
  balanceSnapshotRef: mongoose.Types.ObjectId | string;
  savingRef?: mongoose.Types.ObjectId | string;
  notes: mongoose.Types.ObjectId[] | string[] | INote[];
  uuid?: string;
}
export interface IExecutionUpdate
  extends DbBaseDoc,
    Omit<Transaction, "_id" | "uuid" | "balanceSnapshotRef" | "amount"> {
  expenseSnapshotRef: mongoose.Types.ObjectId | string;
  amount: number;
  userRef: mongoose.Types.ObjectId | IUser | string;
  notes: mongoose.Types.ObjectId[] | string[] | INote[];
  uuid?: string;
  householdRef: mongoose.Types.ObjectId | string;
}

export interface IHousehold extends DbBaseDoc {
  name: string;
  currency: SUPPORTED_CURRENCIES;
  usersRef: mongoose.Types.ObjectId[];
}

export interface DbBaseDoc {
  _id: string;
  createdAt: string | Date;
  updatedAt: string | Date;
  status?: COMMON_STATUES;
}

export interface IBalanceSnapshotPopulated
  extends Omit<IBalanceSnapshot, "balanceRef" | "ownerRefs"> {
  balanceRef: IBalance;
  ownerRefs?: IUser[];
}
export interface IExpenseSnapshotPopulated
  extends Omit<
    IExpenseSnapshot,
    "expenseRef" | "executionUpdatesRef" | "balanceSnapshotRef"
  > {
  executionUpdatesRef: IExecutionUpdatePopulated[];
  balanceSnapshotRef: IBalanceSnapshotPopulated;
  expenseRef?: Omit<IExpense, "executionUpdatesRef">;
  averageExpenseAmount?: number;
}
export interface IUserPopulated extends Omit<IUser, "householdsRef"> {
  householdsRef: IHousehold[];
}

export interface IExecutionUpdatePopulated
  extends Omit<IExecutionUpdate, "userRef"> {
  userRef: Pick<IUser, "fullName" | "_id" | "profilePic">;
}

export type IPopulatedBalance = Omit<IBalance, "householdRef"> & {
  householdRef: IHousehold;
};

export type IPopulatedExpense = Omit<
  IExpense,
  "householdRef" | "balanceRef"
> & {
  householdRef: IHousehold;
  balanceRef?: IPopulatedBalance | undefined;
};

export interface INote extends DbBaseDoc {
  content: string;
  householdRef: mongoose.Types.ObjectId | string;
  creatorRef: mongoose.Types.ObjectId | string;
  documentRef?: mongoose.Types.ObjectId | string;
  modalName: NOTE_MODELS;
}

export interface IPopulatedNote extends Omit<INote, "creatorRef"> {
  creatorRef: IUser;
}

export interface IOverallWidget {
  overTimeWidget: IOverTimeWidget[];
  averageWidget: IAverageWidget;
}

export interface IIncomePerBalance {
  balanceRef: IBalance["_id"];
  total: IBalanceSnapshotPopulated["amount"];
  name: IBalance["name"];
}

export interface IIncomePerBalanceDictionary {
  [key: string]: IIncomePerBalance;
}

export interface IOverTimeWidget {
  periodRef: IPeriod;
  savingsCurrentAmount: number;
  expensesPerMonth: IExpenseSnapshotPopulated[];
  expenses: {
    total: number;
    creditCard: number;
    bank: number;
    savings: number;
    periodicalExpenses: number;
    nonPeriodicalExpenses: number;
  };
  incomes: {
    total: number;
    incomesPerBalance: IIncomePerBalanceDictionary;
  };
}

export interface IAverageWidget {
  averageExpensesPerMonth: number;
  averageExpensesWithoutSavingsPerMonth: number;
  averageIncomesPerMonth: number;
  averageSavingsPerMonth: number;
  averageBankAccumulationPerMonth: number;
  averagePeriodicalExpensesPerMonth: number;
  averageNonPeriodicalExpensesPerMonth: number;
  averageExpensesPerMonthPerBalance: {
    balanceRef: IBalance;
    amount: number;
  }[];
  averageIncomesPerMonthPerBalance: {
    balanceRef: IBalance;
    amount: number;
  }[];
  averageExpensesPerMonthPerExpense: {
    expenseRef: IExpense;
    amount: number;
  }[];
  averageExpensesPerMonthPerType: {
    type: EXPANSE_TYPES;
    amount: number;
  }[];
}

export interface Transaction {
  _id?: string;
  date?: string;
  businessName?: string;
  category?: string;
  cardNumber?: string;
  transactionType?: string;
  amount?: number;
  currency?: string;
  originalAmount?: number;
  originalAmountCurrency?: string;
  paymentDate?: string;
  transactionInfo?: string;
  paymentMethod?: string;
  exchangeRate?: string;
  uuid?: string;
  balanceSnapshotRef?: string;
}

export interface AutoExecutedTransaction {
  uuid: string;
  expenseRef: string;
  type: AutoExecutedFileTypes;
}

export interface CCName extends DbBaseDoc {
  name: string;
  balanceRef: string;
  status: COMMON_STATUES;
  householdRef: string;
}

export interface ExcludedBusinessName extends DbBaseDoc {
  name: string;
  householdRef: string;
}

export interface PopulateCCName extends Omit<CCName, "balanceRef"> {
  balanceRef: IBalance;
}

export interface Credentials {
  username: string;
  password: string;
}

export enum CompanyTypes {
  hapoalim = "hapoalim",
  hapoalimBeOnline = "hapoalimBeOnline",
  beinleumi = "beinleumi",
  union = "union",
  amex = "amex",
  isracard = "isracard",
  visaCal = "visaCal",
  max = "max",
  leumiCard = "leumiCard",
  otsarHahayal = "otsarHahayal",
  discount = "discount",
  mizrahi = "mizrahi",
  leumi = "leumi",
  massad = "massad",
  yahav = "yahav",
  beyahadBishvilha = "beyahadBishvilha",
  oneZero = "oneZero",
}

export interface ScraperSecret extends DbBaseDoc {
  companyType: CompanyTypes;
  householdRef: string;
}

export const enum EntityTypes {
  BALANCE = "balance",
  EXPENSE = "expense",
  EXPENSE_SNAPSHOT = "expense-snapshot",
  BALANCE_SNAPSHOT = "balance-snapshot",
}

interface IEventBalanceEntity {
  entityType: EntityTypes.BALANCE;
  entitySnapshot: IBalance;
  dataBefore: Partial<IBalance>;
  dataAfter: Partial<IBalance>;
}

interface IEventExpenseEntity {
  entityType: EntityTypes.EXPENSE;
  entitySnapshot: IExpense;
  dataBefore: Partial<IExpense>;
  dataAfter: Partial<IExpense>;
}

interface IEventExpenseSnapshotEntity {
  entityType: EntityTypes.EXPENSE_SNAPSHOT;
  entitySnapshot: IExpenseSnapshotPopulated;
  dataBefore: Partial<IExpenseSnapshotPopulated>;
  dataAfter: Partial<IExpenseSnapshotPopulated>;
}

interface IEventBalanceSnapshotEntity {
  entityType: EntityTypes.BALANCE_SNAPSHOT;
  entitySnapshot: IBalanceSnapshotPopulated;
  dataBefore: Partial<IBalanceSnapshotPopulated>;
  dataAfter: Partial<IBalanceSnapshotPopulated>;
}

export type EntityData =
  | IEventBalanceEntity
  | IEventExpenseEntity
  | IEventExpenseSnapshotEntity
  | IEventBalanceSnapshotEntity;

export interface IEvent extends DbBaseDoc {
  householdRef: mongoose.Types.ObjectId | string;
  timestamp: Date;
  operation: string;
  userRef: mongoose.Types.ObjectId | string;
  entityData: EntityData;
}

export type IEventPopulated = Omit<IEvent, "userRef" | "entityRef"> & {
  userRef: IUser;
};

export interface IPeriodExpensesComparison {
  expenseRef: string;
  expenseAmount: number;
  avgComparedPeriodsAmount: number;
  deviation: number;
}

export interface IAverageExpensesAmount {
  average: number;
  id: string;
}
