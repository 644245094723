import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Popover, Stack } from '@mui/material'
import * as React from 'react'
import { BlockPicker, ColorResult } from 'react-color'
import { useTranslation } from 'react-i18next'
import CustomButton from './CustomButton'
import CustomIcon from './CustomIcon'
import CustomSpacer from './CustomSpacer'

interface Props {
  onChangeComplete: <T>({ key, value }: { key: string; value: T }) => void
  color: string
}

export default function CustomColorPicker(props: Props): React.ReactElement {
  const { onChangeComplete, color } = props
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleColorChange = (newColor: ColorResult) => {
    onChangeComplete({ key: 'color', value: newColor.hex })
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      <CustomButton onClick={handleClick} variant="outlined" fullWidth>
        <Stack
          direction="row"
          spacing={1}
          sx={{ width: '100%' }}
          justifyContent="space-between"
        >
          <Stack direction="row">
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: 10,
                backgroundColor: color,
              }}
            />
            <CustomSpacer horizontal size="small" />
            {t('translations:common.selectColor')}
            <CustomSpacer horizontal size="medium" />
          </Stack>
          <CustomIcon icon={faChevronDown} />
        </Stack>
      </CustomButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <BlockPicker color={color} onChangeComplete={handleColorChange} />
      </Popover>
    </>
  )
}
