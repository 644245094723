/* eslint-disable no-restricted-syntax */
import * as xlsx from 'xlsx';
import { generateUUIDFromObject } from '../../../../shared-resources/CONSTANTS';
import { IExpenseSnapshotPopulated, Transaction } from '../../../../shared-resources/INTERFACES';

export type InputObject = Record<string, string | number>;

function isValidDate(dateString: string): boolean {
  const dateParts = dateString.split('-');

  // Ensure that the dateString has three parts separated by hyphens
  if (dateParts.length !== 3) {
    return false;
  }

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Months are 0-based in JavaScript Date object
  const year = parseInt(dateParts[2], 10);

  const date = new Date(year, month, day);

  return date.getDate() === day && date.getMonth() === month && date.getFullYear() === year;
}

function isObjectValid(obj: InputObject): boolean {
  let numProps = 0;
  let dateProps = 0;
  let stringProps = 0;

  for (const key in obj) {
    if (obj[key] === '') {
      delete obj[key];
    } else {
      const value = obj[key];
      if (typeof value === 'number') {
        numProps += 1;
      } else if (typeof value === 'string') {
        if (isValidDate(value)) {
          dateProps += 1;
        } else {
          stringProps += 1;
        }
      }
    }
  }

  const isValid = numProps >= 2 && dateProps >= 2 && stringProps >= 2;
  return isValid;
}

export function filterAndCleanArray(arr: InputObject[]): Transaction[] {
  const data = arr.filter(isObjectValid);

  return updateKeys(data, keyMap);
}

type KeyMap = {
  [key: string]: string;
};

const keyMap: KeyMap = {
  A: 'date',
  B: 'businessName',
  C: 'category',
  D: 'cardNumber',
  E: 'transactionType',
  F: 'amount',
  G: 'currency',
  H: 'originalAmount',
  I: 'originalAmountCurrency',
  J: 'paymentDate',
  K: 'transactionInfo',
  O: 'paymentMethod',
  P: 'exchangeRate',
};

interface CustomObject {
  [key: string]: unknown;
  businessName?: string;
  amount?: number;
  date?: string;
}

// eslint-disable-next-line @typescript-eslint/no-shadow
function updateKeys(array: Array<CustomObject>, keyMap: KeyMap): Array<CustomObject> {
  return array.map((item) => {
    const newItem: CustomObject = {};
    for (const key in item) {
      // eslint-disable-next-line no-prototype-builtins
      if (keyMap.hasOwnProperty(key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newItem[keyMap[key]] = (item as any)[key];
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newItem[key] = (item as any)[key];
      }
    }
    newItem.uuid = generateUUIDFromObject({
      businessName: newItem.businessName,
      amount: newItem.amount,
      date: newItem.date,
    });
    return newItem;
  });
}

export const formatFiles = (files: File[]): Promise<Transaction[]> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (fileEvent: ProgressEvent<FileReader>) => {
      const results = [];
      const data = fileEvent.target?.result;
      const workbook = xlsx.read(data, { type: 'array' });
      for (let index = 0; index < workbook.SheetNames.length; index += 1) {
        const sheetName = workbook.SheetNames[index];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, {
          blankrows: false,
          header: 'A',
          skipHidden: true,
        });
        results.push(...json);
      }
      const temp = filterAndCleanArray(results as InputObject[]);
      resolve(temp);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(files[0]);
  });

export function findKeyByValue(value: string, obj?: Record<string, string>): string | undefined {
  if (!obj) return;
  for (const [key, val] of Object.entries(obj)) {
    if (val === value) {
      return key;
    }
  }
  return undefined;
}

export const filterRows = ({
  files,
  expenses,
}: {
  files: Transaction[];
  expenses: IExpenseSnapshotPopulated[];
}) => {
  const expenseUUIDs = expenses.flatMap((expense) => [
    expense.uuid,
    ...expense.executionUpdatesRef.map((execution) => execution.uuid),
  ]);

  // filter out undefined uuids elements from the uuid array
  const filteredExpenseUUIDs = expenseUUIDs.filter((uuid) => Boolean(uuid));

  return files.filter((file) => !filteredExpenseUUIDs.includes(file.uuid));
};
