import { Button, IconButton, Popover } from '@mui/material';
import * as React from 'react';
import { FlexDiv } from '../styledComponents/FlexDiv';
import { ACTION_ITEMS } from '../utils/constants';
import { COMMON_ICONS } from '../utils/icons';
import CustomIcon from './CustomIcon';
import CustomSpacer from './CustomSpacer';

type ButtonColors =
  | 'error'
  | 'primary'
  | 'inherit'
  | 'info'
  | 'warning'
  | 'secondary'
  | 'success'
  | undefined;

type ButtonVariants = 'contained' | 'outlined' | 'text' | undefined;

type ButtonColorMappingType = {
  [key in ACTION_ITEMS]: ButtonColors;
};

type ButtonVariantMappingType = {
  [key in ACTION_ITEMS]: ButtonVariants;
};

const buttonColorsMapping: ButtonColorMappingType = {
  [ACTION_ITEMS.DELETE]: 'error',
  [ACTION_ITEMS.EDIT]: 'secondary',
  [ACTION_ITEMS.ADD_NOTE]: undefined,
  [ACTION_ITEMS.CLOSE_EXPENSE]: 'secondary',
  EXECUTE: undefined,
  PARTIAL_EXECUTE: undefined,
  ADD_PERIOD: undefined,
  DELETE_PERIOD: undefined,
  ADD_EXPENSE: undefined,
  EDIT_PERIOD: undefined,
  ADD_BALANCE: undefined,
  ADD_EXPENSE_FROM_FILE: undefined,
  EXCLUDE_BUSINESS_NAME: undefined,
  SELECT_EXPENSE_SNAP: undefined,
};

const buttonVariantMapping: ButtonVariantMappingType = {
  [ACTION_ITEMS.DELETE]: 'contained',
  [ACTION_ITEMS.EDIT]: 'outlined',
  [ACTION_ITEMS.EXECUTE]: 'outlined',
  [ACTION_ITEMS.PARTIAL_EXECUTE]: 'outlined',
  [ACTION_ITEMS.ADD_NOTE]: 'contained',
  [ACTION_ITEMS.CLOSE_EXPENSE]: 'outlined',
  ADD_PERIOD: undefined,
  DELETE_PERIOD: undefined,
  ADD_EXPENSE: undefined,
  EDIT_PERIOD: undefined,
  ADD_BALANCE: undefined,
  ADD_EXPENSE_FROM_FILE: undefined,
  EXCLUDE_BUSINESS_NAME: undefined,
  SELECT_EXPENSE_SNAP: undefined,
};

type Props = {
  actions: {
    label: string;
    action: ACTION_ITEMS;
    disabled?: boolean;
  }[];
  onClick: (action: ACTION_ITEMS) => void;
};

export default function CustomActionMoreButton(props: Props): React.ReactElement {
  const { actions, onClick } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleActionClick = (action: ACTION_ITEMS, event: React.MouseEvent<HTMLButtonElement>) => {
    onClick(action);
    handleClose(event);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          width: (theme) => theme.spacing(5),
          height: (theme) => theme.spacing(5),
        }}
      >
        <CustomIcon icon={COMMON_ICONS.MORE} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FlexDiv
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: 16,
          }}
        >
          {actions.map((action, index) => (
            <React.Fragment key={action.action}>
              <Button
                disabled={action.disabled}
                color={buttonColorsMapping?.[action.action]}
                variant={buttonVariantMapping?.[action.action]}
                fullWidth
                onClick={(event) => handleActionClick(action.action, event)}
              >
                {action.label}
              </Button>
              {index !== actions.length - 1 && <CustomSpacer vertical size="small" />}
            </React.Fragment>
          ))}
        </FlexDiv>
      </Popover>
    </>
  );
}
