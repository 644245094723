import { Card, CardContent, CardHeader } from '@mui/material';
import { CustomGenericSelection } from 'components/CustomGenericSelection';
import * as React from 'react';
import { IOverTimeWidget } from '../../../../shared-resources/INTERFACES';
import { LineChart, LineChartProps } from '../../components/Charts/LineChart';
import { i18n } from '../../i18n/i18n';
import { getDate } from '../../utils/helpers';

const formatOverTimeData = (
  data: IOverTimeWidget[],
  selectedSegment: Segment[]
): LineChartProps['data'] => {
  const incomes = {
    id: i18n.t('translations:common.incomes'),
    segment: Segment.INCOMES,
    data: data.map((item) => ({
      x: getDate({
        date: item.periodRef.startDate,
        format: 'MM/YYYY',
      }),
      y: item.incomes.total,
    })),
  };

  const expenses = {
    id: i18n.t('translations:common.expenses'),
    segment: Segment.EXPENSES,
    data: data.map((item) => ({
      x: getDate({
        date: item.periodRef.startDate,
        format: 'MM/YYYY',
      }),
      y: item.expenses.total,
    })),
  };

  const expensesWithoutSavings = {
    id: i18n.t('translations:period.summary.expensesWithoutSaving'),
    segment: Segment.EXPENSES_WITHOUT_SAVINGS,
    data: data.map((item) => ({
      x: getDate({
        date: item.periodRef.startDate,
        format: 'MM/YYYY',
      }),
      y: item.expenses.total - item.expenses.savings,
    })),
  };

  const savings = {
    id: i18n.t('translations:common.savings'),
    segment: Segment.SAVINGS,
    data: data.map((item) => ({
      x: getDate({
        date: item.periodRef.startDate,
        format: 'MM/YYYY',
      }),
      y: item.savingsCurrentAmount,
    })),
  };

  const incomeAfterExpenses = {
    id: i18n.t('translations:period.summary.bankAccountAccumulated'),
    segment: Segment.INCOME_AFTER_EXPENSES,
    data: data.map((item) => ({
      x: getDate({
        date: item.periodRef.startDate,
        format: 'MM/YYYY',
      }),
      y: item.incomes.total - item.expenses.total,
    })),
  };

  const allData = [incomes, expenses, savings, incomeAfterExpenses, expensesWithoutSavings];

  if (selectedSegment.length === Object.values(Segment).length) {
    return allData;
  }

  return allData.filter((item) => selectedSegment.includes(item.segment));
};

enum Segment {
  INCOMES = 'incomes',
  EXPENSES = 'expenses',
  SAVINGS = 'savings',
  INCOME_AFTER_EXPENSES = 'incomeAfterExpenses',
  EXPENSES_WITHOUT_SAVINGS = 'expensesWithoutSavings',
}
interface Props {
  data?: IOverTimeWidget[];
}

const OPTIONS = [
  {
    label: i18n.t('translations:common.incomes'),
    value: Segment.INCOMES,
  },
  {
    label: i18n.t('translations:common.expenses'),
    value: Segment.EXPENSES,
  },
  {
    label: i18n.t('translations:common.savings'),
    value: Segment.SAVINGS,
  },
  {
    label: i18n.t('translations:period.summary.bankAccountAccumulated'),
    value: Segment.INCOME_AFTER_EXPENSES,
  },
  {
    label: i18n.t('translations:period.summary.expensesWithoutSaving'),
    value: Segment.EXPENSES_WITHOUT_SAVINGS,
  },
];

export function OverTimeWidget(props: Props): React.ReactElement {
  const { data = [] } = props;
  const [selectedSegment, setSelectedSegment] = React.useState<Segment[]>(
    Object.values(Segment).filter((segment) => segment !== Segment.SAVINGS)
  );

  const handleChange = (value: Segment[]) => {
    if (value.length === 0) return;

    setSelectedSegment(value);
  };

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        title={
          <CustomGenericSelection
            options={OPTIONS}
            onChange={handleChange}
            selected={selectedSegment}
            multi
          />
        }
        sx={{
          pb: 0,
        }}
      />
      <CardContent
        sx={{
          height: 400,
          pt: 0,
        }}
      >
        <LineChart data={formatOverTimeData(data, selectedSegment)} />
      </CardContent>
    </Card>
  );
}
