import { Stack, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IExpenseSnapshotPopulated } from '../../../../../shared-resources/INTERFACES';
import CustomPaper from '../../../components/CustomPaper';
import CustomSpacer from '../../../components/CustomSpacer';
import ExecutionUpdateItem from './AmountUpdateItem';

type Props = {
  executionUpdates: IExpenseSnapshotPopulated['executionUpdatesRef'];
  onUpdate?: () => void;
  searchValue?: string;
};

export default function ExecutionUpdates(props: Props): React.ReactElement {
  const { executionUpdates, onUpdate, searchValue } = props;
  const { t } = useTranslation();
  const sortedExecutionUpdate = orderBy(executionUpdates, ['createdAt'], ['desc']);

  return (
    <CustomPaper
      sx={{
        padding: 0,
        maxHeight: 200,
        overflowY: 'auto',
        py: 1,
        bgcolor: 'background.paper',
      }}
    >
      {!sortedExecutionUpdate.length ? (
        <Typography
          sx={{
            p: 4,
          }}
        >
          {t('translations:expenses.actions.noExecutionUpdates')}
        </Typography>
      ) : null}
      {sortedExecutionUpdate.map(
        (amountUpdate: IExpenseSnapshotPopulated['executionUpdatesRef'][0]) => (
          <Stack key={amountUpdate._id.toString()}>
            <ExecutionUpdateItem
              executionUpdate={amountUpdate}
              searchValue={searchValue}
              onUpdate={onUpdate}
            />
            {sortedExecutionUpdate.indexOf(amountUpdate) !== sortedExecutionUpdate.length - 1 && (
              <CustomSpacer vertical size="small" />
            )}
          </Stack>
        )
      )}
    </CustomPaper>
  );
}
