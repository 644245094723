import { useAppSelector } from 'hooks/useReactRedux';
import { useSearchQueryParam } from 'hooks/useSearchQueryParam';
import { matchSorter } from 'match-sorter';
import { useDeferredValue } from 'react';
import { ExpanseItemMemo } from 'views/Period/ExpanseItem/ExpanseItem';
import { SearchBar } from 'views/Period/Expanses/SearchBar';
import CustomModal from './CustomModal';

interface Props {
  currentExecutionId: string;
  onClosed: () => void;
}
export const SelectExpenseSnapshot = (props: Props) => {
  const { currentExecutionId, onClosed } = props;
  const { expenses } = useAppSelector((state) => state.expenses);
  const { handleSearchChange, handleResetSearch, search } = useSearchQueryParam({
    queryKey: 'e-s',
  });
  const deferredValue = useDeferredValue(search);

  const sortedExpenses = matchSorter(expenses, deferredValue, {
    keys: [
      'expenseRef.name',
      'balanceSnapshotRef.balanceRef.name',
      'amount',
      'notes.*.content',
      'executionUpdatesRef.*.notes.*.content',
      'executionUpdatesRef.*.businessName',
      'executionUpdatesRef.*.amount',
    ],
  });

  return (
    <CustomModal open onClose={onClosed}>
      <SearchBar
        isSticky={false}
        search={search}
        onSearch={handleSearchChange}
        onResetSearch={handleResetSearch}
      />
      {sortedExpenses.map((expense) => {
        return (
          <ExpanseItemMemo
            key={expense._id.toString()}
            expense={expense}
            disableActions
            executeUpdateId={currentExecutionId}
            onMoved={onClosed}
            searchValue={search}
          />
        );
      })}
    </CustomModal>
  );
};
