import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BALANCE_TYPES } from '../../../../../shared-resources/CONSTANTS'
import CustomIcon from '../../../components/CustomIcon'
import CustomSpacer from '../../../components/CustomSpacer'
import { BALANCE_TYPE_LABELS } from '../../../utils/labels'
import { getIconsByType } from '../../Period/PeriodSummary/BalanceItem'

type Props = {
  onChange: (type: BALANCE_TYPES) => void
  selectedType?: BALANCE_TYPES
}

export const TypeWithTitle = ({ type }: { type: BALANCE_TYPES }) => {
  const icon = getIconsByType(type)
  return (
    <Stack direction="row" alignItems="center">
      {icon && <CustomIcon icon={icon} />}
      <CustomSpacer horizontal size="small" />
      <Typography>{BALANCE_TYPE_LABELS[type]}</Typography>
    </Stack>
  )
}

export function BalanceTypeSelection({ onChange, selectedType }: Props): React.ReactElement {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (balanceType: BALANCE_TYPES) => {
    onChange(balanceType)
    handleClose()
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        fullWidth
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {selectedType ? (
          <TypeWithTitle type={selectedType} />
        ) : (
          t('translations:balances.selectBalanceType')
        )}
        <CustomSpacer horizontal size="medium" />
        <CustomIcon icon={faChevronDown} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.keys(BALANCE_TYPES).map((type) => (
          <MenuItem
            key={type}
            onClick={() => handleChange(type as BALANCE_TYPES)}
            selected={type === selectedType}
          >
            <TypeWithTitle type={type as BALANCE_TYPES} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
