import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import CustomButton from './CustomButton';

interface IProps {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description?: string;
  isLoading?: boolean;
}

export default function CustomDialog({
  onClose,
  onConfirm,
  title,
  description,
  isLoading,
}: IProps) {
  const { t } = useTranslation();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.isDefaultPrevented()) {
      event.preventDefault();
      onConfirm();
    }
  };

  return (
    <Dialog open onClose={onClose} onKeyDown={handleKeyDown}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={onClose}>{t('translations:common.close')}</CustomButton>
        <CustomButton variant="contained" onClick={onConfirm} isLoading={isLoading}>
          {t('translations:common.agree')}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
