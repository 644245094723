/* eslint-disable max-len */
import { addDays, isWithinInterval } from "date-fns";
import { BALANCE_TYPES, EXPANSE_TYPES } from "./CONSTANTS";
import {
  IBalanceSnapshotPopulated,
  IExpenseSnapshotPopulated,
  IIncomePerBalanceDictionary,
} from "./INTERFACES";

/**
 * Calculates the remaining amount of an expense that can be executed.
 * It subtracts the total amount of executed updates from the initial expense amount.
 */
export const calculateRemainingExpenseAmount = (
  expense: IExpenseSnapshotPopulated
) => {
  // Total amount of executed updates
  const totalExecutedUpdatesAmount = expense?.executionUpdatesRef?.reduce(
    (acc, curr) => acc + curr.amount,
    0
  );
  const remainingExpenseAmount = expense.amount - totalExecutedUpdatesAmount;
  return { remainingExpenseAmount };
};

interface CalculateTotals {
  expenses: IExpenseSnapshotPopulated[];
  balances: IBalanceSnapshotPopulated[];
}

export const calculateTotals = ({ balances, expenses }: CalculateTotals) => {
  const totalCCExpenses = expenses
    .filter(
      (expense) =>
        expense.balanceSnapshotRef.balanceRef?.type === BALANCE_TYPES.CC
    )
    .reduce((acc, expense) => expense.amount + acc, 0);

  const totalBankExpenses = expenses
    .filter(
      (expense) =>
        expense.balanceSnapshotRef.balanceRef?.type === BALANCE_TYPES.BANK
    )
    .reduce((acc, expense) => expense.amount + acc, 0);

  const totalSavingsExpenses = expenses
    .filter(
      (expense) =>
        expense.savingRef || expense.expenseRef?.type === EXPANSE_TYPES.SAVINGS
    )
    .reduce((acc, expense) => expense.amount + acc, 0);

  const totalPeriodicalExpenses = expenses
    .filter((expense) => expense.expenseRef?.isPeriodical)
    .reduce((acc, expense) => expense.amount + acc, 0);

  const totalNonPeriodicalExpenses = expenses
    .filter((expense) => !expense.expenseRef?.isPeriodical)
    .reduce((acc, expense) => expense.amount + acc, 0);

  const incomesPerBalance = balances.reduce<IIncomePerBalanceDictionary>(
    (acc, curr) => {
      if (curr.balanceRef?.type === BALANCE_TYPES.INCOME) {
        const balanceId = curr.balanceRef._id.toString();
        if (!acc[balanceId]) {
          acc[balanceId] = {
            name: curr.balanceRef.name,
            total: curr.amount,
            balanceRef: balanceId,
          };
        } else {
          acc[balanceId].total += curr.amount;
        }
      }
      return acc;
    },
    {}
  );

  const totalIncomes = balances
    .filter((balance) => balance.balanceRef?.type === BALANCE_TYPES.INCOME)
    .reduce((acc, curr) => acc + curr.amount, 0);

  const notRealizedIncomes = balances
    .filter(
      (balance) =>
        balance.balanceRef?.type === BALANCE_TYPES.INCOME && !balance.realized
    )
    .reduce((acc, curr) => acc + curr.amount, 0);

  // Not realized expenses are CC expenses in case the balance is not realized and all other expenses
  const notRealizedExpenses = expenses.reduce((acc, curr) => {
    if (
      curr.balanceSnapshotRef.balanceRef?.type === BALANCE_TYPES.CC &&
      !curr.balanceSnapshotRef.realized
    ) {
      return acc + curr.amount;
    }
    const { remainingExpenseAmount } = calculateRemainingExpenseAmount(curr);
    return acc + remainingExpenseAmount;
  }, 0);

  const totalBankBalances = balances
    .filter((balance) => balance.balanceRef?.type === BALANCE_TYPES.BANK)
    .reduce((acc, curr) => acc + curr.amount, 0);

  const totalExpenses = totalCCExpenses + totalBankExpenses;
  const bankAccountInEndOfPeriod =
    totalBankBalances + notRealizedIncomes - notRealizedExpenses;
  const totalAccumulated = totalIncomes - totalExpenses;

  return {
    totalCCExpenses,
    totalBankExpenses,
    totalSavingsExpenses,
    totalPeriodicalExpenses,
    totalNonPeriodicalExpenses,
    totalExpenses,
    totalIncomes,
    notRealizedIncomes,
    notRealizedExpenses,
    totalBankBalances,
    bankAccountInEndOfPeriod,
    totalAccumulated,
    incomesPerBalance,
  };
};

import { parseISO } from "date-fns";

/**
 * Parses a date string using a safe approach - supports ISO 8601 and falls back to custom parsing for 'DD-MM-YYYY'.
 */
const safeParseDate = (dateStr: string): Date => {
  try {
    // First, attempt to parse assuming ISO 8601; fallback to custom if failure or invalid date
    const parsedDate = parseISO(dateStr);
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
  } catch (error) {
    // Log error or handle specific cases if needed
  }

  // Custom parsing for 'DD-MM-YYYY' format
  const [day, month, year] = dateStr.split("-").map(Number);
  return new Date(year, month - 1, day); // JavaScript months are 0-indexed
};

/**
 * Checks if a given date is between specified start and end dates.
 */
export const isDateBetween = ({
  transactionDate,
  currentPeriodStart,
  currentPeriodEnd,
}: {
  transactionDate: string;
  currentPeriodStart: string;
  currentPeriodEnd: string;
}): boolean => {
  const offsetDays = 5;
  try {
    const dateToCheck = safeParseDate(transactionDate);
    const startDate = addDays(new Date(currentPeriodStart), -offsetDays);
    const endDate = addDays(new Date(currentPeriodEnd), offsetDays);

    return isWithinInterval(dateToCheck, { start: startDate, end: endDate });
  } catch (error) {
    // Handle error or log error message
    console.error("Error occurred while checking date:", error);
    return false;
  }
};
