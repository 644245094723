import { Stack, Typography, useTheme } from '@mui/material'
import CustomSpacer from 'components/CustomSpacer'
import { CustomTag } from 'components/CustomTag'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { COMMON_STATUES } from '../../../../../shared-resources/CONSTANTS'
import { IBalance } from '../../../../../shared-resources/INTERFACES'
import { TypeWithTitle } from './BalanceTypeSelection'

interface Props {
  balance: IBalance
}

export function BaseBalance({ balance }: Props): React.ReactElement {
  const { t } = useTranslation()
  const isInActive = balance.status === COMMON_STATUES.INACTIVE
  const { palette } = useTheme()
  const { warning } = palette
  return (
    <Stack direction="row">
      <Stack direction="column">
        <Typography>{balance.name}</Typography>
        <TypeWithTitle type={balance.type} />
      </Stack>
      <CustomSpacer horizontal size="small" />
      {isInActive && (
        <CustomTag backgroundColor={warning.main}>{t('translations:common.inactive')}</CustomTag>
      )}
    </Stack>
  )
}
