import * as React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MainContext } from '../context/MainContext'
import { useAppSelector } from '../hooks/useReactRedux'
import { APP_ROUTES, MANAGEMENT_TABS } from '../utils/constants'
import { AddHousehold } from '../views/AddHousehold/AddHousehold'
import Dashboard from '../views/Dashboard/Dashboard'
import { History } from '../views/History/History'
import Home from '../views/Home'
import ExpensePage from '../views/Managmenet/Expenses/ExpansePage/ExpansePage'
import Management from '../views/Managmenet/Management'
import { NotePage } from '../views/Managmenet/Notes/NotePage'
import Period from '../views/Period/Period'
import { Settings } from '../views/Settings/Settings'
import Protected from './Protected'

export default function CustomRoutes(): React.ReactElement {
  const { user } = useAppSelector((state) => state.initialData)
  const { isLoggedIn } = React.useContext(MainContext)

  return isLoggedIn && !user?.householdsRef.length ? (
    <Routes>
      <Route path="*" element={<Navigate to={`/${APP_ROUTES.ADD_HOUSEHOLD}`} />} />
      <Route
        path={`/${APP_ROUTES.ADD_HOUSEHOLD}`}
        element={
          <Protected>
            <AddHousehold />
          </Protected>
        }
      />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path={`/${APP_ROUTES.DASHBOARD}`}
        element={
          <Protected>
            <Dashboard />
          </Protected>
        }
      />
      <Route
        path={`/${APP_ROUTES.SETTINGS}`}
        element={
          <Protected>
            <Settings />
          </Protected>
        }
      />
      <Route
        path={`/${APP_ROUTES.HISTORY}`}
        element={
          <Protected>
            <History />
          </Protected>
        }
      />
      <Route
        path={`/${APP_ROUTES.SETTINGS}/:tab`}
        element={
          <Protected>
            <Settings />
          </Protected>
        }
      />
      <Route
        path={`/${APP_ROUTES.SETTINGS}/:tab`}
        element={
          <Protected>
            <Settings />
          </Protected>
        }
      />
      <Route
        path={`/${APP_ROUTES.PERIOD}/:id`}
        element={
          <Protected>
            <Period />
          </Protected>
        }
      />
      <Route
        path={`/${APP_ROUTES.PERIOD}`}
        element={
          <Protected>
            <Period />
          </Protected>
        }
      />
      <Route
        path={`/${APP_ROUTES.MANAGEMENT}/:tab`}
        element={
          <Protected>
            <Management />
          </Protected>
        }
      />
      <Route
        path={`/${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.EXPENSES}/:id`}
        element={
          <Protected>
            <ExpensePage />
          </Protected>
        }
      />
      <Route
        path={`/${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.NOTES}/:id`}
        element={
          <Protected>
            <NotePage />
          </Protected>
        }
      />
      <Route
        path={`/${APP_ROUTES.MANAGEMENT}`}
        element={
          <Protected>
            <Management />
          </Protected>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
