import { configureStore } from '@reduxjs/toolkit'
import { allPeriodsSlice } from '../slices/allPeriodSlice'
import { balancesSlice } from '../slices/balancesSlice'
import { currentPeriodSlice } from '../slices/currentPeriodSlice'
import { expensesSlice } from '../slices/expensesSlice'
import { initialDataSlice } from '../slices/initialDataSlice'

export const store = configureStore({
  reducer: {
    initialData: initialDataSlice.reducer,
    balances: balancesSlice.reducer,
    expenses: expensesSlice.reducer,
    currentPeriod: currentPeriodSlice.reducer,
    allPeriod: allPeriodsSlice.reducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
