import { Stack, Typography } from '@mui/material'
import { IUser } from '../../../shared-resources/INTERFACES'
import CustomSpacer from './CustomSpacer'
import { CustomUserAvatar } from './CustomUserAvatar'

interface Props {
  user: IUser
}

export const User = (props: Props) => {
  const { user } = props

  return (
    <Stack direction="row" alignItems="flex-end">
      <CustomUserAvatar profilePicURL={user.profilePic} alt={user.fullName} isExternalUser />
      <CustomSpacer horizontal size="small" />
      <Typography variant="body2">{user.fullName}</Typography>
    </Stack>
  )
}
