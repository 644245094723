import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { COMMON_ICONS } from '../../utils/icons';
import { BaseRoute, ItemRoute } from '../../utils/inAppRoutes';
import CustomIcon from '../CustomIcon';
import CustomSpacer from '../CustomSpacer';

interface Props {
  path?: string;
  icon?: BaseRoute['icon'];
  name: string;
  onClick: () => void;
  isChild?: boolean;
  open?: boolean;
  isGroupHeader?: boolean;
  routes?: ItemRoute[];
}

export const NavItem = (props: Props): React.ReactElement => {
  const { routes, open, path, icon, name, onClick, isChild, isGroupHeader } = props;
  const { pathname } = useLocation();
  const isChildSelected = routes?.some(
    (route) => pathname.includes(route?.path)
    // eslint-disable-next-line function-paren-newline
  );
  const selected = path && pathname.includes(path);
  const theme = useTheme();

  return (
    <ListItem key={path} disablePadding>
      <ListItemButton selected={!!selected} onClick={onClick}>
        <ListItemIcon>
          {icon && (
            <CustomIcon
              icon={icon}
              color={selected || isChildSelected ? theme.palette.primary.main : undefined}
            />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction="row" alignItems="center">
              {isChild && selected && (
                <Box
                  sx={{
                    height: 4,
                    width: 4,
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '50%',
                    display: 'inline-block',
                  }}
                />
              )}
              <CustomSpacer horizontal size="small" />
              <Typography
                variant={isChild ? 'body2' : 'body1'}
                color={isChild ? theme.palette.text.secondary : theme.palette.text.primary}
                noWrap
              >
                {name}
              </Typography>
            </Stack>
          }
        />
        {isGroupHeader && (
          <CustomIcon
            icon={COMMON_ICONS.CHEVRON_DOWN}
            color={selected || isChildSelected ? theme.palette.primary.main : undefined}
            // Adding animations to the chevron icon
            style={{
              transition: 'transform 0.3s ease',
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};
