import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Button, Menu, MenuItem, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EXPANSE_TYPES } from '../../../../shared-resources/CONSTANTS'
import CustomIcon from '../../components/CustomIcon'
import CustomSpacer from '../../components/CustomSpacer'
import { EXPENSE_TYPE_EMOJIS } from '../../utils/icons'

type Props = {
  onChange: (expenseType: EXPANSE_TYPES) => void
  selectedType?: EXPANSE_TYPES
  disabled?: boolean
}

export const ExpenseTypeWithTitle = ({ type }: { type: EXPANSE_TYPES }) => {
  const { t } = useTranslation()
  return (
    <Stack direction="row">
      {EXPENSE_TYPE_EMOJIS?.[type as keyof typeof EXPENSE_TYPE_EMOJIS]}
      <CustomSpacer horizontal size="small" />
      {t(`translations:expenses.expenseType.${type.toLowerCase()}`)}
    </Stack>
  )
}

export function TypeSelection(props: Props): React.ReactElement {
  const { onChange, selectedType, disabled } = props
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (expenseType: EXPANSE_TYPES) => {
    onChange(expenseType)
    handleClose()
  }

  return (
    <>
      <Button
        disabled={disabled}
        variant="outlined"
        onClick={handleOpen}
        fullWidth
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {selectedType ? (
          <ExpenseTypeWithTitle type={selectedType} />
        ) : (
          t('translations:expenses.addEditExpense.selectBalance')
        )}
        <CustomSpacer horizontal size="medium" />
        <CustomIcon icon={faChevronDown} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.values(EXPANSE_TYPES).map((type) => (
          <MenuItem key={type} onClick={() => handleChange(type)} selected={type === selectedType}>
            <ExpenseTypeWithTitle type={type} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
