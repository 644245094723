import { Stack, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useLocalStorageState } from 'hooks/useLocalStorageState';
import React, { ReactElement, useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CustomLoading from './components/CustomLoading';
import { AxiosInterceptors } from './components/CustomSnackbar';
import Layout from './components/Layout/Layout';
import CustomRoutes from './components/Routes';
import { FirebaseContext } from './context/FirebaseContext';
import { MainContext } from './context/MainContext';
import { useAppDispatch, useAppSelector } from './hooks/useReactRedux';
import { getInitialDataThunk } from './slices/initialDataSlice';
import { retrieveTheme } from './theme/customTheme';
import { LocalSettings } from './types/localSettings';
import { LocalStorageKeys } from './types/localStorageKeys';

function App(): ReactElement {
  const { initializing, firebaseUser } = useContext(FirebaseContext);
  const dispatch = useAppDispatch();
  const { loading, user } = useAppSelector((state) => state.initialData);
  const [mode, setMode] = useLocalStorageState<'light' | 'dark'>(
    LocalStorageKeys.THEME_MODE,
    'light'
  );
  const { theme } = retrieveTheme(mode);
  const isLoggedIn = Boolean(firebaseUser && user);
  const showLoading = initializing || loading || (firebaseUser && !user);
  const [localSettings, setLocalSettings] = React.useState<LocalSettings>({
    maskAmount: false,
  });

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    if (initializing) return;
    if (firebaseUser) dispatch(getInitialDataThunk());
  }, [firebaseUser, initializing, dispatch]);

  return (
    <MainContext.Provider value={{ isLoggedIn, toggleColorMode, localSettings, setLocalSettings }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <Stack
            sx={{
              bgcolor: theme?.palette?.background?.default,
            }}
          >
            <Router>
              {showLoading ? (
                <CustomLoading />
              ) : (
                <Layout>
                  <CustomRoutes />
                </Layout>
              )}
            </Router>
          </Stack>
          <AxiosInterceptors />
        </ThemeProvider>
      </LocalizationProvider>
    </MainContext.Provider>
  );
}

export default App;
