export const namespaces = {
  pages: {
    hello: 'pages.hello',
  },
  common: 'common',
}

export const languages = {
  iw: 'iw',
  en: 'en',
}
