import { Paper, SxProps } from '@mui/material';
import * as React from 'react';

type Props = {
  children: React.ReactNode;
  sx?: SxProps;
};

export default function CustomPaper(props: Props): React.ReactElement {
  const { children, sx } = props;
  return (
    <Paper
      sx={{
        bgcolor: (theme) => theme.palette.background.default,
        padding: (theme) => theme.spacing(1),
        textAlign: 'center',
        color: (theme) => theme.palette.text.secondary,
        ...sx,
      }}
      elevation={2}
    >
      {children}
    </Paper>
  );
}
