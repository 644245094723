import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowRightToBracket,
  faArrowTrendDown,
  faArrowTrendUp,
  faArrowUp,
  faBars,
  faBuildingColumns,
  faCalendarAlt,
  faCalendarPlus,
  faCartPlus,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCoins,
  faCreditCardAlt,
  faEdit,
  faEllipsisV,
  faEyeSlash,
  faFileExcel,
  faGaugeHigh,
  faGears,
  faHomeAlt,
  faInfoCircle,
  faMinus,
  faMoon,
  faPencilAlt,
  faPiggyBank,
  faSquarePlus,
  faSun,
  faSyncAlt,
  faTableColumns,
  faTrash,
  faUndoAlt,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { BALANCE_TYPES, EXPANSE_TYPES } from '../../../shared-resources/CONSTANTS';
import { ACTION_ITEMS } from './constants';

export const ROUTES_ICONS = {
  DASHBOARD: faGaugeHigh,
  PERIOD: faCalendarAlt,
  MANAGEMENT: faHomeAlt,
  LOGIN: faArrowRightToBracket,
  SETTINGS: faGears,
  HISTORY: faUndoAlt,
};

export const COMMON_ICONS = {
  MENU: faBars,
  PERIODICAL: faSyncAlt,
  MORE: faEllipsisV,
  EDIT: faEdit,
  ARROW_RIGHT: faArrowRight,
  ARROW_LEFT: faArrowLeft,
  DELETE: faTrash,
  REALIZE_INCOME: faCheckCircle,
  ARROW_DOWN: faArrowDown,
  ARROW_UP: faArrowUp,
  NOTE: faPencilAlt,
  CHEVRON_DOWN: faChevronDown,
  CHEVRON_UP: faChevronUp,
  SIGNOUT: faArrowRightToBracket,
  CLEAR: faXmark,
  CHECK: faCheck,
  INFO: faInfoCircle,
  SPLIT_VIEW: faTableColumns,
  TREND_UP: faArrowTrendUp,
  TREND_DOWN: faArrowTrendDown,
  EQUAL_TREND: faMinus,
  MASK: faEyeSlash,
  DARK_MODE: faMoon,
  LIGHT_MODE: faSun,
};

export const PERIOD_ACTION_ICONS = {
  [ACTION_ITEMS.ADD_EXPENSE]: faCartPlus,
  [ACTION_ITEMS.ADD_PERIOD]: faCalendarPlus,
  [ACTION_ITEMS.DELETE_PERIOD]: faTrash,
  [ACTION_ITEMS.ADD_BALANCE]: faSquarePlus,
  [ACTION_ITEMS.ADD_EXPENSES_FROM_FILE]: faFileExcel,
};

export const BALANCE_TYPE_ICONS = {
  [BALANCE_TYPES.CC]: faCreditCardAlt,
  [BALANCE_TYPES.BANK]: faBuildingColumns,
  [BALANCE_TYPES.SAVINGS]: faPiggyBank,
  [BALANCE_TYPES.INCOME]: faCoins,
};

export const EXPENSE_TYPE_EMOJIS = {
  [EXPANSE_TYPES.FOOD]: '🍔',
  [EXPANSE_TYPES.HOUSING]: '🏠',
  [EXPANSE_TYPES.AUTHORITIES]: '🏛',
  [EXPANSE_TYPES.OTHER]: '🤔',
  [EXPANSE_TYPES.CHILDREN]: '👧',
  [EXPANSE_TYPES.TRANSPORTATION]: '🚌',
  [EXPANSE_TYPES.REGULAR]: '🔌',
  [EXPANSE_TYPES.SHOPPING]: '🛒',
  [EXPANSE_TYPES.INSURANCE]: '📃',
  [EXPANSE_TYPES.MEDICAL]: '🏥',
  [EXPANSE_TYPES.SAVINGS]: '💰',
  [EXPANSE_TYPES.PERSONAL]: '💅',
  [EXPANSE_TYPES.CLOTHING]: '👕',
  [EXPANSE_TYPES.GIFTS]: '🎁',
  [EXPANSE_TYPES.ENTERTAINMENT]: '🎬',
  [EXPANSE_TYPES.VACATION]: '🏖',
};
