import { Typography, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

export const WidgetTitle = (props: { title: string; showTotalIndication?: boolean }) => {
  const { title, showTotalIndication } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        width: '100%',
      }}
      justifyContent="space-between"
      direction="row"
      alignItems="center"
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 'bold',
          color: theme.palette.text.secondary,
        }}
      >
        {title}
      </Typography>
      {showTotalIndication && (
        <Stack direction="row" alignItems="center">
          <Typography
            variant="caption"
            color={theme.palette.error.main}
            sx={{
              fontWeight: 'bold',
              fontSize: '0.7rem',
            }}
          >
            {t('translations:widgets.fromTotalExpenses')}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold',
              fontSize: '0.7rem',
              mx: 0.5,
            }}
          >
            /
          </Typography>
          <Typography
            variant="caption"
            color={theme.palette.success.main}
            sx={{
              fontWeight: 'bold',
              fontSize: '0.7rem',
            }}
          >
            {t('translations:widgets.fromTotalIncomes')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
