import { lighten, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { linearProgressClasses } from '@mui/material/LinearProgress'
import { useTranslation } from 'react-i18next'
import { AmountTypography } from './AmountTypography'
import CustomTypography from './CustomTypography'
import { LinearProgressWithLabel } from './LinearProgressWithLabel'

type Props = {
  amountLeft: number
  isFullyExecuted: boolean
  totalAmount: number
  executedAmount: number
  color: string
  searchValue?: string
}

export const ProgressBarIndication = (props: Props) => {
  const { searchValue, color, amountLeft, isFullyExecuted, totalAmount, executedAmount } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const opacity = isFullyExecuted ? 0.5 : 1
  const amountVariant = isMobile ? 'caption' : 'body2'
  const titleVariant = 'caption'

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={0}
        sx={{
          opacity,
        }}
      >
        <Typography
          variant={titleVariant}
          sx={{
            textDecoration: isFullyExecuted ? 'line-through' : 'none',
          }}
        >
          {t('translations:expenseItem.execute')}:
          <AmountTypography variant={amountVariant} searchValue={searchValue}>
            {executedAmount}
          </AmountTypography>
        </Typography>
        {!isMobile && (
          <Stack direction="column" alignItems="center">
            <Typography
              variant={titleVariant}
              sx={{
                textDecoration: isFullyExecuted ? 'line-through' : 'none',
              }}
            >
              {t('translations:expenseItem.amountLeft')}:
            </Typography>
            <AmountTypography variant={amountVariant} searchValue={searchValue}>
              {amountLeft}
            </AmountTypography>
          </Stack>
        )}
        <CustomTypography searchValue={searchValue} variant={titleVariant}>
          {t('translations:expenseItem.initialAmount')}:
          <AmountTypography variant={amountVariant} searchValue={searchValue}>
            {totalAmount}
          </AmountTypography>
        </CustomTypography>
      </Stack>
      <LinearProgressWithLabel
        value={
          // eslint-disable-next-line no-nested-ternary
          amountLeft === 0
            ? 100
            : amountLeft === totalAmount
            ? 0
            : 100 - (amountLeft / totalAmount) * 100
        }
        sx={{
          borderRadius: 5,
          height: 6,
          backgroundColor: lighten(color, 0.7),
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: color,
          },
          opacity,
        }}
      />
    </div>
  )
}
