/* eslint-disable max-len */
import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import qs from 'qs';
import { BASE_ENDPOINTS, ROUTES, WIDGETS } from '../../../shared-resources/BASE_ENDPOINTS';
import {
  BUSINESS_NAME_ACTION_TYPES,
  NOTE_MODELS,
  OvertimeWidgetTimeUnit,
} from '../../../shared-resources/CONSTANTS';
import {
  AutoExecutedTransaction,
  CCName,
  ExcludedBusinessName,
  IAverageExpensesAmount,
  IBalance,
  IBalanceSnapshot,
  IBalanceSnapshotPopulated,
  IEventPopulated,
  IExecutionUpdate,
  IExpense,
  IExpenseSnapshot,
  IExpenseSnapshotPopulated,
  IHousehold,
  IOverallWidget,
  IPeriod,
  IPeriodExpensesComparison,
  IPopulatedBalance,
  IPopulatedExpense,
  IPopulatedNote,
  IUserPopulated,
  PopulateCCName,
  ScraperSecret,
  Transaction,
} from '../../../shared-resources/INTERFACES';
import { firebaseAuth } from '../hooks/useFirebaseAuth';
import { getAllPeriodsThunk } from '../slices/allPeriodSlice';
import { getBalancesThunkSilently } from '../slices/balancesSlice';
import { IPeriodSlice, currentPeriodSlice } from '../slices/currentPeriodSlice';
import { getExpensesThunkSilently } from '../slices/expensesSlice';
import { getInitialDataThunk } from '../slices/initialDataSlice';
import { store } from '../store';
import { API_METHODS } from './constants';

export const API_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9090'
    : 'https://7rrpxva6rl.execute-api.us-east-1.amazonaws.com';

const getHeaders = async (): Promise<Record<string, string>> => {
  const token = await firebaseAuth.currentUser?.getIdToken();
  const householdId = store.getState().initialData.currentHousehold?._id.toString();
  return {
    token: token || '',
    household_id: householdId || '',
  };
};

const getCurrentPeriodRef = () => {
  const { period } = store.getState().currentPeriod;

  return { period };
};

export async function getInitialData(): Promise<IUserPopulated> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios.get(`${API_BASE_URL}/${BASE_ENDPOINTS.INITIAL_DATA}`, {
      headers,
    });
    return data.data;
  } catch (error) {
    // sign out fro firebase
    firebaseAuth.signOut();
    throw new Error();
  }
}

export async function getHouseholds(): Promise<IHousehold[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios.get(`${API_BASE_URL}/${BASE_ENDPOINTS.HOUSEHOLD}`, {
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function postHousehold(household: Pick<IHousehold, 'name'>): Promise<IHousehold> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      method: API_METHODS.POST,
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.HOUSEHOLD}`,
      headers,
      data: household,
    });
    store.dispatch(getInitialDataThunk());
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function putHousehold({
  name,
  id,
}: {
  name: string;
  id: string;
}): Promise<IHousehold> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      method: API_METHODS.PUT,
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.HOUSEHOLD}/${id}`,
      headers,
      data: {
        name,
      },
    });
    store.dispatch(getInitialDataThunk());
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deleteHousehold(id: string): Promise<IHousehold> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      method: API_METHODS.DELETE,
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.HOUSEHOLD}/${id}`,
      headers,
    });
    store.dispatch(getInitialDataThunk());
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getBalancesByHouseholdId(): Promise<IPopulatedBalance[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.BALANCE}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function postBalance(newBalance: Partial<IBalance>): Promise<IBalance> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.BALANCE}`,
      method: API_METHODS.POST,
      headers,
      data: newBalance,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function putBalance({
  newBalance,
  balanceId,
}: {
  newBalance: IPopulatedBalance;
  balanceId: string;
}): Promise<IBalance> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.BALANCE}/${balanceId}`,
      method: API_METHODS.PUT,
      headers,
      data: newBalance,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deleteBalance(balanceId: string): Promise<IBalance> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.BALANCE}/${balanceId}`,
      method: API_METHODS.DELETE,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getExpensesByHouseholdId(): Promise<IPopulatedExpense[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPENSE}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getExpensesById(id: string): Promise<IPopulatedExpense> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPENSE}`,
      method: API_METHODS.GET,
      headers,
      params: {
        expenseRef: id,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deleteExpense(expenseId: string): Promise<IPopulatedExpense> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      method: API_METHODS.DELETE,
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPENSE}/${expenseId}`,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function postExpense(
  newExpense: Omit<IPopulatedExpense, 'householdRef' | '_id' | 'createdAt' | 'updatedAt'>
): Promise<IExpense> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPENSE}`,
      method: API_METHODS.POST,
      headers,
      data: newExpense,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function putExpense({
  newExpense,
  expenseId,
}: {
  newExpense: Omit<IPopulatedExpense, 'householdRef'>;
  expenseId: string;
}): Promise<IExpense> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPENSE}/${expenseId}`,
      method: API_METHODS.PUT,
      headers,
      data: {
        ...newExpense,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getAllPeriods(): Promise<IPeriodSlice[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;

  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.PERIOD}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function postPeriod(newPeriod: IPeriod): Promise<IPeriod> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.PERIOD}`,
      method: API_METHODS.POST,
      headers,
      data: {
        ...newPeriod,
        _id: undefined,
      },
    });
    store.dispatch(getAllPeriodsThunk());
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function putPeriod(newPeriod: IPeriod): Promise<IPeriod> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.PERIOD}/${newPeriod._id.toString()}`,
      method: API_METHODS.PUT,
      headers,
      data: {
        ...newPeriod,
        _id: undefined,
      },
    });
    store.dispatch(getAllPeriodsThunk());
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getPeriodById(periodId: string): Promise<IPeriodSlice> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.PERIOD}/${periodId}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deletePeriod(periodId: string): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.PERIOD}/${periodId}`,
      method: API_METHODS.DELETE,
      headers,
    });
    store.dispatch(getAllPeriodsThunk());
    store.dispatch(currentPeriodSlice.actions.resetCurrentPeriod());
  } catch (error) {
    throw new Error();
  }
}

export async function getBalancesSnapshotByPeriod(
  periodId: string
): Promise<IBalanceSnapshotPopulated[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.BALANCE_SNAPSHOT}`,
      method: API_METHODS.GET,
      headers,
      params: {
        periodRef: periodId,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function postBalanceSnapshot(
  newBalanceSnapshot: IBalanceSnapshot
): Promise<IBalanceSnapshot> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.BALANCE_SNAPSHOT}`,
      method: API_METHODS.POST,
      headers,
      data: newBalanceSnapshot,
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    store.dispatch(getBalancesThunkSilently(currentPeriodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function putBalanceSnapshot({
  newAmount,
  balanceSnapshotId,
  realized,
}: {
  newAmount: number;
  balanceSnapshotId: string;
  realized?: boolean;
}): Promise<IBalanceSnapshot> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.BALANCE_SNAPSHOT}/${balanceSnapshotId}`,
      method: API_METHODS.PUT,
      headers,
      data: {
        amount: newAmount,
        realized,
      },
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    store.dispatch(getBalancesThunkSilently(currentPeriodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deleteBalanceSnapshot({
  balanceSnapshotId,
}: {
  balanceSnapshotId: string;
}): Promise<IBalanceSnapshot> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.BALANCE_SNAPSHOT}/${balanceSnapshotId}`,
      method: API_METHODS.DELETE,
      headers,
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    store.dispatch(getBalancesThunkSilently(currentPeriodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getExpensesSnapshotByPeriod(
  periodRef: string
): Promise<IExpenseSnapshotPopulated[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPANSE_SNAPSHOT}`,
      method: API_METHODS.GET,
      headers,
      params: {
        periodRef,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getExpensesSnapshotByExpanse(
  expenseRef: string
): Promise<IExpenseSnapshotPopulated[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPANSE_SNAPSHOT}`,
      method: API_METHODS.GET,
      headers,
      params: {
        expenseRef,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getExpensesSnapshotByNoteData(
  noteRef: string,
  modalName: NOTE_MODELS
): Promise<IExpenseSnapshotPopulated> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPANSE_SNAPSHOT}/note`,
      method: API_METHODS.GET,
      headers,
      params: {
        noteRef,
        modalName,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getExpenseSnapshotById(periodId: string): Promise<IExpenseSnapshotPopulated> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPANSE_SNAPSHOT}/${periodId}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deleteExpenseSnapshot(expenseSnapshotId: string): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPANSE_SNAPSHOT}/${expenseSnapshotId}`,
      method: API_METHODS.DELETE,
      headers,
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    store.dispatch(getBalancesThunkSilently(currentPeriodRef));
  } catch (error) {
    throw new Error();
  }
}

interface IPutExpenseSnapshot {
  amount?: number;
  duoDate?: string | null;
}
export async function putExpenseSnapshot({
  newExpenseSnapshot,
  expenseSnapshotId,
}: {
  newExpenseSnapshot: IPutExpenseSnapshot;
  expenseSnapshotId: string;
}): Promise<IExpenseSnapshot> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPANSE_SNAPSHOT}/${expenseSnapshotId}`,
      method: API_METHODS.PUT,
      headers,
      data: newExpenseSnapshot,
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    store.dispatch(getBalancesThunkSilently(currentPeriodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function closeExpenseSnapshot(expenseSnapshotId: string): Promise<IExpenseSnapshot> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPANSE_SNAPSHOT}/close-expense/${expenseSnapshotId}`,
      method: API_METHODS.PUT,
      headers,
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    store.dispatch(getBalancesThunkSilently(currentPeriodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

interface IPostExecutionUpdate extends Transaction {
  amount: number;
  expenseSnapshotRef: string;
  uuid?: string;
}

export async function postExecutionUpdate(
  newExecutionUpdate: IPostExecutionUpdate
): Promise<IExecutionUpdate> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXECUTION_UPDATE}`,
      method: API_METHODS.POST,
      headers,
      data: newExecutionUpdate,
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    store.dispatch(getBalancesThunkSilently(currentPeriodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function postExpenseSnapshot({
  newExpenseSnapshot,
  periodRef,
}: {
  newExpenseSnapshot: IExpenseSnapshot;
  periodRef: string;
}): Promise<IExpenseSnapshot> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
  store.dispatch(getExpensesThunkSilently(currentPeriodRef));
  store.dispatch(getBalancesThunkSilently(currentPeriodRef));
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPANSE_SNAPSHOT}`,
      method: API_METHODS.POST,
      headers,
      data: newExpenseSnapshot,
    });
    store.dispatch(getExpensesThunkSilently(periodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deleteExecutionUpdate({
  deleteExecutionUpdateId,
}: {
  deleteExecutionUpdateId: string;
  periodRef: string;
}): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXECUTION_UPDATE}/${deleteExecutionUpdateId}`,
      method: API_METHODS.DELETE,
      headers,
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    store.dispatch(getBalancesThunkSilently(currentPeriodRef));
  } catch (error) {
    throw new Error();
  }
}

export async function getNoteById(noteId: string): Promise<IPopulatedNote> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.NOTE}/${noteId}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getNotes(): Promise<IPopulatedNote[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.NOTE}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function postNote(newNote: Partial<IPopulatedNote>): Promise<IPopulatedNote> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.NOTE}`,
      method: API_METHODS.POST,
      headers,
      data: newNote,
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function putNote(
  newNote: Partial<IPopulatedNote>,
  noteId: string
): Promise<IPopulatedNote> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.NOTE}/${noteId}`,
      method: API_METHODS.PUT,
      headers,
      data: newNote,
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deleteNote(noteId: string): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.NOTE}/${noteId}`,
      method: API_METHODS.DELETE,
      headers,
    });
    const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
  } catch (error) {
    throw new Error();
  }
}

interface GetWidgetData {
  widgetName: WIDGETS;
  timeUnit: OvertimeWidgetTimeUnit | string;
}
export async function getWidgetData({
  widgetName,
  timeUnit,
}: GetWidgetData): Promise<IOverallWidget> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  const { period } = getCurrentPeriodRef();

  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.WIDGET}/${widgetName}?timeUnit=${timeUnit}&upTo=${period.startDate}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

interface PutExpenseBusinessNamePayload {
  businessNames: string;
  expenseId: string;
  action: string;
}

export async function putExpenseBusinessName({
  businessNames,
  expenseId,
  action,
}: PutExpenseBusinessNamePayload): Promise<IExpense> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPENSE}/${expenseId}/${action}`,
      method: API_METHODS.PUT,
      headers,
      data: {
        businessNames,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

interface PostExpensesFileProcessor {
  files: Transaction[];
  periodRef: string;
}
export async function postExpensesFileProcessor(
  payload: PostExpensesFileProcessor
): Promise<AutoExecutedTransaction[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  const currentPeriodRef = store.getState().currentPeriod.period._id.toString();
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPENSES_FILE_PROCESSOR}`,
      method: API_METHODS.POST,
      headers,
      data: {
        ...payload,
      },
    });
    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getCCNames(): Promise<PopulateCCName[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;

  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.CC_NAME}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function postCCName(newCCName: Partial<CCName>): Promise<CCName> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.CC_NAME}`,
      method: API_METHODS.POST,
      headers,
      data: {
        ...newCCName,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deleteCCName(ccNameId: string): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.CC_NAME}/${ccNameId}`,
      method: API_METHODS.DELETE,
      headers,
    });
  } catch (error) {
    throw new Error();
  }
}

export async function getScraperSecrets(): Promise<ScraperSecret[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;

  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.SCRAPER_SECRET}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function postScraperSecret(
  scraperSecrets: Partial<
    ScraperSecret & {
      u: string;
      p: string;
    }
  >
): Promise<CCName> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.SCRAPER_SECRET}`,
      method: API_METHODS.POST,
      headers,
      data: {
        ...scraperSecrets,
      },
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deleteScraperSecret(scraperSecretId: string): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.SCRAPER_SECRET}/${scraperSecretId}`,
      method: API_METHODS.DELETE,
      headers,
    });
  } catch (error) {
    throw new Error();
  }
}

export async function getPendingTransactions(): Promise<Transaction[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  const { period } = getCurrentPeriodRef();

  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.PENDING_TRANSACTION}/${period._id}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deletePendingTransactions(transactionId: string): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.PENDING_TRANSACTION}/${transactionId}`,
      method: API_METHODS.DELETE,
      headers,
    });
  } catch (error) {
    throw new Error();
  }
}

interface BusinessNameAction {
  businessName: string;
  expenseId: string;
}
export async function deleteBusinessName({
  businessName,
  expenseId,
}: BusinessNameAction): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.BUSINESS_NAME}/${BUSINESS_NAME_ACTION_TYPES.DELETE}/${expenseId}`,
      method: API_METHODS.PUT,
      headers,
      data: {
        businessName,
      },
    });
  } catch (error) {
    throw new Error();
  }
}

export async function excludeBusinessName({
  businessName,
  expenseId,
}: BusinessNameAction): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.BUSINESS_NAME}/${BUSINESS_NAME_ACTION_TYPES.EXCLUDE}/${expenseId}`,
      method: API_METHODS.PUT,
      headers,
      data: {
        businessName,
      },
    });
  } catch (error) {
    throw new Error();
  }
}

export async function getExcludedBusinessNames(): Promise<ExcludedBusinessName[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXCLUDED_BUSINESS_NAME}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function deleteExcludedBusinessName(id: string): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXCLUDED_BUSINESS_NAME}/${id}`,
      method: API_METHODS.DELETE,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

interface GetEvents {
  page: number;
  limit: number;
}

interface EventsResponse {
  events: IEventPopulated[];
  count: number;
}

export async function getEvents({ page, limit }: GetEvents): Promise<EventsResponse> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EVENT}?page=${page}&limit=${limit}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getPeriodExpensesComparison({
  periodRef,
  periodsToCompare,
}: {
  periodRef: string;
  periodsToCompare: number;
}): Promise<IPeriodExpensesComparison[]> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.PERIOD_EXPENSES_COMPARISON}?periodRef=${periodRef}&periodsToCompare=${periodsToCompare}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getAverageExpense(expenseRef: string): Promise<{ average: number }> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPENSE}/${ROUTES.AVERAGE}/${expenseRef}`,
      method: API_METHODS.GET,
      headers,
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function getAverageExpenses(ids: string[]): Promise<{
  averages: IAverageExpensesAmount[];
}> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXPENSE}/${ROUTES.EXPENSE_AVERAGES}`,
      params: {
        ids,
      },
      method: API_METHODS.GET,
      headers,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data.data;
  } catch (error) {
    throw new Error();
  }
}

export async function moveExecutionUpdate({
  id,
  newExpenseSnapshotRef,
}: {
  id: string;
  newExpenseSnapshotRef: string;
}): Promise<void> {
  const headers = (await getHeaders()) as AxiosRequestHeaders;
  const currentPeriodRef = store.getState().currentPeriod.period._id.toString();

  try {
    const data: AxiosResponse = await axios({
      url: `${API_BASE_URL}/${BASE_ENDPOINTS.EXECUTION_UPDATE}/${ROUTES.MOVE_EXECUTION_UPDATE}/${id}`,
      method: API_METHODS.PUT,
      headers,
      data: {
        newExpenseSnapshotRef,
      },
    });

    store.dispatch(getExpensesThunkSilently(currentPeriodRef));
    return data.data;
  } catch (error) {
    throw new Error();
  }
}
