import { Card, Divider, Stack, useTheme } from '@mui/material';
import { Point } from '@nivo/line';
import { useCurrency } from 'hooks/useCurrency';
import { useTranslation } from 'react-i18next';
import CustomTypography from '../CustomTypography';
import PreserveLtr from '../PreserveLtr';

interface Props {
  point: Point;
  showMainTitle?: boolean;
}

export const ChartTooltip = (props: Props): JSX.Element => {
  const { point, showMainTitle = true } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const { formatAmount } = useCurrency();

  return (
    <Card
      sx={{
        p: 1,
      }}
      style={{
        zIndex: theme.zIndex.drawer + 1,
        position: 'relative',
      }}
    >
      <Stack direction="column" spacing={1}>
        {showMainTitle && (
          <>
            <CustomTypography variant="caption" color="text.secondary" noWrap>
              {point.serieId} {t('translations:dateAndTime.month')} {point.data.x.toString()}
            </CustomTypography>
            <Divider
              sx={{
                width: '100%',
              }}
            />
          </>
        )}
        <CustomTypography
          variant="body1"
          noWrap
          sx={{
            textAlign: 'left',
          }}
        >
          <PreserveLtr>{formatAmount(Number(point.data.y))}</PreserveLtr>
        </CustomTypography>
      </Stack>
    </Card>
  );
};
