import {
  Autocomplete,
  Badge,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { AmountTypography } from 'components/AmountTypography';
import { CustomDatePicker } from 'components/CustomDatePicker';
import CustomIcon from 'components/CustomIcon';
import { FormikValues, useFormik } from 'formik';
import moment from 'moment';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import stringSimilarity from 'string-similarity';
import { COMMON_ICONS } from 'utils/icons';
import * as Yup from 'yup';
import { ROUTES } from '../../../../shared-resources/BASE_ENDPOINTS';
import { EXPANSE_TYPES, NOTE_MODELS } from '../../../../shared-resources/CONSTANTS';
import { calculateRemainingExpenseAmount } from '../../../../shared-resources/FUNCTIONS';
import {
  IExecutionUpdatePopulated,
  IExpenseSnapshot,
  IExpenseSnapshotPopulated,
  IPopulatedExpense,
  Transaction,
} from '../../../../shared-resources/INTERFACES';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import CustomLoadingGuard from '../../components/CustomLoadingGuard';
import CustomModal from '../../components/CustomModal';
import CustomSpacer from '../../components/CustomSpacer';
import { useAppSelector } from '../../hooks/useReactRedux';
import { i18n } from '../../i18n/i18n';
import {
  getExpensesByHouseholdId as getAllExpenses,
  getExpenseSnapshotById,
  postExecutionUpdate,
  postExpense,
  postExpenseSnapshot,
  postNote,
  putExpenseBusinessName,
  putExpenseSnapshot,
} from '../../utils/apiUtils';
import { COMPONENT_MODES, DATE_PICKER_FORMAT } from '../../utils/constants';
import { getComponentModeTitle } from '../../utils/helpers';
import ExecutionUpdates from '../Period/ExpanseItem/ExecutionUpdates';
import { BalanceSnapshotSelection } from './BalanceSnapshotSelection';
import { SavingSelection } from './SavingSelection';
import { SimilarAmountBox } from './SimilarAmountBox';
import { TypeSelection } from './TypeSelection';
import { useAverageExpense } from './useAverageExpense';

type Props = {
  mode: COMPONENT_MODES;
  expenseId?: string;
  onClose: () => void;
  predefinedData?: {
    executeOnDone?: boolean;
    amount?: number;
    balanceSnapshotRef?: string;
    uuid?: string;
    businessName?: string;
  };
  transaction?: Transaction;
  onSuccessfulExecute: () => void;
};

interface IFormValues {
  name: string;
  amount?: number;
  type: EXPANSE_TYPES;
  balanceSnapshotRef?: string;
  isPeriodical: boolean;
  duoDate?: Date | string;
  executionUpdatesRef: IExecutionUpdatePopulated[];
  expenseRef: string;
  _id?: string;
  savingRef?: string;
}

export function AddEditExpenseSnapshot(props: Props): React.ReactElement {
  const { predefinedData, mode, expenseId, onClose, onSuccessfulExecute, transaction } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(mode === COMPONENT_MODES.EDIT);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const { period } = useAppSelector((state) => state.currentPeriod);
  const { expenses: currentExpenses } = useAppSelector((state) => state.expenses);
  const [isSaving, setIsSaving] = React.useState(false);
  const [executeOnDone, setExecuteOnDone] = React.useState(!!predefinedData);
  const [expenses, setExpenses] = React.useState<IPopulatedExpense[]>([]);
  const [showSimilarity, setShowSimilarity] = React.useState(false);
  const [allowAutoExecution, setAllowAutoExecution] = React.useState(false);
  const [noteContent, setNoteContent] = React.useState('');
  const [fetchAllExpensesInProgress, setFetchAllExpensesInProgress] = React.useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, i18n.t('translations:errors.tooShort'))
      .max(50, i18n.t('translations:errors.tooLong'))
      .required(i18n.t('translations:errors.required')),
    amount: Yup.number().required(i18n.t('translations:errors.required')),
    balanceSnapshotRef: Yup.string().required(i18n.t('translations:errors.required')),
    duoDate: allowAutoExecution
      ? Yup.string().required(i18n.t('translations:errors.required'))
      : Yup.object().nullable(),
    savingRef: isSaving
      ? Yup.string().required(i18n.t('translations:errors.required'))
      : Yup.string(),
  });

  const formik: FormikValues & {
    values: IFormValues;
  } = useFormik({
    initialValues: {
      name: '',
      amount: predefinedData?.amount,
      isPeriodical: false,
      type: EXPANSE_TYPES.OTHER,
      executionUpdatesRef: [],
      balanceSnapshotRef: predefinedData?.balanceSnapshotRef || '',
      expenseRef: undefined || '',
      savingRef: undefined || '',
    },
    validationSchema,
    onSubmit,
  });

  const { averageExpenseAmount } = useAverageExpense(formik.values.expenseRef);

  const expenseAlreadyExists =
    formik.values.expenseRef &&
    currentExpenses.find((expense) => expense.expenseRef?._id === formik.values.expenseRef);

  const similarity =
    formik.values.name && expenses.length
      ? stringSimilarity.findBestMatch(
          formik.values.name,
          expenses.map((e) => e.name)
        )
      : null;
  const similarityNames = similarity?.ratings.filter((r) => r.rating > 0.5) || [];

  const minAmountToExecute =
    formik.values?.executionUpdatesRef?.reduce(
      (acc: number, curr: { amount: number }) => acc + curr.amount,
      0
    ) || 0;

  const editError =
    mode === COMPONENT_MODES.EDIT && Number(formik.values?.amount) < minAmountToExecute;

  useEffect(() => {
    if (mode === COMPONENT_MODES.EDIT && expenseId) {
      fetchExpenseSnapshot(expenseId);
    } else {
      fetchAllExpenses();
    }
  }, []);

  const fetchAllExpenses = async () => {
    setFetchAllExpensesInProgress(true);
    try {
      const allExpenses = await getAllExpenses();
      setExpenses(allExpenses);
    } catch (error) {
      //
    }
    setFetchAllExpensesInProgress(false);
  };

  const handleLeaveNameTextInput = () => {
    if (formik.values.name) {
      setShowSimilarity(true);
    }
  };

  async function fetchExpenseSnapshot(id: string, showLoading = true) {
    setLoading(showLoading);
    try {
      const data = await getExpenseSnapshotById(id);
      if (data?.expenseRef) {
        formik.setValues({
          _id: data._id,
          duoDate: data.duoDate,
          amount: data.amount,
          name: data?.expenseRef.name,
          type: data?.expenseRef.type,
          isPeriodical: data?.expenseRef?.isPeriodical,
          balanceSnapshotRef: data?.balanceSnapshotRef?._id?.toString(),
          expenseRef: data?.expenseRef._id.toString(),
          executionUpdatesRef: data.executionUpdatesRef,
          savingRef: data?.savingRef?.toString(),
        } as IFormValues);
      }

      if (data?.duoDate) {
        setAllowAutoExecution(true);
      }
    } catch (error) {
      //
    }
    setLoading(false);
  }

  const handlePost = async (values: IFormValues) => {
    setRequestLoading(true);
    // Check if user trying to create a new expense with existing name
    if (!values.expenseRef && expenses.some((e) => e.name === values.name)) {
      setRequestLoading(false);
      formik.setFieldError('name', i18n.t('translations:errors.existingExpense'));
      return;
    }
    try {
      const expenseToPost = {
        name: values.name,
        type: values.type,
        isPeriodical: values.isPeriodical,
      };

      //
      const expenseSnapshot = {
        amount: values.amount,
        duoDate: values.duoDate,
        periodRef: period._id.toString(),
        expenseRef: values.expenseRef,
        balanceSnapshotRef: values.balanceSnapshotRef,
        savingRef: values.savingRef ? values.savingRef : undefined,
        uuid: predefinedData?.uuid,
      };

      if (!values.expenseRef) {
        // create new expense
        const newExpense = await postExpense(expenseToPost);
        // create new expense snapshot
        const response = await postExpenseSnapshot({
          newExpenseSnapshot: {
            ...expenseSnapshot,
            expenseRef: newExpense._id.toString(),
          } as IExpenseSnapshot,
          periodRef: period._id.toString(),
        });
        if (executeOnDone) {
          const newExecutionUpdate = {
            amount: response.amount,
            expenseSnapshotRef: response._id.toString(),
          };
          await postExecutionUpdate({ ...newExecutionUpdate, ...transaction });
        }

        const trimmedNote = noteContent.trim();
        if (trimmedNote) {
          await postNote({
            content: trimmedNote,
            modalName: NOTE_MODELS.EXPENSE_SNAPSHOT,
            documentRef: newExpense._id.toString(),
          });
        }

        // If there is a business name, we need to update the expense
        if (predefinedData?.businessName && newExpense._id) {
          await putExpenseBusinessName({
            expenseId: newExpense._id.toString(),
            action: ROUTES.ADD_BUSINESS_NAME,
            businessNames: predefinedData.businessName,
          });
        }
      } else {
        // create new expense snapshot
        const newExpense = await postExpenseSnapshot({
          newExpenseSnapshot: expenseSnapshot as IExpenseSnapshot,
          periodRef: period._id.toString(),
        });
        if (executeOnDone) {
          const newExecutionUpdate = {
            amount: newExpense.amount,
            expenseSnapshotRef: newExpense._id.toString(),
          };
          await postExecutionUpdate({ ...newExecutionUpdate, ...transaction });
        }

        const trimmedNote = noteContent.trim();
        if (trimmedNote) {
          await postNote({
            content: trimmedNote,
            modalName: NOTE_MODELS.EXPENSE_SNAPSHOT,
            documentRef: newExpense._id.toString(),
          });
        }

        // If there is a business name, we need to update the expense
        if (predefinedData?.businessName && newExpense.expenseRef) {
          await putExpenseBusinessName({
            expenseId: newExpense.expenseRef.toString(),
            action: ROUTES.ADD_BUSINESS_NAME,
            businessNames: predefinedData.businessName,
          });
        }
      }
      onSuccessfulExecute();
    } catch (error) {
      //
    } finally {
      setRequestLoading(false);
    }
  };

  const handlePut = async (values: IFormValues) => {
    setRequestLoading(true);
    const newExpenseSnapshot = {
      amount: Number(values.amount),
      duoDate: values.duoDate ? moment(values.duoDate).format() : null,
      balanceSnapshotRef: values.balanceSnapshotRef,
      savingRef: values.savingRef,
    };

    try {
      await putExpenseSnapshot({
        expenseSnapshotId: values?._id as string,
        newExpenseSnapshot,
      });
      if (executeOnDone && values) {
        const { remainingExpenseAmount } = calculateRemainingExpenseAmount(
          values as unknown as IExpenseSnapshotPopulated
        );
        const newExecutionUpdate = {
          amount: remainingExpenseAmount,
          expenseSnapshotRef: values?._id as string,
        };
        await postExecutionUpdate({ ...newExecutionUpdate, ...transaction });
      }

      onSuccessfulExecute();
    } catch (error) {
      //
    }
    setRequestLoading(false);
  };

  function onSubmit() {
    if (mode === COMPONENT_MODES.ADD) {
      handlePost(formik.values);
    }
    if (mode === COMPONENT_MODES.EDIT) {
      handlePut(formik.values);
    }
  }

  const handleChangeByKey = <T,>({ key, value }: { key: string; value: T }) => {
    formik.setFieldValue(key, value);
  };

  async function onUpdate() {
    if (!expenseId) return;
    fetchExpenseSnapshot(expenseId, false);
  }

  const handleChangeNoteContent = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNoteContent(event.target.value);
  };

  return (
    <CustomModal onClose={onClose}>
      <CustomLoadingGuard loading={loading}>
        <div>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              height: '100%',
            }}
          >
            <Stack flexDirection="column">
              <CardHeader title={getComponentModeTitle(mode)} />
              {!formik.values.expenseRef && showSimilarity && similarityNames.length ? (
                <Typography
                  variant="body2"
                  sx={{
                    color: 'warning.main',
                  }}
                >
                  {t('translations:errors.similarNames1')} {similarityNames.length}{' '}
                  {t('translations:errors.similarNames2')}
                </Typography>
              ) : (
                <CustomSpacer vertical size="small" />
              )}
              <CustomSpacer vertical size="small" />
              <Badge
                sx={{
                  width: '100%',
                  '& .MuiBadge-badge': {
                    mr: 4,
                  },
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                color={formik.values.expenseRef ? 'warning' : 'success'}
                badgeContent={
                  formik.values.expenseRef
                    ? t('translations:period.floatButton.existingExpense')
                    : t('translations:period.floatButton.newExpense')
                }
                invisible={
                  (!formik.values.name && !formik.values.expenseRef) ||
                  mode === COMPONENT_MODES.EDIT
                }
              >
                <Stack direction="column" width="100%">
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disableClearable
                    noOptionsText={t('translations:common.noOptions')}
                    value={formik.values.name}
                    disabled={
                      loading || mode === COMPONENT_MODES.EDIT || fetchAllExpensesInProgress
                    }
                    options={expenses.map((option) => option.name)}
                    onChange={(event, value) => {
                      const selectedExpense = expenses.find((e) => e.name === value);
                      formik.setValues({
                        ...formik.values,
                        expenseRef: selectedExpense?._id || '',
                        name: value,
                        type: selectedExpense?.type || EXPANSE_TYPES.OTHER,
                        isPeriodical: selectedExpense?.isPeriodical || false,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onBlur={handleLeaveNameTextInput}
                        onFocus={() => setShowSimilarity(false)}
                        value={formik.values.name}
                        label={t('translations:common.description')}
                        size="small"
                        disabled={
                          loading || mode === COMPONENT_MODES.EDIT || fetchAllExpensesInProgress
                        }
                        onChange={(e) => {
                          if (!expenses.find((element) => element.name === e.target.value)) {
                            formik.setValues({
                              ...formik.values,
                              expenseRef: undefined,
                              type: EXPANSE_TYPES.OTHER,
                              isPeriodical: false,
                              name: e.target.value,
                            });
                          } else {
                            // Check if there is an expense with the same name
                            const selectedExpense = expenses.find(
                              (expense) => expense.name === e.target.value
                            );
                            if (selectedExpense) {
                              // If there is, set the expenseRef
                              formik.setValues({
                                ...formik.values,
                                expenseRef: selectedExpense?._id || '',
                                name: e.target.value,
                                type: selectedExpense?.type || EXPANSE_TYPES.OTHER,
                                isPeriodical: selectedExpense?.isPeriodical || false,
                              });
                            } else {
                              formik.setValues({
                                ...formik.values,
                                name: e.target.value,
                              });
                            }
                          }
                        }}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                  {expenseAlreadyExists && (
                    <Stack direction="row" alignItems="center">
                      <CustomIcon icon={COMMON_ICONS.INFO} />
                      <CustomSpacer horizontal size="small" />
                      <Typography variant="body2" color="caption">
                        {t('translations:expenses.addEditExpense.expenseAlreadyExists')}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Badge>
              <CustomSpacer vertical size="small" />
              <SimilarAmountBox amount={formik.values.amount} />
              <CustomInput
                sx={{ width: '100%' }}
                handleChange={formik.handleChange}
                name="amount"
                value={formik.values.amount}
                error={editError || (formik.touched.amount && Boolean(formik.errors.amount))}
                helperText={formik.touched.amount && formik.errors.amount}
              />
              {averageExpenseAmount && (
                <Stack direction="row" alignItems="center">
                  <Typography variant="caption">
                    {t('translations:expenses.addEditExpense.monthlyAverageAmount')}:
                  </Typography>
                  <CustomSpacer horizontal size="xsmall" />
                  <AmountTypography variant="caption">{averageExpenseAmount}</AmountTypography>
                </Stack>
              )}
              {mode === COMPONENT_MODES.EDIT && formik.values.executionUpdatesRef.length > 0 && (
                <ExecutionUpdates
                  executionUpdates={formik.values.executionUpdatesRef}
                  onUpdate={onUpdate}
                />
              )}
              <CustomSpacer vertical size="small" />
              <BalanceSnapshotSelection
                selectedBalanceSnapshot={formik.values?.balanceSnapshotRef}
                onChange={(value) => handleChangeByKey({ key: 'balanceSnapshotRef', value })}
                error={
                  formik.touched.balanceSnapshotRef && Boolean(formik.errors.balanceSnapshotRef)
                }
                helperText={
                  formik.touched.balanceSnapshotRef && formik.errors.balanceSnapshotRef?.toString()
                }
              />
              <CustomSpacer vertical size="small" />
              <Stack>
                <FormControlLabel
                  disabled={mode === COMPONENT_MODES.EDIT}
                  control={
                    <Checkbox
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e.target.checked) {
                          handleChangeByKey({
                            key: 'savingRef',
                            value: undefined,
                          });
                        }
                        setIsSaving(e.target.checked);
                      }}
                      checked={isSaving || !!formik.values.savingRef}
                    />
                  }
                  label={
                    <Typography noWrap variant="body2">
                      {t('translations:expenses.addEditExpense.isSaving')}
                    </Typography>
                  }
                />
                <SavingSelection
                  disabled={!isSaving}
                  selectedSaving={formik.values?.savingRef}
                  onChange={(value) => handleChangeByKey({ key: 'savingRef', value })}
                  error={formik.touched.savingRef && Boolean(formik.errors.savingRef)}
                  helperText={formik.touched.savingRef && formik.errors.savingRef?.toString()}
                />
              </Stack>
              <CustomSpacer vertical size="small" />
              <TypeSelection
                selectedType={formik.values.type}
                onChange={(value) => handleChangeByKey({ key: 'type', value })}
                disabled={!!(formik.values.expenseRef && formik.values.type)}
              />
              <CustomSpacer vertical size="small" />
              <FormControlLabel
                value={allowAutoExecution}
                control={<Switch checked={allowAutoExecution} />}
                label={t('translations:expenses.addEditExpense.autoExecute')}
                onChange={(e, checked) => {
                  setAllowAutoExecution(checked);
                  handleChangeByKey({
                    key: 'duoDate',
                    value: checked ? moment(period.endDate).add(-1, 'day') : undefined,
                  });
                }}
              />
              <CustomDatePicker
                disabled={!allowAutoExecution}
                inputFormat={DATE_PICKER_FORMAT}
                label={t('translations:common.duoDate')}
                maxDate={moment(period.endDate).add(-1, 'day')}
                minDate={moment(period.startDate)}
                value={formik.values.duoDate ? moment(formik.values.duoDate) : null}
                onChange={(newDate) => handleChangeByKey({ key: 'duoDate', value: newDate })}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    error={
                      (formik.touched.duoDate || allowAutoExecution) &&
                      Boolean(formik.errors.duoDate)
                    }
                    helperText={
                      (formik.touched.duoDate || allowAutoExecution) && formik.errors.duoDate
                    }
                  />
                )}
              />
              <CustomSpacer vertical size="small" />
              <FormControlLabel
                value={formik.values.isPeriodical}
                control={<Switch />}
                label={t('translations:common.periodicalExpense')}
                onChange={formik.handleChange}
                name="isPeriodical"
                id="isPeriodical"
                disabled={!!formik.values.expenseRef}
              />
              <FormControlLabel
                value={executeOnDone}
                control={<Switch checked={executeOnDone} />}
                label={t('translations:expenses.addEditExpense.executeOnDone')}
                name="executeOnDone"
                id="executeOnDone"
                onChange={(e, checked) => setExecuteOnDone(checked)}
              />
              {mode === COMPONENT_MODES.ADD && (
                <TextField
                  fullWidth
                  label={t('translations:note.content')}
                  margin="normal"
                  name="content"
                  onChange={handleChangeNoteContent}
                  value={noteContent}
                  variant="outlined"
                  rows={4}
                />
              )}
              <CustomSpacer vertical size="large" />
            </Stack>
            <CustomButton
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={editError}
              isLoading={requestLoading}
            >
              {getComponentModeTitle(mode)}
            </CustomButton>
          </form>
        </div>
      </CustomLoadingGuard>
    </CustomModal>
  );
}
