import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CustomBadge } from 'components/CustomBadge';
import CustomTypography from 'components/CustomTypography';
import { useAppSelector } from 'hooks/useReactRedux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isDateBetween } from 'shared-resources/FUNCTIONS';
import { Transaction } from '../../../../shared-resources/INTERFACES';
import { formatAmountWithCommas, getDate } from '../../utils/helpers';
import PreserveLtr from '../PreserveLtr';

interface Props {
  rawRow: Transaction;
  searchValue?: string;
}

export const UploadedFileBox = (props: Props) => {
  const { rawRow, searchValue } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const infoVariant = isMobile ? 'caption' : 'body2';
  const dataVariant = isMobile ? 'caption' : 'body1';
  const hideComponent =
    !rawRow.date && !rawRow.transactionType && !rawRow.businessName && !rawRow.currency;
  const { period } = useAppSelector((state) => state.currentPeriod);

  const isBetweenPeriodRange =
    rawRow.date &&
    isDateBetween({
      currentPeriodEnd: period.endDate as string,
      currentPeriodStart: period.startDate as string,
      transactionDate: rawRow.date,
    });

  return hideComponent ? null : (
    <CustomBadge
      variant="standard"
      color="error"
      invisible={!!isBetweenPeriodRange}
      badgeContent={
        <CustomTypography variant="caption" noWrap>
          {t('translations:addExpensesFromFile.notBetweenPeriodRange')}
        </CustomTypography>
      }
    >
      <Stack flexDirection="column">
        {!isMobile && (
          <Stack flexDirection="row" alignItems="center">
            <Typography variant={infoVariant} color="GrayText" noWrap>
              {getDate({
                date: moment(rawRow.date, 'DD-MM-YYYY').toString(),
                format: 'DD/MM/YYYY',
              })}
            </Typography>
            <Typography
              sx={{
                mx: 1,
              }}
              variant={infoVariant}
              color="GrayText"
            >
              •
            </Typography>
            <Typography variant={infoVariant} color="GrayText" noWrap>
              {rawRow.transactionType}
            </Typography>
            <Typography
              sx={{
                mx: 1,
              }}
              variant={infoVariant}
              color="GrayText"
            >
              •
            </Typography>
            <Typography variant={infoVariant} color="GrayText" noWrap>
              {rawRow.category}
            </Typography>
            {rawRow.paymentMethod && (
              <>
                <Typography
                  sx={{
                    mx: 1,
                  }}
                  variant={infoVariant}
                  color="GrayText"
                >
                  •
                </Typography>
                <Typography variant={infoVariant} color="GrayText" noWrap>
                  {rawRow.paymentMethod}
                </Typography>
              </>
            )}
            {rawRow.transactionInfo && (
              <>
                <Typography
                  sx={{
                    mx: 1,
                  }}
                  variant={infoVariant}
                  color="GrayText"
                >
                  •
                </Typography>
                <Typography variant={infoVariant} color="GrayText" noWrap>
                  {rawRow.transactionInfo}
                </Typography>
              </>
            )}
          </Stack>
        )}
        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : 'center'}
        >
          <CustomTypography searchValue={searchValue} variant={dataVariant} noWrap>
            {rawRow.businessName}
          </CustomTypography>
          {!isMobile && (
            <Typography
              sx={{
                mx: 1,
              }}
              variant="body2"
              color="GrayText"
            >
              •
            </Typography>
          )}
          <CustomTypography searchValue={searchValue} variant={dataVariant}>
            <PreserveLtr>
              {rawRow.currency}
              {formatAmountWithCommas(rawRow.amount)}
            </PreserveLtr>
          </CustomTypography>
        </Stack>
      </Stack>
    </CustomBadge>
  );
};
