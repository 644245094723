import { useTheme } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { ChartTooltip } from './ChartTooltip';

const MARGIN = 6;

export const StreamChart = ({ data }: { data?: number[] }) => {
  const theme = useTheme();
  const streamData = [
    {
      id: 'pastData',
      color: 'hsl(0, 70%, 50%)',
      data:
        data?.map((item, index) => ({
          x: `x-${index}`,
          y: item,
        })) || [],
    },
  ];

  return (
    <ResponsiveLine
      colors={[theme.palette.text.primary]}
      theme={{
        tooltip: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          zIndex: theme.zIndex.drawer + 1,
        },
      }}
      data={streamData || []}
      curve="cardinal"
      margin={{ right: MARGIN, top: MARGIN, bottom: MARGIN, left: 0 }}
      // adding custom gradient the fade out the chart in the end
      fill={[
        {
          match: '*',
          id: 'gradient',
        },
      ]}
      defs={[
        {
          id: 'gradient',
          type: 'linearGradient',
          colors: [
            { offset: 0, color: theme.palette.primary.main },
            { offset: 100, color: theme.palette.background.default },
          ],
        },
      ]}
      useMesh
      // eslint-disable-next-line react/no-unstable-nested-components
      tooltip={({ point }) => <ChartTooltip point={point} showMainTitle={false} />}
      enableArea
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      pointSize={4}
      pointBorderWidth={1}
    />
  );
};
