import { useTheme } from '@mui/system';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

export const CustomDatePicker = <TInputDate, TDate = TInputDate>(
  props: DatePickerProps<TInputDate, TDate> & React.RefAttributes<HTMLDivElement>
) => {
  const { ...rest } = props;
  const theme = useTheme();

  return (
    <DatePicker
      {...rest}
      PopperProps={{
        dir: theme.direction === 'rtl' ? 'rtl' : 'ltr',
      }}
    />
  );
};
