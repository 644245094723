import { i18n } from '../i18n/i18n'
import { APP_ROUTES, IN_ROUTE_TYPES, MANAGEMENT_TABS, SETTING_TABS } from './constants'
import { ROUTES_ICONS } from './icons'

export interface BaseRoute {
  name: string
  icon?: typeof ROUTES_ICONS[keyof typeof ROUTES_ICONS]
}

export interface GroupRoute extends BaseRoute {
  type: IN_ROUTE_TYPES.GROUP
  routes: ItemRoute[]
  path?: never
}

export interface ItemRoute extends BaseRoute {
  path: string
  type: IN_ROUTE_TYPES.ITEM
  routes?: never
}

export const MODULE_APP_ROUTES: (GroupRoute | ItemRoute)[] = [
  {
    path: APP_ROUTES.DASHBOARD,
    name: i18n.t(`translations:navigation.${APP_ROUTES.DASHBOARD}`),
    icon: ROUTES_ICONS.DASHBOARD,
    type: IN_ROUTE_TYPES.ITEM,
  },
  {
    path: APP_ROUTES.PERIOD,
    name: i18n.t(`translations:navigation.${APP_ROUTES.PERIOD}`),
    icon: ROUTES_ICONS.PERIOD,
    type: IN_ROUTE_TYPES.ITEM,
  },
  {
    path: APP_ROUTES.HISTORY,
    name: i18n.t(`translations:navigation.${APP_ROUTES.HISTORY}`),
    icon: ROUTES_ICONS.HISTORY,
    type: IN_ROUTE_TYPES.ITEM,
  },
  {
    name: i18n.t(`translations:navigation.${APP_ROUTES.MANAGEMENT}`),
    icon: ROUTES_ICONS.MANAGEMENT,
    type: IN_ROUTE_TYPES.GROUP,
    routes: [
      {
        path: `${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.BALANCES}`,
        name: i18n.t(`translations:navigation.${MANAGEMENT_TABS.BALANCES}`),
        type: IN_ROUTE_TYPES.ITEM,
      },
      {
        path: `${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.EXPENSES}`,
        name: i18n.t(`translations:navigation.${MANAGEMENT_TABS.EXPENSES}`),
        type: IN_ROUTE_TYPES.ITEM,
      },
      {
        path: `${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.HOUSEHOLDS}`,
        name: i18n.t(`translations:navigation.${MANAGEMENT_TABS.HOUSEHOLDS}`),
        type: IN_ROUTE_TYPES.ITEM,
      },
      {
        path: `${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.NOTES}`,
        name: i18n.t(`translations:navigation.${MANAGEMENT_TABS.NOTES}`),
        type: IN_ROUTE_TYPES.ITEM,
      },
      {
        path: `${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.CC_NAMES}`,
        name: i18n.t('translations:ccName.ccNames'),
        type: IN_ROUTE_TYPES.ITEM,
      },
      {
        path: `${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.SCRAPER_SECRETS}`,
        name: i18n.t('translations:scraperSecret.scraperSecret'),
        type: IN_ROUTE_TYPES.ITEM,
      },
      {
        path: `${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.EXCLUDE_BUSINESS_NAMES}`,
        name: i18n.t('translations:excludedBusinessName.title'),
        type: IN_ROUTE_TYPES.ITEM,
      },
    ],
  },
]

export const BASE_APP_ROUTES: (GroupRoute | ItemRoute)[] = [
  {
    name: i18n.t(`translations:navigation.${APP_ROUTES.SETTINGS}`),
    icon: ROUTES_ICONS.SETTINGS,
    type: IN_ROUTE_TYPES.GROUP,
    routes: [
      {
        path: `${APP_ROUTES.SETTINGS}/${SETTING_TABS.PROFILE}`,
        name: i18n.t('translations:settings.profile.title'),
        type: IN_ROUTE_TYPES.ITEM,
      },
      {
        path: `${APP_ROUTES.SETTINGS}/${SETTING_TABS.APPLICATION}`,
        name: i18n.t('translations:settings.application.title'),
        type: IN_ROUTE_TYPES.ITEM,
      },
    ],
  },
]
