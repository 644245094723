import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QueryKeys } from 'src/types/queryKeys';

interface Props {
  queryKey?: `${QueryKeys}`;
}

export const useSearchQueryParam = (props?: Props) => {
  const queryKey = props?.queryKey;
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');

  // Initialize `search` state from the query parameter
  useEffect(() => {
    if (!queryKey) return;

    const params = new URLSearchParams(location.search);
    const querySearchValue = params.get(queryKey);
    if (querySearchValue) {
      setSearch(querySearchValue);
    }
  }, [location.search]);

  useEffect(() => {
    return () => {
      if (!queryKey) return;

      const params = new URLSearchParams(location.search);
      params.delete(queryKey);
    };
  }, []);

  // Handler to update the URL query parameter
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);

    if (!queryKey) return;

    const params = new URLSearchParams();
    params.append(queryKey, event.target.value);
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  const handleResetSearch = () => {
    setSearch('');
    if (!queryKey) return;

    const params = new URLSearchParams();
    params.append(queryKey, '');
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  return {
    search,
    handleSearchChange,
    handleResetSearch,
  };
};
