/* eslint-disable no-nested-ternary */
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { WIDGETS } from 'shared-resources/BASE_ENDPOINTS';
import { IOverallWidget } from 'shared-resources/INTERFACES';
import { getWidgetData } from 'utils/apiUtils';
import { BALANCE_TYPES, OvertimeWidgetTimeUnit } from '../../../../../shared-resources/CONSTANTS';
import { BalancesAccordion } from '../../../components/BalancesAccordion';
import CustomLoadingGuard from '../../../components/CustomLoadingGuard';
import { useAppSelector } from '../../../hooks/useReactRedux';
import SummaryAccordion from './Summary/SummaryAccordion/SummaryAccordion';

export default function PeriodSummary(): React.ReactElement {
  const { loading: balancesLoading, balances } = useAppSelector((state) => state.balances);
  const { t } = useTranslation();
  const { loading: expensesLoading } = useAppSelector((state) => state.expenses);

  const [widgetData, setWidgetData] = React.useState<IOverallWidget>();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getWidgetData({
          widgetName: WIDGETS.OVERALL_WIDGET,
          timeUnit: OvertimeWidgetTimeUnit.LAST_6_MONTHS,
        });
        setWidgetData(response);
      } catch (error) {
        //
      }
    };
    if (!balances.length) return;

    fetchData();
  }, [balances]);

  const loading = balancesLoading || expensesLoading;
  const incomes = balances.filter((b) => b.balanceRef?.type === BALANCE_TYPES.INCOME);
  const savings = balances.filter((b) => b.balanceRef?.type === BALANCE_TYPES.SAVINGS);
  const creditCards = balances.filter((b) => b.balanceRef?.type === BALANCE_TYPES.CC);
  const bankAccounts = balances.filter((b) => b.balanceRef?.type === BALANCE_TYPES.BANK);
  const xsCellSize = 12;
  const smCellSize = 3;

  const pastIncomes = widgetData?.overTimeWidget.map((element) => element.incomes.total);
  const pastCCExpenses = widgetData?.overTimeWidget.map((element) => element.expenses.creditCard);

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <CustomLoadingGuard loading={loading}>
        <Grid container spacing={1}>
          {balances.length > 0 ? (
            <>
              <Grid item sm={smCellSize} xs={xsCellSize}>
                <BalancesAccordion
                  balances={incomes}
                  title={t('translations:common.incomes')}
                  pastData={pastIncomes}
                />
              </Grid>
              <Grid item sm={smCellSize} xs={xsCellSize}>
                <BalancesAccordion
                  isCreditCard
                  balances={creditCards}
                  title={t('translations:common.creditCards')}
                  pastData={pastCCExpenses}
                />
              </Grid>
              <Grid item sm={smCellSize} xs={xsCellSize}>
                <BalancesAccordion
                  balances={bankAccounts}
                  title={t('translations:common.bankAccounts')}
                />
              </Grid>
              <Grid item sm={smCellSize} xs={xsCellSize}>
                <BalancesAccordion balances={savings} title={t('translations:common.savings')} />
              </Grid>
              <Grid item xs={xsCellSize}>
                <SummaryAccordion widgetData={widgetData} />
              </Grid>
            </>
          ) : (
            <Grid item xs={xsCellSize}>
              <Typography variant="h5">{t('translations:emptyStates.balances')}</Typography>
            </Grid>
          )}
        </Grid>
      </CustomLoadingGuard>
    </Box>
  );
}
