import { Stack } from '@mui/material';
import CustomButton from 'components/CustomButton';
import { useSearchQueryParam } from 'hooks/useSearchQueryParam';
import { orderBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { COMPONENT_MODES } from 'utils/constants';
import { getComponentModeTitle } from 'utils/helpers';
import { SearchBar } from 'views/Period/Expanses/SearchBar';
import { ScraperSecret } from '../../../../../shared-resources/INTERFACES';
import CustomDialog from '../../../components/CustomDialog';
import CustomLoadingGuard from '../../../components/CustomLoadingGuard';
import CustomSpacer from '../../../components/CustomSpacer';
import { useDebounce } from '../../../hooks/useDebounce';
import { deleteScraperSecret, getScraperSecrets } from '../../../utils/apiUtils';
import { AddScraperSecret } from '../../AddScraperSecret/AddScraperSecret';
import { ManagementPageHeader } from '../ManagementPageHeader';
import { ScraperSecretItem } from './ScraperSecretItem';

export function ScraperSecrets(): React.ReactElement {
  const [scraperSecrets, setScraperSecrets] = React.useState<ScraperSecret[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [addScraperSecretModal, setAddScraperSecretModal] = React.useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState({
    idToDelete: '',
    open: false,
  });
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { handleSearchChange, search, handleResetSearch } = useSearchQueryParam({
    queryKey: 's',
  });
  const debounceSearch = useDebounce(search, 500);
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchScraperSecrets();
  }, []);

  const fetchScraperSecrets = async () => {
    setLoading(true);
    try {
      const data = await getScraperSecrets();
      setScraperSecrets(data);
    } catch (error) {
      //
    }
    setLoading(false);
  };

  const handleClose = (closeOptions: { fetchData: boolean }) => {
    const { fetchData } = closeOptions;
    if (fetchData) {
      fetchScraperSecrets();
    }

    setAddScraperSecretModal(false);
  };

  const handleDelete = async (id: string) => {
    setIsDeleting(true);
    try {
      await deleteScraperSecret(id);
      fetchScraperSecrets();
      closeDeleteModal();
    } catch (error) {
      //
    }
    setIsDeleting(false);
  };

  const openDeleteModal = (id: string) => {
    setDeleteConfirmation({
      idToDelete: id,
      open: true,
    });
  };

  const closeDeleteModal = () => {
    setDeleteConfirmation({
      idToDelete: '',
      open: false,
    });
  };

  return (
    <div>
      <ManagementPageHeader header={t('translations:scraperSecret.scraperSecret')} />
      <CustomSpacer vertical size="medium" />
      <Stack direction="row" spacing={2}>
        <SearchBar
          search={search}
          onSearch={handleSearchChange}
          onResetSearch={handleResetSearch}
        />
        <CustomButton onClick={() => setAddScraperSecretModal(true)}>
          {getComponentModeTitle(COMPONENT_MODES.ADD)}
        </CustomButton>
      </Stack>
      <CustomSpacer vertical size="medium" />
      <CustomLoadingGuard loading={loading}>
        {orderBy(
          matchSorter(scraperSecrets, debounceSearch, {
            keys: ['createdAt'],
          }),
          'createdAt',
          'desc'
        ).map((scraperSecret) => (
          <>
            <ScraperSecretItem
              scraperSecret={scraperSecret}
              key={scraperSecret._id}
              handleDelete={openDeleteModal}
            />
            <CustomSpacer vertical size="medium" />
          </>
        ))}
      </CustomLoadingGuard>
      {addScraperSecretModal && <AddScraperSecret onClose={handleClose} />}
      {deleteConfirmation.open && (
        <CustomDialog
          onClose={closeDeleteModal}
          onConfirm={() => handleDelete(deleteConfirmation.idToDelete)}
          title={t('translations:common.delete')}
          description={t('translations:common.deleteGenericDesc')}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
}
