import { CardContent, SxProps } from '@mui/material'

type Props = {
  children?: React.ReactNode
  sx?: SxProps
}

export function CustomCardContent(props: Props): React.ReactElement {
  const { children, sx } = props
  return (
    <CardContent
      sx={{
        p: 1,
        ...sx,
        ':last-child': {
          paddingBottom: 0,
        },
        ':first-child': {
          paddingTop: 0,
        },
      }}
    >
      {children}
    </CardContent>
  )
}
