import { Stack } from '@mui/material';
import CustomButton from 'components/CustomButton';
import { useSearchQueryParam } from 'hooks/useSearchQueryParam';
import { orderBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TypeSelection } from 'views/AddEditExpenseSnapshot/TypeSelection';
import { SearchBar } from 'views/Period/Expanses/SearchBar';
import { EXPANSE_TYPES } from '../../../../../shared-resources/CONSTANTS';
import { IPopulatedExpense } from '../../../../../shared-resources/INTERFACES';
import CustomLoadingGuard from '../../../components/CustomLoadingGuard';
import CustomSpacer from '../../../components/CustomSpacer';
import { getExpensesByHouseholdId } from '../../../utils/apiUtils';
import { COMPONENT_MODES } from '../../../utils/constants';
import { ManagementPageHeader } from '../ManagementPageHeader';
import { AddEditExpense } from './AddEditExpense';
import Expense from './Expense';

export default function Expenses(): React.ReactElement {
  const [expenses, setExpenses] = React.useState<IPopulatedExpense[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [expenseType, setExpenseType] = React.useState<EXPANSE_TYPES | undefined>(undefined);
  const { handleSearchChange, handleResetSearch, search } = useSearchQueryParam({
    queryKey: 's',
  });

  const { t } = useTranslation();
  const [modal, setModal] = React.useState({
    open: false,
    mode: COMPONENT_MODES.EDIT,
    expense: undefined as IPopulatedExpense | undefined,
  });

  React.useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async (isLoading = true) => {
    setLoading(isLoading);
    try {
      const data = await getExpensesByHouseholdId();
      setExpenses(data);
    } catch (error) {
      //
    }
    setLoading(false);
  };

  const handleCloseAddEdit = () => {
    setModal({ open: false, mode: COMPONENT_MODES.EDIT, expense: undefined });
  };

  const handleOpenAddEditModal = ({
    mode,
    expense,
  }: {
    mode: COMPONENT_MODES;
    expense?: IPopulatedExpense;
  }) => {
    setModal({ open: true, mode, expense });
  };

  const handleSuccess = () => {
    handleCloseAddEdit();
    fetchExpenses(false);
  };

  return (
    <div>
      <ManagementPageHeader
        header={t('translations:common.expenses')}
        buttonTitle={t('translations:period.floatButton.addExpense')}
        handleClick={() => handleOpenAddEditModal({ mode: COMPONENT_MODES.ADD })}
      />
      <CustomSpacer vertical size="medium" />
      <Stack direction="row" spacing={1}>
        <TypeSelection selectedType={expenseType} onChange={setExpenseType} />
        <CustomButton
          variant="outlined"
          onClick={() => setExpenseType(undefined)}
          disabled={!expenseType}
        >
          {t('translations:common.clear')}
        </CustomButton>
      </Stack>
      <CustomSpacer vertical size="small" />
      <SearchBar search={search} onSearch={handleSearchChange} onResetSearch={handleResetSearch} />
      <CustomSpacer vertical size="medium" />
      <CustomLoadingGuard loading={loading}>
        {orderBy(
          matchSorter(expenses, search, {
            keys: ['name', 'businessNames'],
          }),
          'createdAt',
          'desc'
        )
          .filter((expense) => {
            if (!expenseType) {
              return true;
            }
            return expense.type === expenseType;
          })
          .map((expense) => (
            <div key={expense._id.toString()}>
              <Expense expense={expense} />
              <CustomSpacer vertical size="medium" />
            </div>
          ))}
      </CustomLoadingGuard>
      {modal.open && (
        <AddEditExpense
          mode={modal.mode}
          expense={modal?.expense}
          onClose={handleCloseAddEdit}
          onSuccess={handleSuccess}
        />
      )}
    </div>
  );
}
