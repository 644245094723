import { Card, CardContent } from '@mui/material'
import { IEventPopulated } from '../../../../shared-resources/INTERFACES'
import { EntityChanges } from './EntityChanges'
import { EntityData } from './EntityData'

interface Props {
  event: IEventPopulated
}

export const HistoryItem = (props: Props) => {
  const { event } = props
  const { entityData } = event
  const { dataBefore, dataAfter } = entityData

  return (
    <Card>
      <CardContent>
        <EntityData event={event} />
        <EntityChanges dataBefore={dataBefore} dataAfter={dataAfter} />
      </CardContent>
    </Card>
  )
}
