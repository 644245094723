/* eslint-disable no-nested-ternary */
import {
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CustomTag } from 'components/CustomTag';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoExecutedFileTypes } from '../../../../shared-resources/CONSTANTS';
import { useAppSelector } from '../../hooks/useReactRedux';
import { MappedAutoExecutedFile } from '../../types/mappedAutoExecutedFiles';
import { closeExpenseSnapshot } from '../../utils/apiUtils';
import { isExpenseSnapshotFullyExecuted } from '../../utils/helpers';
import { COMMON_ICONS, PERIOD_ACTION_ICONS } from '../../utils/icons';
import ExpanseItemSummary from '../../views/Period/ExpanseItem/ExpanseItemSummary';
import CustomButton from '../CustomButton';
import CustomDialog from '../CustomDialog';
import CustomIcon from '../CustomIcon';
import CustomSpacer from '../CustomSpacer';
import { UploadFromFileContext } from './UploadFromFileContext';
import { UploadedFileBox } from './UploadedFileBox';

interface Props {
  rawRow: MappedAutoExecutedFile;
  isAutoExecutedFile?: boolean;
}

export const FileRow = (props: Props) => {
  const { rawRow, isAutoExecutedFile } = props;
  const { handleDeleteRow, handleOpenExpensesModal } = useContext(UploadFromFileContext);
  const { expenses } = useAppSelector((state) => state.expenses);
  const { t } = useTranslation();
  const theme = useTheme();
  const isRefund = !!(rawRow?.amount && rawRow?.amount < 0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const expenseSnapshot = expenses.find(
    (expense) => expense._id.toString() === rawRow.expenseSnapshotRef
  );
  const { isFullyExecuted } = isExpenseSnapshotFullyExecuted(expenseSnapshot);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const displayTag = isAutoExecutedFile || isRefund;

  const handleExecuteOnDone = async () => {
    setIsLoading(true);
    if (!expenseSnapshot) return;
    try {
      await closeExpenseSnapshot(expenseSnapshot?._id);
      setOpenModal(false);
    } catch (error) {
      //
    }
    setIsLoading(false);
  };

  return (
    <ListItem
      onClick={() => !isAutoExecutedFile && handleOpenExpensesModal(rawRow)}
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: 1,
        border: `1px solid ${theme.palette.grey[300]}`,
        my: 1,
        cursor: isAutoExecutedFile ? 'default' : 'pointer',
        ':hover': isAutoExecutedFile
          ? {}
          : {
              opacity: 0.8,
              backgroundColor: theme.palette.action.hover,
            },
      }}
      key={`item-${rawRow.uuid}`}
    >
      <ListItemText
        primary={
          <Stack flexDirection="column" width="100%">
            {displayTag && (
              <CustomTag
                backgroundColor={isRefund ? theme.palette.info.main : theme.palette.success.main}
                sx={{
                  alignItems: 'center',
                  display: 'inline-flex',
                }}
              >
                <CustomIcon
                  icon={COMMON_ICONS.CHECK}
                  color={
                    isRefund ? theme.palette.info.contrastText : theme.palette.success.contrastText
                  }
                />
                <CustomSpacer horizontal size="small" />
                <Typography
                  variant="caption"
                  color={
                    isRefund ? theme.palette.info.contrastText : theme.palette.success.contrastText
                  }
                >
                  {isRefund
                    ? t('translations:common.refund')
                    : t('translations:addExpensesFromFile.autoExecuted')}
                </Typography>
              </CustomTag>
            )}
            <Stack
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <UploadedFileBox rawRow={rawRow} />
              {isAutoExecutedFile && (
                <Stack flexDirection="row" alignItems="center">
                  <Typography variant="caption">
                    {rawRow.type === AutoExecutedFileTypes.EXECUTION_UPDATE
                      ? t('translations:common.executeUpdate')
                      : t('translations:common.expense')}
                  </Typography>
                  <CustomSpacer horizontal size="small" />
                  <CustomButton
                    onClick={() => setOpenModal(true)}
                    variant="contained"
                    size="small"
                    disabled={isFullyExecuted}
                    isLoading={isLoading}
                  >
                    {t('translations:expenses.addEditExpense.executeOnDone')}
                  </CustomButton>
                </Stack>
              )}
              {!isAutoExecutedFile && (
                <Stack flexDirection="row" alignItems="center">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleDeleteRow(rawRow.uuid as string);
                    }}
                  >
                    <CustomIcon icon={COMMON_ICONS.DELETE} />
                  </IconButton>
                  <CustomSpacer horizontal size="small" />
                  <IconButton onClick={() => handleOpenExpensesModal(rawRow)}>
                    <CustomIcon icon={PERIOD_ACTION_ICONS.ADD_EXPENSE} />
                  </IconButton>
                </Stack>
              )}
            </Stack>
            {isAutoExecutedFile && expenseSnapshot && (
              <>
                <CustomSpacer vertical size="small" />
                <Divider />
                <CustomSpacer vertical size="small" />
                <ExpanseItemSummary expense={expenseSnapshot} hideActions />
              </>
            )}
          </Stack>
        }
      />
      {openModal && (
        <CustomDialog
          onConfirm={handleExecuteOnDone}
          onClose={() => setOpenModal(false)}
          title={t('translations:expenses.actions.closeExpense')}
          description={t('translations:expenses.actions.closeExpenseDescription')}
          isLoading={isLoading}
        />
      )}
    </ListItem>
  );
};
