import { CardHeader, Stack, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { NOTE_MODELS } from '../../../../shared-resources/CONSTANTS'
import { IPopulatedNote } from '../../../../shared-resources/INTERFACES'
import CustomButton from '../../components/CustomButton'
import { CustomCardContent } from '../../components/CustomCardContent'
import CustomLoadingGuard from '../../components/CustomLoadingGuard'
import CustomModal from '../../components/CustomModal'
import CustomSpacer from '../../components/CustomSpacer'
import { i18n } from '../../i18n/i18n'
import { getNoteById, postNote, putNote } from '../../utils/apiUtils'
import { COMPONENT_MODES } from '../../utils/constants'
import { getComponentModeTitle } from '../../utils/helpers'

type Props = {
  modalName?: NOTE_MODELS
  onClose: () => void
  id?: string
  documentRef?: string
}

const validationSchema = Yup.object().shape({
  content: Yup.string().required(i18n.t('translations:errors.required')),
})

export function AddEditNote({ modalName, documentRef, id, onClose }: Props): React.ReactElement {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)

  const mode = id ? COMPONENT_MODES.EDIT : COMPONENT_MODES.ADD

  const formik = useFormik({
    initialValues: {
      modalName,
      documentRef,
      content: '',
    } as IPopulatedNote,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    if (!id) return
    fetchNote()
  }, [id])

  const fetchNote = async () => {
    if (!id) return
    try {
      setLoading(true)
      const response = await getNoteById(id)
      formik.setValues(response)
    } catch (error) {
      //
    } finally {
      setLoading(false)
    }
  }

  async function onSubmit() {
    if (id) {
      handlePutNote(formik.values)
    } else {
      handlePostNote(formik.values)
    }
  }

  const handlePostNote = async (values: IPopulatedNote) => {
    setLoading(true)
    try {
      await postNote(values)
      onClose()
    } catch (error) {
      setLoading(false)
    }
  }

  const handlePutNote = async (values: IPopulatedNote) => {
    if (!id) return
    setLoading(true)
    try {
      await putNote(values, id)
      onClose()
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <CustomModal onClose={onClose}>
      <form
        style={{
          height: '100%',
        }}
        onSubmit={formik.handleSubmit}
      >
        <Stack flexDirection="column" justifyContent="space-between" height="100%" overflow="auto">
          <CustomCardContent>
            <CustomLoadingGuard loading={loading}>
              <CardHeader title={getComponentModeTitle(mode)} />
              <TextField
                fullWidth
                label={t('translations:note.content')}
                margin="normal"
                name="content"
                onChange={formik.handleChange}
                required
                value={formik.values.content}
                variant="outlined"
                rows={4}
                error={Boolean(formik.touched.content && formik.errors.content)}
                helperText={formik.touched.content && formik.errors.content}
                autoFocus
              />
              <CustomSpacer vertical size="large" />
            </CustomLoadingGuard>
          </CustomCardContent>
          <CustomButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            isLoading={loading}
          >
            {getComponentModeTitle(mode)}
          </CustomButton>
        </Stack>
      </form>
    </CustomModal>
  )
}
