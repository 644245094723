import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Button, Menu, MenuItem, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { orderBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomActionMoreButton from '../../../../components/ActionMoreButton';
import CustomDialog from '../../../../components/CustomDialog';
import CustomIcon from '../../../../components/CustomIcon';
import CustomLoadingGuard from '../../../../components/CustomLoadingGuard';
import CustomSpacer from '../../../../components/CustomSpacer';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { useAppSelector } from '../../../../hooks/useReactRedux';
import { IPeriodSlice } from '../../../../slices/currentPeriodSlice';
import { deletePeriod } from '../../../../utils/apiUtils';
import { PERIOD_SELECTION_ACTIONS } from '../../../../utils/buttonActions';
import {
  ACTION_ITEMS,
  APP_ROUTES,
  COMPONENT_MODES,
  LOCAL_STORAGE_KEYS,
} from '../../../../utils/constants';
import { getDate } from '../../../../utils/helpers';
import { AddEditPeriod } from '../../../AddEditPeriod/AddEditPeriod';

enum MonthItemType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

const MonthItem = ({ period, type }: { period: IPeriodSlice; type: MonthItemType }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!period) return null;

  const mainVariant = type === MonthItemType.PRIMARY ? 'h5' : 'body1';
  const secondaryVariant = type === MonthItemType.PRIMARY ? 'subtitle2' : 'caption';

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'flex-start' : 'baseline'}
      justifyContent={isMobile ? 'center' : 'flex-start'}
      sx={{ width: '100%' }}
    >
      <Typography variant={mainVariant} noWrap>
        {t('translations:dateAndTime.month')} {getDate({ date: period?.startDate, format: 'M' })},
      </Typography>
      <CustomSpacer horizontal size="small" />
      <Typography variant={secondaryVariant}>
        {getDate({ date: period.startDate, format: 'DD/MM/YYYY' })}
        {' - '}
        {getDate({ date: period.endDate, format: 'DD/MM/YYYY' })}
      </Typography>
    </Stack>
  );
};
export default function PeriodSelection(): React.ReactElement {
  const { loading: loadingPeriods, periods } = useAppSelector((state) => state.allPeriod);
  const { loading: loadingPeriod, period } = useAppSelector((state) => state.currentPeriod);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { setValue } = useLocalStorage(LOCAL_STORAGE_KEYS.LAST_PERIOD);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (selectedPeriod: IPeriodSlice) => {
    handleClose();
    navigate(`/${APP_ROUTES.PERIOD}/${selectedPeriod._id}`, {
      replace: true,
    });
    setValue(selectedPeriod._id);
  };

  const handleOpenConfirmation = () => {
    setConfirmModal(true);
  };

  const handleCloseModals = () => {
    setConfirmModal(false);
    setEditModal(false);
  };

  const handleOpenEditModal = () => {
    setEditModal(true);
  };

  const handleDeletePeriod = async () => {
    try {
      await deletePeriod(period._id.toString());
      handleCloseModals();
      navigate(`/${APP_ROUTES.PERIOD}`);
    } catch (error) {
      //
    }
  };

  const handleAction = (action: ACTION_ITEMS) => {
    switch (action) {
      case ACTION_ITEMS.EDIT:
        handleOpenEditModal();
        break;
      case ACTION_ITEMS.DELETE:
        handleOpenConfirmation();
        break;
      default:
        break;
    }
  };

  return (
    <CustomLoadingGuard loading={loadingPeriods || loadingPeriod}>
      {periods.length === 0 ? (
        <Typography variant="h5">{t('translations:emptyStates.period')}</Typography>
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              width: '100%',
            }}
          >
            <Button onClick={handleOpen}>
              <Stack direction="row" alignItems="center">
                <MonthItem period={period} type={MonthItemType.PRIMARY} />
                <CustomSpacer horizontal size="medium" />
                <CustomIcon icon={faChevronDown} />
              </Stack>
            </Button>
            <Stack direction="row" alignItems="center">
              <CustomActionMoreButton actions={PERIOD_SELECTION_ACTIONS} onClick={handleAction} />
            </Stack>
          </Stack>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {orderBy(periods, ['startDate'], 'desc').map((elem) => (
              <MenuItem
                key={elem._id.toString()}
                onClick={() => handleSelect(elem)}
                selected={elem?._id?.toString() === period?._id?.toString()}
              >
                <MonthItem period={elem} type={MonthItemType.SECONDARY} />
              </MenuItem>
            ))}
          </Menu>
          {confirmModal && (
            <CustomDialog
              title={t('translations:common.delete')}
              description={t('translations:common.deleteGenericDesc')}
              onClose={handleCloseModals}
              onConfirm={handleDeletePeriod}
            />
          )}
          {editModal && <AddEditPeriod mode={COMPONENT_MODES.EDIT} onClose={handleCloseModals} />}
        </>
      )}
    </CustomLoadingGuard>
  );
}
