import { Backdrop, Button, useTheme } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import CustomTypography from 'components/CustomTypography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../components/CustomDialog';
import CustomIcon from '../../components/CustomIcon';
import { UploadFromFile } from '../../components/UploadFromFile/UploadFromFile';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useAppSelector } from '../../hooks/useReactRedux';
import { i18n } from '../../i18n/i18n';
import { ACTION_ITEMS, COMPONENT_MODES, DRAWER_WIDTH, FLOAT_PADDING } from '../../utils/constants';
import { PERIOD_ACTION_ICONS } from '../../utils/icons';
import { AddEditExpenseSnapshot } from '../AddEditExpenseSnapshot/AddEditExpenseSnapshot';
import { AddEditPeriod } from '../AddEditPeriod/AddEditPeriod';
import { AddBalanceSnapshot } from './PeriodSummary/AddBalanceSnapshot';

const ADD_PERIOD_ITEM = {
  icon: <CustomIcon icon={PERIOD_ACTION_ICONS.ADD_PERIOD} size="lg" />,
  name: i18n.t('translations:period.floatButton.addPeriod'),
  action: ACTION_ITEMS.ADD_PERIOD,
};

const BUTTON_ITEMS = [
  ADD_PERIOD_ITEM,
  {
    icon: <CustomIcon icon={PERIOD_ACTION_ICONS.ADD_BALANCE} size="lg" />,
    name: i18n.t('translations:period.floatButton.addBalance'),
    action: ACTION_ITEMS.ADD_BALANCE,
  },
  {
    icon: <CustomIcon icon={PERIOD_ACTION_ICONS.ADD_EXPENSE_FROM_FILE} size="lg" />,
    name: i18n.t('translations:period.floatButton.addExpensesFromFile'),
    action: ACTION_ITEMS.ADD_EXPENSES_FROM_FILE,
  },
  {
    icon: <CustomIcon icon={PERIOD_ACTION_ICONS.ADD_EXPENSE} size="lg" />,
    name: i18n.t('translations:period.floatButton.addExpense'),
    action: ACTION_ITEMS.ADD_EXPENSE,
  },
];

type IInit = {
  type?: ACTION_ITEMS;
  open: boolean;
};

const openModalInit: IInit = {
  type: undefined,
  open: false,
};

export default function FloatButton() {
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(openModalInit);
  const { period, loading: periodsLoading } = useAppSelector((state) => state.currentPeriod);
  const { loading: expensesLoading } = useAppSelector((state) => state.expenses);
  const { loading: balancesLoading } = useAppSelector((state) => state.balances);
  const { loading: currentPeriodLoading } = useAppSelector((state) => state.currentPeriod);
  const loading = periodsLoading || expensesLoading || balancesLoading || currentPeriodLoading;
  const { t } = useTranslation();
  const { zIndex } = useTheme();
  const floatButtonActions = !period._id ? [ADD_PERIOD_ITEM] : BUTTON_ITEMS;

  const handleClick = () => setOpen((prev) => !prev);
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = React.useRef<HTMLDivElement>(null);
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, handleClose);

  function handleClose() {
    setOpen(false);
  }

  const handleActionClick = (action: ACTION_ITEMS) => {
    setOpenModal({
      type: action,
      open: true,
    });
  };

  const handleModalClose = () => {
    setOpenModal(openModalInit);
  };

  return loading ? null : (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: zIndex.appBar - 1 }}
        open={open}
        onClick={handleClose}
      />
      <SpeedDial
        ariaLabel="SpeedDial"
        sx={{
          position: 'absolute',
          bottom: FLOAT_PADDING,
          left: { md: DRAWER_WIDTH + FLOAT_PADDING, xs: FLOAT_PADDING },
          zIndex: zIndex.appBar,
        }}
        icon={<SpeedDialIcon />}
        open={open}
        onClick={handleClick}
      >
        {floatButtonActions.map((a) => (
          <SpeedDialAction
            key={a.name}
            icon={a.icon}
            tooltipTitle={
              <Button>
                <CustomTypography noWrap>
                  <b>{a.name}</b>
                </CustomTypography>
              </Button>
            }
            tooltipOpen
            onClick={() => handleActionClick(a.action)}
            tooltipPlacement="right"
          />
        ))}
      </SpeedDial>
      {openModal.open && openModal.type === ACTION_ITEMS.ADD_EXPENSE && (
        <AddEditExpenseSnapshot
          mode={COMPONENT_MODES.ADD}
          onClose={handleModalClose}
          onSuccessfulExecute={handleModalClose}
        />
      )}
      {openModal.open && openModal.type === ACTION_ITEMS.ADD_BALANCE && (
        <AddBalanceSnapshot onClose={handleModalClose} />
      )}
      {openModal.open && openModal.type === ACTION_ITEMS.ADD_PERIOD && (
        <AddEditPeriod mode={COMPONENT_MODES.ADD} onClose={handleModalClose} />
      )}
      {openModal.open && openModal.type === ACTION_ITEMS.DELETE_PERIOD && (
        <CustomDialog
          onConfirm={handleModalClose}
          onClose={handleModalClose}
          title={t('translations:period.addEditPeriod.deletePeriod')}
          description={t('translations:period.addEditPeriod.deletePeriodDescription')}
        />
      )}
      {openModal.open && openModal.type === ACTION_ITEMS.ADD_EXPENSES_FROM_FILE && (
        <UploadFromFile onClose={handleModalClose} />
      )}
    </>
  );
}
