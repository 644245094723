import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CustomTypography from 'components/CustomTypography'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { version } from '../../../package.json'
import Page from '../components/Page'
import { MainContext } from '../context/MainContext'
import { firebaseAuth } from '../hooks/useFirebaseAuth'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { APP_ROUTES, LOCAL_STORAGE_KEYS } from '../utils/constants'

const provider = new GoogleAuthProvider()

export default function Home(): React.ReactElement {
  const { isLoggedIn } = React.useContext(MainContext)
  const { storedValue } = useLocalStorage(LOCAL_STORAGE_KEYS.LAST_ROUTE)

  if (isLoggedIn) {
    return <Navigate to={`/${storedValue || APP_ROUTES.DASHBOARD}`} replace />
  }

  const handleLogin = async (): Promise<void> => {
    try {
      await signInWithPopup(firebaseAuth, provider)
    } catch (error) {
      // handle error
    }
  }

  return (
    <Page title="Home">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button onClick={handleLogin} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign in with Google
          </Button>
        </Box>
        <CustomTypography>{version}</CustomTypography>
      </Box>
    </Page>
  )
}
