import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/useReactRedux'
import { ExpanseItemMemo } from '../Period/ExpanseItem/ExpanseItem'

interface Props {
  amount?: number
}

export const SimilarAmountBox = (props: Props) => {
  const { amount } = props
  const { expenses } = useAppSelector((state) => state.expenses)
  const { t } = useTranslation()

  const similarExpenses = amount
    ? expenses?.filter((expense) => {
        if (amount === expense.amount) return true

        if (amount >= expense.amount - 0.5 && amount <= expense.amount + 0.5) {
          return true
        }

        if (
          expense.executionUpdatesRef?.filter(
            (execution) =>
              amount === execution.amount ||
              (amount >= execution.amount - 0.5 &&
                amount <= execution.amount + 0.5)
          ).length
        ) {
          return true
        }

        return false
      })
    : []

  const count = similarExpenses.length
  const translatedDisplayType = t('translations:common.expenses')
  const title = t('translations:similarAmountBox.title', {
    displayType: translatedDisplayType,
    count,
  })
  const noSimilarTitle = t('translations:similarAmountBox.noSimilar', {
    displayType: translatedDisplayType,
  })

  return (
    <Accordion
      disabled={!similarExpenses.length}
      sx={{
        borderRadius: 1,
        ':before': {
          opacity: 0,
        },
      }}
    >
      <AccordionSummary sx={{}} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="caption">
          {!similarExpenses.length ? noSimilarTitle : title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack flexDirection="column">
          {similarExpenses.length > 0 &&
            similarExpenses?.map((expense) => (
              <ExpanseItemMemo key={expense._id.toString()} expense={expense} />
            ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
