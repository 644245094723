import React from 'react'
import { LocalSettings } from 'src/types/localSettings'

interface IMainContext {
  isLoggedIn: boolean
  toggleColorMode: () => void
  localSettings: LocalSettings
  setLocalSettings: React.Dispatch<React.SetStateAction<LocalSettings>>
}
export const MainContext = React.createContext<IMainContext>(null as unknown as IMainContext)
