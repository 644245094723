import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Button, FormHelperText, Menu, MenuItem, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BALANCE_TYPES } from '../../../../shared-resources/CONSTANTS'
import { IBalanceSnapshotPopulated } from '../../../../shared-resources/INTERFACES'
import CustomIcon from '../../components/CustomIcon'
import CustomSpacer from '../../components/CustomSpacer'
import { useAppSelector } from '../../hooks/useReactRedux'
import { getBalancesSnapshotByPeriod } from '../../utils/apiUtils'
import { BALANCE_TYPE_ICONS } from '../../utils/icons'

type Props = {
  onChange: (savingRef: string) => void
  selectedSaving?: string
  error?: boolean
  helperText?: string | false
  disabled?: boolean
}

interface SavingItemProps {
  name: string
  type: BALANCE_TYPES
}

const SavingItem = ({ name, type }: SavingItemProps) => (
  <Stack direction="row" alignItems="center">
    <CustomIcon icon={BALANCE_TYPE_ICONS[type]} />
    <CustomSpacer horizontal size="small" />
    {name}
  </Stack>
)

export function SavingSelection(props: Props): React.ReactElement {
  const { onChange, selectedSaving, error, helperText, disabled } = props
  const [savings, setSavings] = React.useState<IBalanceSnapshotPopulated[]>([])
  const { period } = useAppSelector((state) => state.currentPeriod)
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const selected = savings.find((b) => b._id === selectedSaving)

  React.useEffect(() => {
    fetchSavings()
  }, [])

  const fetchSavings = async () => {
    try {
      const data = await getBalancesSnapshotByPeriod(period._id.toString())
      setSavings(data.filter((balance) => balance.balanceRef.type === BALANCE_TYPES.SAVINGS))
    } catch (resError) {
      //
    }
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (balanceRef: IBalanceSnapshotPopulated) => {
    onChange(balanceRef._id.toString())
    handleClose()
  }

  return (
    <>
      <Button
        disabled={savings.length === 0 || disabled}
        variant="outlined"
        onClick={handleOpen}
        fullWidth
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderLeft: (theme) =>
            `5px solid ${selected?.balanceRef.color ?? theme.palette.primary.main}`,
          '&:hover': {
            borderLeft: (theme) =>
              `5px solid ${selected?.balanceRef.color ?? theme.palette.primary.main}`,
          },
        }}
      >
        {selected?.balanceRef.name ? (
          <SavingItem name={selected?.balanceRef.name} type={selected?.balanceRef.type} />
        ) : (
          t('translations:expenses.addEditExpense.selectSaving')
        )}
        <CustomSpacer horizontal size="medium" />
        <CustomIcon icon={faChevronDown} />
      </Button>
      <FormHelperText error={error}>{helperText}</FormHelperText>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {savings.map((saving) => (
          <MenuItem
            key={saving._id.toString()}
            onClick={() => handleChange(saving)}
            sx={{
              borderLeft: `5px solid ${saving.balanceRef.color}`,
            }}
            selected={selected?._id.toString() === saving._id.toString()}
          >
            <SavingItem name={saving.balanceRef.name} type={saving.balanceRef.type} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
