/* eslint-disable no-restricted-syntax */
import { Divider, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BALANCE_TYPES } from '../../../../shared-resources/CONSTANTS'
import { PopulateCCName, Transaction } from '../../../../shared-resources/INTERFACES'
import { useAppSelector } from '../../hooks/useReactRedux'
import { getCCNames } from '../../utils/apiUtils'
import { CustomGenericSelection } from '../CustomGenericSelection'
import CustomLoadingGuard from '../CustomLoadingGuard'
import CustomSpacer from '../CustomSpacer'

interface Props {
  rows?: Transaction[]
  selectedBalances?: Record<string, string>
  onChange: ({ card, balanceId }: { card: string; balanceId: string }) => void
}

export const BalanceLinking = (props: Props) => {
  const { rows, selectedBalances, onChange } = props
  const { t } = useTranslation()
  const selectedBalanceValues = selectedBalances && Object.values(selectedBalances)
  const { balances } = useAppSelector((state) => state.balances)
  const [isLoading, setIsLoading] = React.useState(true)
  const [ccNames, setCCNames] = React.useState<PopulateCCName[]>()

  const filteredBalances = balances.filter(
    (balance) => balance.balanceRef.type === BALANCE_TYPES.CC
  )
  const cardNames = rows?.reduce((acc: string[], curr: Transaction) => {
    if (curr.cardNumber && !acc.includes(curr.cardNumber)) {
      acc.push(curr.cardNumber)
    }
    return acc
  }, [] as string[])
  const mappedBalances = filteredBalances.map((balance) => ({
    value: balance._id.toString(),
    label: balance.balanceRef.name,
    disabled: selectedBalanceValues?.includes(balance._id.toString()),
  }))

  useEffect(() => {
    fetchCCNames()
  }, [])

  useEffect(() => {
    if (!ccNames?.length || !cardNames?.length) return

    const updateBalances = () => {
      for (const cardName of cardNames) {
        const existingCardName = ccNames.find(({ name }) => name === cardName)
        const existingBalanceSnapshot = balances.find(
          ({ balanceRef }) => balanceRef._id === existingCardName?.balanceRef._id
        )

        if (existingBalanceSnapshot) {
          onChange({
            card: cardName,
            balanceId: existingBalanceSnapshot._id,
          })
        }
      }
    }

    updateBalances()
  }, [ccNames])

  async function fetchCCNames() {
    setIsLoading(true)
    try {
      const data = await getCCNames()
      setCCNames(data)
    } catch (error) {
      //
    }
    setIsLoading(false)
  }

  return (
    <Stack flexDirection="column">
      <CustomLoadingGuard loading={isLoading}>
        {cardNames?.map((card, index) => (
          <Stack key={card} flexDirection="column">
            <Stack flexDirection="row" alignItems="center">
              <Typography>
                {t('translations:addExpensesFromFile.cardName')}: {card}
              </Typography>
              <CustomSpacer horizontal size="small" />
              <CustomGenericSelection
                options={mappedBalances}
                onChange={(balanceId) =>
                  onChange({
                    card,
                    balanceId: balanceId as string,
                  })
                }
                selected={selectedBalances?.[card]}
              />
            </Stack>
            {index !== cardNames.length - 1 && (
              <Divider
                sx={{
                  my: 2,
                }}
              />
            )}
          </Stack>
        ))}
      </CustomLoadingGuard>
    </Stack>
  )
}
