import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import CustomSpacer from '../../components/CustomSpacer'
import Page from '../../components/Page'
import { i18n } from '../../i18n/i18n'
import { APP_ROUTES, MANAGEMENT_TABS } from '../../utils/constants'
import Balances from './Balances/Balances'
import CCNames from './CCNAmes/CCNames'
import ExcludedBusinessNames from './ExcludedBusinessNames/ExcludedBusinessNames'
import Expenses from './Expenses/Expenses'
import Households from './Households/Households'
import Notes from './Notes/Notes'
import { ScraperSecrets } from './ScraperSecret/ScraperSecrets'

const TAB_TITLES: Record<string, string> = {
  [MANAGEMENT_TABS.BALANCES]: i18n.t('translations:management.balances'),
  [MANAGEMENT_TABS.EXPENSES]: i18n.t('translations:common.expenses'),
  [MANAGEMENT_TABS.HOUSEHOLDS]: i18n.t('translations:navigation.households'),
  [MANAGEMENT_TABS.NOTES]: i18n.t('translations:navigation.notes'),
  [MANAGEMENT_TABS.CC_NAMES]: i18n.t('translations:ccName.ccNames'),
  [MANAGEMENT_TABS.SCRAPER_SECRETS]: i18n.t('translations:scraperSecret.scraperSecret'),
  [MANAGEMENT_TABS.EXCLUDE_BUSINESS_NAMES]: i18n.t('translations:excludedBusinessName.title'),
}

const TABS = {
  [MANAGEMENT_TABS.EXPENSES]: 0,
  [MANAGEMENT_TABS.BALANCES]: 1,
  [MANAGEMENT_TABS.HOUSEHOLDS]: 2,
  [MANAGEMENT_TABS.NOTES]: 3,
  [MANAGEMENT_TABS.CC_NAMES]: 4,
  [MANAGEMENT_TABS.SCRAPER_SECRETS]: 5,
  [MANAGEMENT_TABS.EXCLUDE_BUSINESS_NAMES]: 6,
}

const TAB_KEYS = Object.keys(TABS)

export default function Management() {
  const { tab } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const convertedTab = TAB_KEYS.findIndex((key) => key === tab)

  // React.useEffect(() => {
  //   if (!tab) {
  //     navigate(`/${APP_ROUTES.MANAGEMENT}/${TAB_KEYS[0]}`, {
  //       replace: true,
  //     })
  //   }
  // }, [])

  const handleChange = (newTab: string) => {
    navigate(`/${APP_ROUTES.MANAGEMENT}/${newTab}`, {
      replace: true,
    })
  }

  return !tab ? null : (
    <Page title={t('translations:navigation.management')}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={convertedTab}>
          {Object.keys(TABS).map((tabKey) => (
            <Tab key={tabKey} label={TAB_TITLES[tabKey]} onClick={() => handleChange(tabKey)} />
          ))}
        </Tabs>
      </Box>
      <CustomSpacer vertical size="medium" />
      {convertedTab === TABS.balances && <Balances />}
      {convertedTab === TABS.expenses && <Expenses />}
      {convertedTab === TABS.households && <Households />}
      {convertedTab === TABS.notes && <Notes />}
      {convertedTab === TABS.ccNames && <CCNames />}
      {convertedTab === TABS.scraperSecrets && <ScraperSecrets />}
      {convertedTab === TABS.excludeBusinessNames && <ExcludedBusinessNames />}
    </Page>
  )
}
