import { CardHeader, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopulateCCName } from 'shared-resources/INTERFACES';
import * as Yup from 'yup';
import CustomButton from '../../components/CustomButton';
import { CustomCardContent } from '../../components/CustomCardContent';
import CustomModal from '../../components/CustomModal';
import CustomSpacer from '../../components/CustomSpacer';
import { i18n } from '../../i18n/i18n';
import { postCCName } from '../../utils/apiUtils';
import { COMPONENT_MODES } from '../../utils/constants';
import { getComponentModeTitle } from '../../utils/helpers';
import { BalanceSelection } from '../Managmenet/Expenses/BalanceSelection';

type Props = {
  onClose: (closeOptions: { fetchData: boolean }) => void;
  ccNames?: PopulateCCName[];
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(i18n.t('translations:errors.required'))
    .matches(/^[0-9]+$/, i18n.t('translations:errors.cardNameLength'))
    .min(4, i18n.t('translations:errors.cardNameLength'))
    .max(4, i18n.t('translations:errors.cardNameLength')),
  balanceRef: Yup.string().required(i18n.t('translations:errors.required')),
});

export function AddCCName({ onClose, ccNames }: Props): React.ReactElement {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const balancesInUse = ccNames?.map((ccName) => ccName.balanceRef._id) || [];

  const formik = useFormik({
    initialValues: {
      balanceRef: '',
      name: '',
    },
    validationSchema,
    onSubmit,
  });

  async function onSubmit() {
    setIsLoading(true);
    try {
      await postCCName({
        balanceRef: formik.values.balanceRef,
        name: formik.values.name,
      });
      onClose({
        fetchData: true,
      });
    } catch (error) {
      //
    }
    setIsLoading(false);
  }

  return (
    <CustomModal
      onClose={() =>
        onClose({
          fetchData: false,
        })
      }
    >
      <form
        style={{
          height: '100%',
        }}
        onSubmit={formik.handleSubmit}
      >
        <Stack flexDirection="column" justifyContent="space-between" height="100%" overflow="auto">
          <CustomCardContent>
            <CardHeader title={getComponentModeTitle(COMPONENT_MODES.ADD)} />
            <TextField
              size="small"
              fullWidth
              name="name"
              label={t('translations:common.name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              autoFocus
            />
            <CustomSpacer vertical size="medium" />
            <BalanceSelection
              onChange={(balanceRef) => formik.setFieldValue('balanceRef', balanceRef)}
              selectedBalance={formik.values.balanceRef}
              disabled={isLoading}
              error={Boolean(formik.errors.balanceRef)}
              filterBalances={balancesInUse}
            />
            <CustomSpacer vertical size="large" />
          </CustomCardContent>
          <CustomButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            isLoading={isLoading}
          >
            {getComponentModeTitle(COMPONENT_MODES.ADD)}
          </CustomButton>
        </Stack>
      </form>
    </CustomModal>
  );
}
