import { Badge, Divider, IconButton, Popover } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { INote } from '../../../shared-resources/INTERFACES'
import { FlexDiv } from '../styledComponents/FlexDiv'
import { deleteNote } from '../utils/apiUtils'
import { COMMON_ICONS } from '../utils/icons'
import CustomDialog from './CustomDialog'
import CustomIcon from './CustomIcon'
import CustomSpacer from './CustomSpacer'
import CustomTypography from './CustomTypography'

interface Props {
  notes: INote[]
  searchValue?: string
}

export default function NotesMenu(props: Props): React.ReactElement | null {
  const { notes, searchValue } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [confirmationDialog, setConfirmationDialog] = React.useState({
    open: false,
    noteId: '',
  })
  const [loading, setLoading] = React.useState(false)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const { t } = useTranslation()

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation()
    setAnchorEl(null)
  }

  const handleDelete = async (noteId: string) => {
    setLoading(true)
    try {
      await deleteNote(noteId)
      setConfirmationDialog({ open: false, noteId: '' })
      setAnchorEl(null)
    } catch (error) {
      // console.error(error)
    }
    setLoading(false)
  }

  const handleModalClose = () => {
    setConfirmationDialog({
      open: false,
      noteId: '',
    })
  }

  return !notes || notes.length === 0 ? null : (
    <>
      <Badge
        color="primary"
        badgeContent={notes.length}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <IconButton onClick={handleOpen}>
          <CustomIcon icon={COMMON_ICONS.NOTE} />
        </IconButton>
      </Badge>
      {open && (
        <Popover
          onClick={(event) => event.stopPropagation()}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <FlexDiv
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: 16,
            }}
          >
            {notes.map((note, index) => (
              <div key={note._id.toString()} style={{ width: '100%' }}>
                <FlexDiv
                  style={{
                    justifyContent: 'space-between',
                  }}
                >
                  <CustomTypography searchValue={searchValue}>{note.content}</CustomTypography>
                  <CustomSpacer horizontal size="small" />
                  <IconButton
                    onClick={() =>
                      setConfirmationDialog({
                        open: true,
                        noteId: note._id.toString(),
                      })
                    }
                  >
                    <CustomIcon icon={COMMON_ICONS.DELETE} />
                  </IconButton>
                </FlexDiv>
                {index !== notes.length - 1 && (
                  <Divider
                    sx={{
                      mt: 1,
                      mb: 1,
                    }}
                  />
                )}
              </div>
            ))}
          </FlexDiv>
          {confirmationDialog.open && (
            <CustomDialog
              onConfirm={() => handleDelete(confirmationDialog.noteId)}
              onClose={handleModalClose}
              title={t('translations:common.delete')}
              description={t('translations:common.deleteGenericDesc')}
              isLoading={loading}
            />
          )}
        </Popover>
      )}
    </>
  )
}
