import { BALANCE_TYPES, COMMON_STATUES } from '../../../shared-resources/CONSTANTS'
import { i18n } from '../i18n/i18n'

export const BALANCE_TYPE_LABELS = {
  [BALANCE_TYPES.BANK]: i18n.t('translations:balances.bankAccount'),
  [BALANCE_TYPES.CC]: i18n.t('translations:balances.cc'),
  [BALANCE_TYPES.INCOME]: i18n.t('translations:balances.income'),
  [BALANCE_TYPES.SAVINGS]: i18n.t('translations:balances.savings'),
}

export const STATUS_LABELS = {
  [COMMON_STATUES.ACTIVE]: i18n.t('translations:status.active'),
  [COMMON_STATUES.INACTIVE]: i18n.t('translations:status.active'),
  [COMMON_STATUES.DELETED]: i18n.t('translations:status.active'),
}
