export const BASE_ENDPOINTS = {
  HOUSEHOLD: "household",
  USER: "user",
  INITIAL_DATA: "initial-data",
  BALANCE: "balance",
  EXPENSE: "expense",
  PERIOD: "period",
  BALANCE_SNAPSHOT: "balance-snapshot",
  EXPANSE_SNAPSHOT: "expanse-snapshot",
  EXECUTION_UPDATE: "execution-update",
  NOTE: "note",
  WIDGET: "widget",
  RRWEB: "rrweb",
  EXPENSES_FILE_PROCESSOR: "expenses-file-processor",
  CC_NAME: "cc-name",
  PENDING_TRANSACTION: "pending-transaction",
  SCRAPER_SECRET: "scraper-secret",
  BUSINESS_NAME: "business-name",
  EXCLUDED_BUSINESS_NAME: "excluded-business-name",
  EVENT: "event",
  PERIOD_EXPENSES_COMPARISON: "period-expenses-comparison",
};

export const ROUTES = {
  REMOVE_BUSINESS_NAME: "remove-business-name",
  ADD_BUSINESS_NAME: "add-business-name",
  AVERAGE: "average",
  EXPENSE_AVERAGES: "expense-averages",
  MOVE_EXECUTION_UPDATE: "move-execution-update",
};

export enum WIDGETS {
  OVERALL_WIDGET = "overallWidget",
}
