/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import { useParams } from 'react-router-dom'
import {
  IExpenseSnapshotPopulated,
  IPopulatedNote,
} from '../../../../../shared-resources/INTERFACES'
import { CustomCardContent } from '../../../components/CustomCardContent'
import CustomLoadingGuard from '../../../components/CustomLoadingGuard'
import CustomSpacer from '../../../components/CustomSpacer'
import Page from '../../../components/Page'
import { getExpensesSnapshotByNoteData, getNoteById } from '../../../utils/apiUtils'
import { ExpenseItem } from '../../Period/ExpanseItem/ExpanseItem'
import NoteItem from './NoteItem'

export const NotePage = () => {
  const { id } = useParams()
  const [loading, setLoading] = React.useState(true)
  const [note, setNote] = React.useState<IPopulatedNote>()
  const [expenseSnapshot, setExpenseSnapshot] = React.useState<IExpenseSnapshotPopulated>()

  React.useEffect(() => {
    if (!id) return
    fetchData(id)
  }, [id])

  const fetchData = async (noteId: string) => {
    setLoading(true)
    try {
      const noteData = await getNoteById(noteId)
      const expenseData = await getExpensesSnapshotByNoteData(noteId, noteData.modalName)
      setNote(noteData)
      setExpenseSnapshot(expenseData)
    } catch (error) {
      //
    }
    setLoading(false)
  }

  return !expenseSnapshot ? null : (
    <Page title={id}>
      <CustomLoadingGuard loading={loading}>
        <CustomCardContent>
          {note && <NoteItem note={note} disableRedirect />}
          <CustomSpacer vertical size="medium" />
          <ExpenseItem expense={expenseSnapshot} fullExecutionIndication={false} />
        </CustomCardContent>
      </CustomLoadingGuard>
    </Page>
  )
}
