import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPeriod } from '../../../shared-resources/INTERFACES';
import { getAllPeriods } from '../utils/apiUtils';

export type IPeriodSlice = Pick<
  IPeriod,
  '_id' | 'createdAt' | 'endDate' | 'startDate' | 'householdRef'
>;

export interface IInitialState {
  loading: boolean;
  periods: IPeriodSlice[];
}

const initialState: IInitialState = {
  loading: true,
  periods: [] as IPeriodSlice[],
};

export const getAllPeriodsThunk = createAsyncThunk(
  'allPeriodsSlice/getAllPeriodsThunk',
  async () => {
    const data = await getAllPeriods();
    return data;
  }
);

export const allPeriodsSlice = createSlice({
  name: 'allPeriodsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllPeriodsThunk.pending.toString()]: (state: IInitialState) => {
      state.loading = true;
    },
    [getAllPeriodsThunk.rejected.toString()]: (state: IInitialState) => {
      state.loading = false;
      state.periods = [];
    },
    [getAllPeriodsThunk.fulfilled.toString()]: (
      state: IInitialState,
      action: PayloadAction<IPeriodSlice[]>
    ) => {
      if (action.payload.length === 0) {
        state.periods = [];
        state.loading = false;
        return;
      }
      state.periods = action.payload;
      state.loading = false;
    },
  },
});
