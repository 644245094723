import { Stack, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import CustomPaper from '../../../../components/CustomPaper';
import PeriodSelection from './PeriodSelection';

export default function PeriodControl(): React.ReactElement {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CustomPaper
      sx={{
        bgcolor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={1}
        alignItems={isMobile ? 'flex-start' : 'baseline'}
        width="100%"
      >
        <PeriodSelection />
      </Stack>
    </CustomPaper>
  );
}
