import { IconButton, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { ExcludedBusinessName } from '../../../../../shared-resources/INTERFACES'
import { CustomCard } from '../../../components/CustomCard'
import { CustomCardContent } from '../../../components/CustomCardContent'
import CustomIcon from '../../../components/CustomIcon'
import CustomSpacer from '../../../components/CustomSpacer'
import { COMMON_ICONS } from '../../../utils/icons'

interface Props {
  excludedBusinessName: ExcludedBusinessName
  handleDelete: (id: string) => void
}

export function ExcludedBusinessNamesItem({
  excludedBusinessName,
  handleDelete,
}: Props): React.ReactElement {
  return (
    <CustomCard>
      <CustomCardContent>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography>{excludedBusinessName.name}</Typography>
          <CustomSpacer horizontal size="small" />
          <IconButton
            onClick={() => handleDelete(excludedBusinessName._id)}
            sx={{
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            <CustomIcon icon={COMMON_ICONS.DELETE} />
          </IconButton>
        </Stack>
      </CustomCardContent>
    </CustomCard>
  )
}
