import createCache, { StylisPlugin } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { prefixer } from 'stylis'
import rtlPlugin from 'stylis-plugin-rtl'

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin as unknown as StylisPlugin],
})

type Props = {
  children: React.ReactNode
}

export function RTL(props: Props): React.ReactElement {
  const { children } = props
  document.dir = 'rtl'
  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>
}
