import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { FirebaseState, fireBaseInitialState } from '../context/FirebaseContext'

// Initialize Firebase
export const firebaseApp = initializeApp({
  apiKey: import.meta.env.VITE_REACT_APP_API_KEY,
  authDomain: import.meta.env.VITE_REACT_APP_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_REACT_APP_PROJECT_ID,
  storageBucket: import.meta.env.VITE_REACT_APP_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_REACT_APP_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_REACT_APP_APP_ID,
  measurementId: import.meta.env.VITE_REACT_APP_MEASUREMENT_ID,
})

export const firebaseAuth = getAuth(firebaseApp)

// use auth hook - user authentication
export const useFirebaseAuth = () => {
  const [state, setState] = useState<FirebaseState>(fireBaseInitialState)

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setState((prev) => ({
          ...prev,
          initializing: false,
          firebaseUser: user,
        }))
      } else {
        setState((prev) => ({
          ...prev,
          initializing: false,
          firebaseUser: null,
        }))
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return { ...state, firebaseApp, firebaseAuth }
}
