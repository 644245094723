import { CardContent, DialogActions, DialogTitle, Stack, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoExecutedTransaction, Transaction } from '../../../../shared-resources/INTERFACES'
import { useAppSelector } from '../../hooks/useReactRedux'
import { postExpensesFileProcessor } from '../../utils/apiUtils'
import CustomButton from '../CustomButton'
import CustomLoading from '../CustomLoading'
import CustomModal from '../CustomModal'
import { Dropzone } from '../Dropzone'
import { BalanceLinking } from './BalanceLinking'
import { FileRows } from './FileRows'
import { FilesExpensesModal } from './FilesExpensesModal'
import { UploadFromFileContext } from './UploadFromFileContext'
import { filterRows } from './helpers'

interface Props {
  onClose: () => void
}

export const UploadFromFile = (props: Props) => {
  const { onClose } = props
  const [step, setStep] = useState(0)
  const { expenses } = useAppSelector((state) => state.expenses)
  const { period } = useAppSelector((state) => state.currentPeriod)
  const [rawRows, setRawRows] = useState<Transaction[]>([])
  const [selectedBalances, setSelectedBalances] = useState<Record<string, string>>()
  const [isAutoExpensesInProgress, setIsAutoExpensesInProgress] = useState(false)
  const [autoExecutedFiles, setAutoExecutedFiles] = useState<AutoExecutedTransaction[]>([])
  const [expensesModal, setExpensesModal] = useState({
    open: false,
    file: {} as Transaction,
  })
  const { t } = useTranslation()
  const theme = useTheme()

  const cardNames = rawRows?.reduce((acc: string[], curr: Transaction) => {
    if (curr.cardNumber && !acc.includes(curr.cardNumber)) {
      acc.push(curr.cardNumber)
    }
    return acc
  }, [] as string[])

  const handleExpensesFileProcessor = async () => {
    setIsAutoExpensesInProgress(true)
    const remappedFiles = rawRows.map((row) => ({
      ...row,
      balanceSnapshotRef: selectedBalances?.[row.cardNumber as string],
    }))
    try {
      const response = await postExpensesFileProcessor({
        files: remappedFiles,
        periodRef: period._id.toString(),
      })
      setAutoExecutedFiles(response)
    } catch (error) {
      //
    } finally {
      setStep((prev) => prev + 1)
      setIsAutoExpensesInProgress(false)
    }
  }

  React.useEffect(() => {
    if (
      selectedBalances &&
      Object.keys(selectedBalances).length === cardNames?.length &&
      step === 1
    ) {
      handleExpensesFileProcessor()
    }
  }, [selectedBalances])

  React.useEffect(() => {
    if (!selectedBalances) return
    const remappedFiles = rawRows.map((row) => ({
      ...row,
      balanceSnapshotRef: selectedBalances?.[row.cardNumber as string],
    }))
    setRawRows(remappedFiles)
  }, [selectedBalances])

  const onFilesChanged = (files: Transaction[]) => {
    const filteredRows = filterRows({ files, expenses })
    setRawRows(filteredRows)
    setStep((prev) => prev + 1)
  }

  const handleSelectBalance = ({ card, balanceId }: { card: string; balanceId: string }) => {
    setSelectedBalances((prev) => ({
      ...prev,
      [card]: balanceId,
    }))
  }

  const handleDeleteRow = (uuid: string) => {
    setRawRows((prevRows) => prevRows?.filter((row) => row.uuid !== uuid))
  }

  const handleCloseExpensesModal = () => {
    setExpensesModal({
      file: {} as Transaction,
      open: false,
    })
  }

  const handleOpenExpensesModal = (file: Transaction) => {
    setExpensesModal({
      file,
      open: true,
    })
  }

  const onSuccessfulExecute = (uuid: string) => {
    handleDeleteRow(uuid)
    handleCloseExpensesModal()
  }

  return (
    <CustomModal
      sx={{
        [theme.breakpoints.up('md')]: {
          width: '90%',
        },
        [theme.breakpoints.up('lg')]: {
          width: '90%',
        },
        [theme.breakpoints.up('xl')]: {
          width: '75%',
        },
      }}
    >
      <UploadFromFileContext.Provider
        value={{
          handleDeleteRow,
          handleOpenExpensesModal,
          expensesModalFile: expensesModal.file,
          onSuccessfulExecute,
          selectedBalances,
        }}
      >
        <Stack flexDirection="column" justifyContent="space-between" height="100%">
          <DialogTitle>{t('translations:addExpensesFromFile.title')}</DialogTitle>
          <CardContent>
            {step > 0 && !rawRows.length && (
              <Stack width="100%" alignItems="center" justifyContent="center">
                <Typography align="center">
                  {step === 1
                    ? t('translations:addExpensesFromFile.noNewExpenses')
                    : t('translations:addExpensesFromFile.youAllDone')}
                </Typography>
              </Stack>
            )}
            {step === 0 && <Dropzone onFilesChanged={onFilesChanged} />}
            {step === 1 &&
              (isAutoExpensesInProgress ? (
                <CustomLoading />
              ) : (
                <BalanceLinking
                  rows={rawRows}
                  selectedBalances={selectedBalances}
                  onChange={handleSelectBalance}
                />
              ))}
            {step === 2 && rawRows.length > 0 && (
              <FileRows
                rawRows={rawRows}
                selectedBalances={selectedBalances}
                autoExecutedFiles={autoExecutedFiles}
              />
            )}
            {expensesModal.open && <FilesExpensesModal handleClose={handleCloseExpensesModal} />}
          </CardContent>
          <DialogActions>
            <CustomButton onClick={onClose}>{t('translations:common.close')}</CustomButton>
          </DialogActions>
        </Stack>
      </UploadFromFileContext.Provider>
    </CustomModal>
  )
}
