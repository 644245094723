import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IHousehold, IUserPopulated } from '../../../shared-resources/INTERFACES'
import { getInitialData } from '../utils/apiUtils'
import { LOCAL_STORAGE_KEYS } from '../utils/constants'

export interface IInitialState {
  user: IUserPopulated | null
  loading: boolean
  currentHousehold: IHousehold | null
}

const initialState: IInitialState = {
  user: null,
  loading: false,
  currentHousehold: null,
}

export const getInitialDataThunk = createAsyncThunk('initialData/getInitialDataThunk', async () => {
  const data = await getInitialData()
  return data
})

export const initialDataSlice = createSlice({
  name: 'initialData',
  initialState,
  reducers: {
    setHousehold: (state, action: PayloadAction<IHousehold>) => {
      state.currentHousehold = action.payload
    },
  },
  extraReducers: {
    [getInitialDataThunk.pending.toString()]: (state: IInitialState) => {
      state.loading = true
      state.currentHousehold = null
    },
    [getInitialDataThunk.rejected.toString()]: (state: IInitialState) => {
      state.loading = false
    },
    [getInitialDataThunk.fulfilled.toString()]: (
      state: IInitialState,
      action: PayloadAction<IUserPopulated>
    ) => {
      state.user = action.payload
      // check if there is current household id in local storage
      const currentHouseholdId = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_HOUSEHOLD_ID)
      // check if there is current household id by local storage
      const householdByLocalStorage = action.payload?.householdsRef?.find(
        (household) => household?._id === currentHouseholdId
      )
      state.currentHousehold = householdByLocalStorage || action.payload?.householdsRef?.[0]
      // if not, set it to the current household id and save it to local storage
      if (!householdByLocalStorage && action.payload?.householdsRef?.[0]) {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.CURRENT_HOUSEHOLD_ID,
          action.payload?.householdsRef?.[0]?._id as string
        )
      }
      // set loading to false
      state.loading = false
    },
  },
})
