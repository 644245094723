import { Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IExpense, IPopulatedExpense } from '../../../../../shared-resources/INTERFACES'
import { CustomCard } from '../../../components/CustomCard'
import { CustomCardContent } from '../../../components/CustomCardContent'
import { APP_ROUTES, MANAGEMENT_TABS } from '../../../utils/constants'
import { BaseExpense } from './BaseExpense'

interface IProps {
  expense: IPopulatedExpense
}

export default function Expense({ expense }: IProps): React.ReactElement {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.EXPENSES}/${expense._id}`)
  }

  return (
    <CustomCard
      onClick={handleClick}
      sx={{
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          cursor: 'pointer',
        },
      }}
    >
      <CustomCardContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="space-between">
            <BaseExpense expense={expense as unknown as IExpense} />
            <Typography>{expense?.balanceRef?.name}</Typography>
            <Typography>
              {t('translations:common.household')}: {expense?.householdRef?.name}
            </Typography>
          </Stack>
        </Stack>
      </CustomCardContent>
    </CustomCard>
  )
}
