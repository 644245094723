/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { BALANCE_TYPES } from '../../../../../shared-resources/CONSTANTS'
import { IBalance, IBalanceSnapshotPopulated } from '../../../../../shared-resources/INTERFACES'
import CustomDialog from '../../../components/CustomDialog'
import CustomIcon from '../../../components/CustomIcon'
import CustomInput from '../../../components/CustomInput'
import CustomLoading from '../../../components/CustomLoading'
import CustomSpacer from '../../../components/CustomSpacer'
import { CustomTag } from '../../../components/CustomTag'
import { useCurrency } from '../../../hooks/useCurrency'
import { useAppSelector } from '../../../hooks/useReactRedux'
import { deleteBalanceSnapshot, putBalanceSnapshot } from '../../../utils/apiUtils'
import { getDate } from '../../../utils/helpers'
import { BALANCE_TYPE_ICONS, COMMON_ICONS } from '../../../utils/icons'

export const getIconsByType = (type: BALANCE_TYPES) => {
  if (type in BALANCE_TYPES) {
    return BALANCE_TYPE_ICONS[type]
  }
}

type Props = Pick<
  IBalanceSnapshotPopulated & IBalance,
  'name' | 'amount' | 'updatedAt' | 'type' | 'color' | '_id' | 'realized'
> & {
  isCreditCard?: boolean
}

export default function BalanceItem(props: Props): React.ReactElement {
  const {
    updatedAt,
    amount,
    type,
    name,
    color,
    _id: balanceSnapshotId,
    realized,
    isCreditCard,
    ...rest
  } = props
  const icon = getIconsByType(type)
  const [isLoading, setIsLoading] = React.useState(false)
  const { expenses } = useAppSelector((state) => state.expenses)
  const [balanceAmount, setBalanceAmount] = React.useState(amount.toString())
  const { t } = useTranslation()
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false)
  // const [isDisabled, setIsDisabled] = React.useState(false)
  const [valueChanged, setValueChanged] = React.useState(false)
  const disableDelete = expenses.some(
    (e) => e.balanceSnapshotRef._id.toString() === balanceSnapshotId
  )
  const { formatAmount } = useCurrency()
  const totalBalanceAmount = expenses
    .filter((e) => e.balanceSnapshotRef._id.toString() === balanceSnapshotId)
    .reduce((acc, curr) => acc + curr.amount, 0)

  React.useEffect(() => {
    setBalanceAmount(amount.toString())
  }, [amount])

  const updateBalanceAmount = async (newAmount: string) => {
    setIsLoading(true)
    await putBalanceSnapshot({
      newAmount: Number(newAmount),
      balanceSnapshotId: balanceSnapshotId.toString(),
    })
    setIsLoading(false)
    // setIsDisabled(true)
  }

  const onRealizedChange = async () => {
    setIsLoading(true)
    await putBalanceSnapshot({
      newAmount: amount,
      balanceSnapshotId: balanceSnapshotId.toString(),
      realized: !realized,
    })
    setIsLoading(false)
  }

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setBalanceAmount(e.target.value ?? 0)
    setValueChanged(true)
  }

  const onClickDelete = () => {
    setDeleteConfirmation(true)
  }

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      await deleteBalanceSnapshot({
        balanceSnapshotId: balanceSnapshotId.toString(),
      })
      handleCloseDeleteConfirmation()
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation(false)
  }

  // const handleEnable = () => {
  //   setIsDisabled(false)
  // }

  const handleBlur = () => {
    updateBalanceAmount(balanceAmount)
    if (valueChanged) {
      setValueChanged(false)
      // setIsDisabled(true)
    }
  }

  return (
    <Grid
      container
      {...rest}
      alignItems="center"
      sx={{
        borderRadius: 1,
        p: 1,
      }}
    >
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon && (
            <>
              <CustomIcon icon={icon} />
              <CustomSpacer horizontal size="small" />
            </>
          )}
          <CustomTag backgroundColor={color}>{name}</CustomTag>
        </Box>
        <CustomSpacer horizontal size="small" />
        <Typography variant="caption" color="textSecondary" noWrap>
          {t('translations:common.updatedAt')} -{' '}
          {getDate({
            date: updatedAt,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column">
            {isCreditCard ? (
              <Typography>{formatAmount(totalBalanceAmount)}</Typography>
            ) : (
              <CustomInput
                sx={{ width: '100%' }}
                handleChange={handleChange}
                name="value"
                value={+balanceAmount}
                disabled={isLoading}
                // autoFocus={!isDisabled}
                onBlur={handleBlur}
              />
            )}
            {isLoading ? (
              <CustomLoading />
            ) : (
              <div
                style={{
                  padding: '2px 0',
                }}
              />
            )}
          </Stack>
          <Stack direction="row" alignItems="center">
            {(type === BALANCE_TYPES.INCOME || type === BALANCE_TYPES.CC) && (
              <IconButton
                onClick={onRealizedChange}
                disabled={isLoading}
                color={realized ? 'success' : 'default'}
                sx={{
                  width: 'fit-content',
                  height: 'fit-content',
                }}
              >
                <CustomIcon icon={COMMON_ICONS.REALIZE_INCOME} />
              </IconButton>
            )}
            <IconButton
              onClick={onClickDelete}
              disabled={disableDelete || isLoading}
              sx={{
                width: 'fit-content',
                height: 'fit-content',
              }}
            >
              <CustomIcon icon={COMMON_ICONS.DELETE} />
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
      {deleteConfirmation && (
        <CustomDialog
          onClose={handleCloseDeleteConfirmation}
          onConfirm={handleDelete}
          title={t('translations:common.delete')}
          description={t('translations:common.deleteGenericDesc')}
          isLoading={isLoading}
        />
      )}
    </Grid>
  )
}
