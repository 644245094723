import { TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { BALANCE_TYPES } from '../../../../../shared-resources/CONSTANTS'
import { IPopulatedBalance } from '../../../../../shared-resources/INTERFACES'
import CustomButton from '../../../components/CustomButton'
import { CustomCardContent } from '../../../components/CustomCardContent'
import CustomColorPicker from '../../../components/CustomColorPicker'
import { CustomGenericSelection } from '../../../components/CustomGenericSelection'
import CustomModal from '../../../components/CustomModal'
import CustomSpacer from '../../../components/CustomSpacer'
import { i18n } from '../../../i18n/i18n'
import { postBalance, putBalance } from '../../../utils/apiUtils'
import { COMPONENT_MODES } from '../../../utils/constants'
import { getComponentModeTitle } from '../../../utils/helpers'
import { BALANCE_TYPE_ICONS } from '../../../utils/icons'

interface AddEditBalanceProps {
  mode: COMPONENT_MODES
  balance?: IPopulatedBalance
  onClose: () => void
  onSuccess: () => void
}

const BALANCE_TYPES_OPTIONS = [
  {
    label: i18n.t('translations:balances.bankAccount'),
    value: BALANCE_TYPES.BANK,
    icon: BALANCE_TYPE_ICONS.BANK,
  },
  {
    label: i18n.t('translations:balances.cc'),
    value: BALANCE_TYPES.CC,
    icon: BALANCE_TYPE_ICONS.CC,
  },
  {
    label: i18n.t('translations:balances.income'),
    value: BALANCE_TYPES.INCOME,
    icon: BALANCE_TYPE_ICONS.INCOME,
  },
  {
    label: i18n.t('translations:balances.savings'),
    value: BALANCE_TYPES.SAVINGS,
    icon: BALANCE_TYPE_ICONS.SAVINGS,
  },
]

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('translations:errors.required')),
  type: Yup.string().required(i18n.t('translations:errors.required')),
})

export default function AddEditBalance(props: AddEditBalanceProps): React.ReactElement {
  const { mode, balance, onClose, onSuccess } = props
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      name: balance?.name || '',
      type: balance?.type || BALANCE_TYPES.BANK,
      color: balance?.color || '#00d181',
    } as IPopulatedBalance,
    validationSchema,
    onSubmit,
  })

  const handlePostBalance = async (newBalance: IPopulatedBalance) => {
    setLoading(true)
    try {
      await postBalance({
        name: newBalance.name,
        type: newBalance.type,
        color: newBalance.color,
      })
      // on success
      onSuccess?.()
    } catch (error) {
      //
    }
    setLoading(false)
  }

  const handlePutBalance = async (newBalance: IPopulatedBalance) => {
    if (!balance) return
    setLoading(true)
    try {
      await putBalance({ newBalance, balanceId: balance._id.toString() })
      // on success
      onSuccess?.()
    } catch (error) {
      //
    }
    setLoading(false)
  }

  function onSubmit() {
    if (mode === COMPONENT_MODES.ADD) {
      handlePostBalance(formik.values)
    }
    if (mode === COMPONENT_MODES.EDIT) {
      handlePutBalance(formik.values)
    }
  }

  function handleChangeByKey<T>({ key, value }: { key: string; value: T }) {
    formik.setFieldValue(key, value)
  }

  return (
    <CustomModal onClose={onClose}>
      <CustomCardContent>
        <Typography variant="h4">{getComponentModeTitle(mode)}</Typography>
        <CustomSpacer vertical size="medium" />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            size="small"
            fullWidth
            id="name"
            name="name"
            label={t('translations:common.description')}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <CustomSpacer vertical size="medium" />
          <CustomGenericSelection
            onChange={(value) => handleChangeByKey({ key: 'type', value })}
            selected={formik.values.type}
            options={BALANCE_TYPES_OPTIONS}
          />
          <CustomSpacer vertical size="medium" />
          <CustomColorPicker color={formik.values.color} onChangeComplete={handleChangeByKey} />
          <CustomSpacer vertical size="large" />
          <CustomButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            isLoading={loading}
          >
            {getComponentModeTitle(mode)}
          </CustomButton>
        </form>
      </CustomCardContent>
    </CustomModal>
  )
}
