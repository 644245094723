/* eslint-disable no-unused-vars */
import { TextField, TextFieldProps } from '@mui/material'
import * as React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { MainContext } from 'src/context/MainContext'
import { useAppSelector } from '../hooks/useReactRedux'
import { getCurrencySymbol } from '../utils/helpers'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormatProps, CustomProps>((props, inputRef) => {
  const { name, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
    />
  )
})

NumberFormatCustom.displayName = 'NumberFormatCustom'

type Props = {
  value?: number
  handleChange: (event: { target: { name: string; value: string } }) => void
  name: string
}
export default function CustomInput(props: Props & TextFieldProps) {
  const { localSettings } = React.useContext(MainContext)
  const { maskAmount } = localSettings
  const { name, value, handleChange, ...rest } = props
  const { currentHousehold } = useAppSelector((state) => state.initialData)
  const decimalFormattedValue = value?.toFixed?.(2)
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select()

  return (
    <TextField
      {...rest}
      type={maskAmount ? 'password' : 'text'}
      onFocus={handleFocus}
      size="small"
      value={decimalFormattedValue}
      onChange={handleChange}
      name={name}
      id="formatted-numberformat-input"
      InputProps={{
        inputComponent: NumberFormatCustom as never,
        endAdornment: getCurrencySymbol(currentHousehold ? currentHousehold.currency : 'usd'),
      }}
    />
  )
}
