import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../../../../shared-resources/BASE_ENDPOINTS'
import { NOTE_MODELS } from '../../../../../shared-resources/CONSTANTS'
import { calculateRemainingExpenseAmount } from '../../../../../shared-resources/FUNCTIONS'
import { IExpenseSnapshotPopulated, Transaction } from '../../../../../shared-resources/INTERFACES'
import CustomButton from '../../../components/CustomButton'
import CustomInput from '../../../components/CustomInput'
import CustomModal from '../../../components/CustomModal'
import CustomSpacer from '../../../components/CustomSpacer'
import { useCurrency } from '../../../hooks/useCurrency'
import {
  closeExpenseSnapshot,
  postExecutionUpdate,
  postNote,
  putExpenseBusinessName,
  putExpenseSnapshot,
} from '../../../utils/apiUtils'
import { SimilarAmountBox } from '../../AddEditExpenseSnapshot/SimilarAmountBox'

type Props = {
  expense: IExpenseSnapshotPopulated
  onClose: () => void
  onSuccessfulExecute?: () => void
  predefinedData?: {
    amount: number
    uuid?: string
    businessName?: string
  }
  transaction?: Transaction
}

export function PartialExecute({
  expense,
  onClose,
  onSuccessfulExecute,
  predefinedData,
  transaction,
}: Props): React.ReactElement {
  const { t } = useTranslation()
  const [amount, setAmount] = React.useState(predefinedData?.amount ?? 0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [noteContent, setNoteContent] = React.useState('')
  const [executeOnDone, setExecuteOnDone] = React.useState(false)
  const { remainingExpenseAmount } = calculateRemainingExpenseAmount(expense)
  const { formatAmount } = useCurrency()
  const newExpenseAmount = expense.amount + (amount - remainingExpenseAmount)
  const updateExpenseNeeded = amount > remainingExpenseAmount

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setIsLoading(true)
    const trimmedNote = noteContent.trim()
    try {
      const executionUpdatePayload = {
        amount: Number(amount),
        expenseSnapshotRef: expense._id.toString(),
        uuid: predefinedData?.uuid,
      }

      const newExecutionUpdate = await postExecutionUpdate({
        ...executionUpdatePayload,
        ...transaction,
      })

      if (trimmedNote && newExecutionUpdate) {
        await postNote({
          content: trimmedNote,
          modalName: NOTE_MODELS.EXECUTION_UPDATE,
          documentRef: newExecutionUpdate._id.toString(),
        })
      }

      // If amount if bigger than maxAmountToExecute, update expense
      if (updateExpenseNeeded) {
        await putExpenseSnapshot({
          expenseSnapshotId: expense._id.toString(),
          newExpenseSnapshot: {
            amount: newExpenseAmount,
          },
        })
      }

      // If executeOnDone is true, we need to update the expense
      if (executeOnDone) {
        await closeExpenseSnapshot(expense._id)
      }

      // If there is a business name, we need to update the expense
      if (predefinedData?.businessName && expense.expenseRef?._id) {
        await putExpenseBusinessName({
          expenseId: expense.expenseRef._id.toString(),
          action: ROUTES.ADD_BUSINESS_NAME,
          businessNames: predefinedData.businessName,
        })
      }
      onClose()
    } catch (resError) {
      //
    } finally {
      setIsLoading(false)
      onSuccessfulExecute?.()
    }
  }

  const handleChange = (event: { target: { name: string; value: string } }) => {
    setAmount(+event.target.value)
  }

  const handleChangeNoteContent = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNoteContent(event.target.value)
  }

  return (
    <CustomModal onClose={onClose}>
      <form
        style={{
          height: '100%',
        }}
        onSubmit={onSubmit}
      >
        <Stack flexDirection="column" justifyContent="space-between" height="100%" overflow="auto">
          <DialogTitle id="alert-dialog-title">
            {t('translations:expenses.actions.partialExecute')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('translations:expenses.actions.maxAmountToExecute')}:{' '}
              {formatAmount(remainingExpenseAmount)}
              {updateExpenseNeeded && (
                <>
                  <CustomSpacer vertical size="medium" />
                  <Typography
                    variant="subtitle2"
                    color="text.primary"
                    sx={{
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {t('translations:expenses.actions.partialExecuteAndUpdateExpense')}{' '}
                    {formatAmount(newExpenseAmount)}
                  </Typography>
                </>
              )}
            </DialogContentText>
            <SimilarAmountBox amount={amount} />
            <CustomInput
              sx={{ width: '100%' }}
              handleChange={handleChange}
              name="value"
              value={amount}
              autoFocus
            />
            <CustomSpacer vertical size="small" />
            <TextField
              fullWidth
              label={t('translations:note.content')}
              margin="normal"
              name="content"
              onChange={handleChangeNoteContent}
              value={noteContent}
              variant="outlined"
              rows={4}
            />
            <CustomSpacer vertical size="small" />
            <FormControlLabel
              disabled={isLoading}
              value={executeOnDone}
              control={<Switch checked={executeOnDone} />}
              label={t('translations:expenses.addEditExpense.executeOnDone')}
              name="executeOnDone"
              id="executeOnDone"
              onChange={(e, checked) => setExecuteOnDone(checked)}
            />
          </DialogContent>
          <CustomSpacer vertical size="large" />
          <DialogActions>
            <CustomButton onClick={onClose}>{t('translations:common.close')}</CustomButton>
            <CustomButton isLoading={isLoading} variant="contained" type="submit">
              {t('translations:common.agree')}
            </CustomButton>
          </DialogActions>
        </Stack>
      </form>
    </CustomModal>
  )
}
