import { Divider } from '@mui/material'
import { orderBy } from 'lodash'
import * as React from 'react'
import { BALANCE_TYPES } from '../../../../../shared-resources/CONSTANTS'
import { IBalanceSnapshotPopulated } from '../../../../../shared-resources/INTERFACES'
import BalanceItem from './BalanceItem'

type Props = {
  balances: IBalanceSnapshotPopulated[]
}

export default function Balances(props: Props): React.ReactElement {
  const { balances, ...rest } = props
  return (
    <div {...rest}>
      {orderBy(balances, 'balanceRef.type').map((b, index) => (
        <>
          <BalanceItem
            isCreditCard={b.balanceRef?.type === BALANCE_TYPES.CC}
            key={b._id.toString()}
            name={b.balanceRef?.name}
            type={b.balanceRef?.type}
            amount={b.amount}
            updatedAt={b?.updatedAt}
            color={b.balanceRef?.color}
            realized={b?.realized}
            _id={b?._id}
          />
          {index !== balances.length - 1 && <Divider sx={{ my: 0.5 }} />}
        </>
      ))}
    </div>
  )
}
