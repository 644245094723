/* eslint-disable jsx-a11y/anchor-is-valid */
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IExpense } from '../../../../../../shared-resources/INTERFACES'
import CustomActionMoreButton from '../../../../components/ActionMoreButton'
import CustomDialog from '../../../../components/CustomDialog'
import { i18n } from '../../../../i18n/i18n'
import { deleteBusinessName, excludeBusinessName } from '../../../../utils/apiUtils'
import { ACTION_ITEMS } from '../../../../utils/constants'

export const ACTIONS = [
  {
    label: i18n.t('translations:expenses.actions.excludeExpanse'),
    action: ACTION_ITEMS.EXCLUDE_BUSINESS_NAME,
  },
  { label: i18n.t('translations:expenses.actions.deleteExpanse'), action: ACTION_ITEMS.DELETE },
]

const initialModalState = {
  open: false,
  businessName: '',
  action: ACTION_ITEMS.DELETE,
}

interface Props {
  businessNames: IExpense['businessNames']
  expenseId: string
  onUpdate: () => void
}

export const ExpansesBusinessNames = (props: Props) => {
  const { businessNames, expenseId, onUpdate } = props
  const [modal, setModal] = React.useState(initialModalState)
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()

  const handleDelete = async (businessName: string) => {
    setLoading(true)
    try {
      await deleteBusinessName({ businessName, expenseId })
      handleCloseModal()
      onUpdate()
    } catch (error) {
      //
    }
    setLoading(false)
  }

  const handleExclude = async (businessName: string) => {
    setLoading(true)
    try {
      await excludeBusinessName({ businessName, expenseId })
      handleCloseModal()
      onUpdate()
    } catch (error) {
      //
    }
    setLoading(false)
  }

  const handleOpenModal = ({
    action,
    businessName,
  }: {
    action: ACTION_ITEMS
    businessName: string
  }) => {
    setModal({
      open: true,
      businessName,
      action,
    })
  }

  const handleCloseModal = () => {
    setModal(initialModalState)
  }

  return (
    <Stack direction="column" width="100%">
      {businessNames?.map((businessName) => (
        <Stack
          key={businessName}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography>{businessName}</Typography>
          <CustomActionMoreButton
            actions={ACTIONS}
            onClick={(action) =>
              handleOpenModal({
                action,
                businessName,
              })
            }
          />
        </Stack>
      ))}
      {modal.open && modal.action === ACTION_ITEMS.DELETE && (
        <CustomDialog
          isLoading={loading}
          onConfirm={() => handleDelete(modal.businessName)}
          onClose={handleCloseModal}
          title={t('translations:expenses.actions.deleteExpanse')}
          description={t('translations:expenses.actions.deleteExpanseDescription')}
        />
      )}
      {modal.open && modal.action === ACTION_ITEMS.EXCLUDE_BUSINESS_NAME && (
        <CustomDialog
          isLoading={loading}
          onConfirm={() => handleExclude(modal.businessName)}
          onClose={handleCloseModal}
          title={t('translations:expenses.actions.excludeExpanse')}
          description={t('translations:expenses.actions.excludeExpanseDescription')}
        />
      )}
    </Stack>
  )
}
