import { FileRow } from 'components/UploadFromFile/FileRow';
import { UploadFromFileContext } from 'components/UploadFromFile/UploadFromFileContext';
import { AutoExecutedFileTypes } from 'shared-resources/CONSTANTS';

export const Profile = () => (
  <div>
    <UploadFromFileContext.Provider
      value={{
        handleDeleteRow: () => {},
        handleOpenExpensesModal: () => {},
        expensesModalFile: {} as never,
        onSuccessfulExecute: () => {},
      }}
    >
      <FileRow
        isAutoExecutedFile
        rawRow={{
          amount: 12.2,
          businessName: 'Some name',
          cardNumber: '4286',
          category: 'Food',
          currency: '$',
          date: new Date().toISOString(),
          transactionType: 'Regular',
          expenseSnapshotRef: 'someRef',
          type: AutoExecutedFileTypes.EXECUTION_UPDATE,
          _id: 'someId',
          balanceSnapshotRef: 'someRef',
          uuid: 'someUuid',
        }}
      />
    </UploadFromFileContext.Provider>
  </div>
);
