import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Button, Checkbox, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React from 'react';
import { i18n } from '../i18n/i18n';
import { COMMON_ICONS } from '../utils/icons';
import CustomIcon from './CustomIcon';
import CustomSpacer from './CustomSpacer';

type Option<T> = {
  label: string;
  value: T;
  icon?: IconProp;
  disabled?: boolean;
};

type BaseProps<T> = {
  options: Option<T>[];
  placeholder?: string;
  enableDoubleClick?: boolean;
  fullWidth?: boolean;
  sortingOrder?: 'asc' | 'desc';
  multi?: boolean;
  disabled?: boolean;
};

type MultiProps<T> = BaseProps<T> & {
  multi: true;
  selected?: T[];
  onChange: (value: T[]) => void;
};

type SingleProps<T> = BaseProps<T> & {
  multi?: false;
  selected?: T;
  onChange: (value: T) => void;
};

type Props<T> = MultiProps<T> | SingleProps<T>;

type ItemProps = {
  icon?: IconProp;
  label: string;
};

const Item = ({ icon, label }: ItemProps) => (
  <Stack direction="row" alignItems="center">
    {icon && (
      <>
        <CustomIcon icon={icon} />
        <CustomSpacer horizontal size="small" />
      </>
    )}
    <Typography noWrap>{label}</Typography>
  </Stack>
);

export const CustomGenericSelection = <T,>(props: Props<T>): React.ReactElement => {
  const {
    options,
    selected,
    onChange,
    placeholder = i18n.t('translations:common.select'),
    enableDoubleClick,
    fullWidth,
    sortingOrder,
    multi,
    disabled,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const selectedOptions = options.filter(({ value }) => {
    if (Array.isArray(selected)) {
      return selected.includes(value);
    }
    return value === selected;
  });

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value: T, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (multi) {
      if (Array.isArray(selected)) {
        const newSelected = selected.includes(value)
          ? selected.filter((val) => val !== value)
          : [...selected, value];
        onChange(newSelected);
      } else {
        onChange([value]);
      }
    } else {
      onChange(value);
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        fullWidth={fullWidth}
        disabled={disabled}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          minWidth: 150,
        }}
      >
        {selectedOptions.length > 0 ? (
          <>
            {multi && (
              <Typography noWrap>
                {selectedOptions.map((option) => option.label).join(', ')}
              </Typography>
            )}
            {!multi && <Item label={selectedOptions[0].label} icon={selectedOptions[0].icon} />}
          </>
        ) : (
          placeholder
        )}
        <CustomSpacer horizontal size="medium" />
        <CustomIcon icon={faChevronDown} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map(({ value, label, icon, disabled: disabledItem }) => (
          <MenuItem
            key={label}
            onClick={(event) => handleChange(value, event)}
            selected={
              multi ? Array.isArray(selected) && selected.includes(value) : value === selected
            }
            disabled={(value === selected && !enableDoubleClick) || disabledItem}
          >
            {multi ? (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Checkbox checked={Array.isArray(selected) && selected.includes(value)} />
                <Item label={label} icon={icon} />
              </Stack>
            ) : (
              <>
                <Item label={label} icon={icon} />
                {sortingOrder && value === selected && (
                  <>
                    <CustomSpacer horizontal size="small" />
                    <CustomIcon
                      icon={
                        sortingOrder === 'asc' ? COMMON_ICONS.ARROW_DOWN : COMMON_ICONS.ARROW_UP
                      }
                    />
                  </>
                )}
              </>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
