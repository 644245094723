import { Stack } from '@mui/material';
import CustomTypography from 'components/CustomTypography';
import CustomButton from '../../components/CustomButton';

interface Props {
  header: string;
  buttonTitle?: string;
  handleClick?: () => void;
}

export const ManagementPageHeader = (props: Props) => {
  const { header, buttonTitle, handleClick } = props;
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      {header && <CustomTypography variant="h5">{header}</CustomTypography>}
      {handleClick && buttonTitle && (
        <CustomButton size="small" variant="contained" onClick={handleClick}>
          {buttonTitle}
        </CustomButton>
      )}
    </Stack>
  );
};
