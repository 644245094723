import { isNaN, isObject } from 'lodash'
import moment from 'moment'
import { COMMON_STATUES } from '../../../shared-resources/CONSTANTS'
import { IExpenseSnapshotPopulated } from '../../../shared-resources/INTERFACES'
import { i18n } from '../i18n/i18n'
import { COMPONENT_MODES } from './constants'
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * console log for development
 * @param  {any}
 */
export function dclg(...args: any) {
  if (process.env.NODE_ENV !== 'development') return
  args.forEach((arg: any) => {
    if (isObject(arg) && arg) {
      // eslint-disable-next-line no-console
      console.dir(arg)
    } else {
      // eslint-disable-next-line no-console
      console.log(arg)
    }
  })
}

type getDateType = {
  date: string | Date
  format?:
    | 'hh:mm a' // 12 hour format with am/pm
    | 'HH:mm' // 24 hour format
    | 'DD/MM/YYYY' // day, month, year
    | 'MM/DD/YYYY' // month, day, year
    | 'dddd, DD/MM' // day name, date
    | 'dddd, MM/DD' // day name, date
    | 'MMMM DD' // month name, date
    | 'MMMM' // month name
    | 'M' // month number
    | 'dddd' // day name
    | 'ddd' // day name
    | 'MM/YYYY' // month number, full year
    | 'MM/YY' // month number, short year
    | 'DD/MM' // date, month
    | 'MM/DD' // month number, date
    | 'YYYY/MM/DD' // full year, month number, date
    | 'MMMM YYYY' // month name, full year
    | 'DD/MM/YYYY, hh:mm' // full date and time without AM/PM
    | 'DD/MM/YYYY, HH:mm' // full date and time without AM/PM
    | 'M' // month number
}
export const getDate = ({ date, format = 'DD/MM/YYYY, HH:mm' }: getDateType): string =>
  moment(date).format(format)

export const getRandomNumber = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const currencySymbols = {
  usd: '$', // us dollar
  eur: '€', // euro
  crc: '₡', // costa rican colón
  gbp: '£', // british pound sterling
  ils: '₪', // israeli new sheqel
  inr: '₹', // indian rupee
  jpy: '¥', // japanese yen
  krw: '₩', // south korean won
  ngn: '₦', // nigerian naira
  php: '₱', // philippine peso
  pln: 'zł', // polish zloty
  pyg: '₲', // paraguayan guarani
  thb: '฿', // thai baht
  uah: '₴', // ukrainian hryvnia
  vnd: '₫', // vietnamese dong
}

type CurrencySymbolType = keyof typeof currencySymbols
export function getCurrencySymbol(key: CurrencySymbolType): string {
  return currencySymbols[key]
}

export function formatAmountWithCommas(amount?: number, toFixed = 2) {
  return amount
    ?.toFixed(toFixed)
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function delay(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

// helper function fot calculate the min amount to execute from expense
export const minAmountToExecuteFromExpense = (expense?: IExpenseSnapshotPopulated) => {
  const executedAmount = expense?.executionUpdatesRef?.reduce((acc, curr) => acc + curr.amount, 0)
  // eslint-disable-next-line no-unsafe-optional-chaining
  const temp = Number(expense?.amount) - Number(executedAmount)
  const minAmountToExecute = isNaN(temp) ? 0 : temp
  return { minAmountToExecute }
}

export const getComponentModeTitle = (mode: COMPONENT_MODES) => {
  switch (mode) {
    case COMPONENT_MODES.ADD:
      return i18n.t('translations:common.add')
    case COMPONENT_MODES.EDIT:
      return i18n.t('translations:common.edit')
    default:
      return ''
  }
}

export const getStatusName = (status: COMMON_STATUES) => {
  switch (status) {
    case COMMON_STATUES.ACTIVE:
      return i18n.t('translations:common.active')
    case COMMON_STATUES.DELETED:
      return i18n.t('translations:common.deleted')
    case COMMON_STATUES.INACTIVE:
      return i18n.t('translations:common.inactive')
    default:
      return ''
  }
}

export const isExpenseSnapshotFullyExecuted = (expense?: IExpenseSnapshotPopulated) => {
  const isFullyExecuted =
    expense?.amount === expense?.executionUpdatesRef.reduce((acc, curr) => acc + curr.amount, 0)

  return { isFullyExecuted }
}
