import { orderBy } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IPopulatedBalance } from '../../../../../shared-resources/INTERFACES'
import CustomDialog from '../../../components/CustomDialog'
import CustomLoadingGuard from '../../../components/CustomLoadingGuard'
import CustomSpacer from '../../../components/CustomSpacer'
import { deleteBalance, getBalancesByHouseholdId } from '../../../utils/apiUtils'
import { COMPONENT_MODES } from '../../../utils/constants'
import { ManagementPageHeader } from '../ManagementPageHeader'
import AddEditBalance from './AddEditBalance'
import Balance from './Balance'

export default function Balances(): React.ReactElement {
  const [balances, setBalances] = React.useState<IPopulatedBalance[]>([])
  const [loading, setLoading] = React.useState(true)
  const { t } = useTranslation()
  const [modal, setModal] = React.useState({
    open: false,
    mode: COMPONENT_MODES.EDIT,
    balance: undefined as IPopulatedBalance | undefined,
  })
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    balanceId: '',
  })

  React.useEffect(() => {
    fetchBalances({ isLoading: true })
  }, [])

  const fetchBalances = async ({ isLoading = true }: { id?: string; isLoading?: boolean }) => {
    setLoading(isLoading)
    try {
      const data = await getBalancesByHouseholdId()
      setBalances(data)
    } catch (error) {
      //
    }
    setLoading(false)
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteBalance(id)
      handleCloseConfirmation()
      fetchBalances({ isLoading: false })
    } catch (error) {
      //
    }
  }

  const handleCloseAddEdit = () => {
    setModal({ open: false, mode: COMPONENT_MODES.EDIT, balance: undefined })
  }

  const handleOpenAddEditModal = ({
    mode,
    balance,
  }: {
    mode: COMPONENT_MODES
    balance?: IPopulatedBalance
  }) => {
    setModal({ open: true, mode, balance })
  }

  const handleSuccess = () => {
    handleCloseAddEdit()
    fetchBalances({ isLoading: false })
  }

  const handleCloseConfirmation = () => {
    setConfirmModal({ open: false, balanceId: '' })
  }

  const handleOpenConfirmation = (balanceId: string) => {
    setConfirmModal({ open: true, balanceId })
  }

  return (
    <div>
      <ManagementPageHeader
        header={t('translations:management.balances')}
        buttonTitle={t('translations:management.addBalance')}
        handleClick={() => handleOpenAddEditModal({ mode: COMPONENT_MODES.ADD })}
      />
      <CustomSpacer vertical size="medium" />
      <CustomLoadingGuard loading={loading}>
        {orderBy(balances, 'createdAt', 'desc').map((balance) => (
          <div key={balance._id.toString()}>
            <Balance
              balance={balance}
              onDelete={handleOpenConfirmation}
              onEdit={handleOpenAddEditModal}
            />
            <CustomSpacer vertical size="medium" />
          </div>
        ))}
      </CustomLoadingGuard>
      {modal.open && (
        <AddEditBalance
          mode={modal.mode}
          balance={modal?.balance}
          onClose={handleCloseAddEdit}
          onSuccess={handleSuccess}
        />
      )}
      {confirmModal.open && (
        <CustomDialog
          title={t('translations:common.delete')}
          description={t('translations:common.deleteGenericDesc')}
          onClose={handleCloseConfirmation}
          onConfirm={() => handleDelete(confirmModal.balanceId)}
        />
      )}
    </div>
  )
}
