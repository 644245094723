import { Card, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { CustomStickyBox } from 'components/CustomStickyBox';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_BAR_HEIGHT } from 'utils/constants';
import { CustomGenericSelection } from '../../../components/CustomGenericSelection';
import CustomIcon from '../../../components/CustomIcon';
import { COMMON_ICONS } from '../../../utils/icons';

interface Props {
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onResetSearch: () => void;
  handleSortingChange?: (value: string) => void;
  sorting?: string;
  search: string;
  sortingOrder?: 'desc' | 'asc';
  sortingOptions?: {
    label: string;
    value: string;
  }[];
  displayCard?: boolean;
  isSticky?: boolean;
  offsetTop?: number;
}

const Wrapper = ({
  displayCard,
  children,
}: {
  displayCard?: boolean;
  children: React.ReactNode;
}) => {
  if (displayCard) {
    return (
      <Card
        sx={{
          p: 1,
          bgcolor: (theme) => theme.palette.background.default,
        }}
      >
        {children}
      </Card>
    );
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {children}
    </div>
  );
};

export const StickyWrapper = ({
  children,
  isSticky,
  offsetTop,
}: {
  children: React.ReactNode;
  isSticky: boolean;
  offsetTop?: number;
}) => {
  const { zIndex } = useTheme();
  if (isSticky) {
    return (
      <CustomStickyBox
        offsetTop={offsetTop}
        style={{
          width: '100%',
          zIndex: zIndex.fab - 1,
        }}
      >
        {children}
      </CustomStickyBox>
    );
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {children}
    </div>
  );
};

export function SearchBar(props: Props) {
  const {
    displayCard = true,
    sortingOptions,
    sorting,
    handleSortingChange,
    onResetSearch,
    sortingOrder,
    search,
    onSearch,
    isSticky = true,
    offsetTop,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const displaySorting = sortingOptions && sorting && handleSortingChange;

  useEffect(() => {
    if (search) {
      const searchInput = document.getElementById('search-bar');
      if (searchInput) {
        searchInput.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }
    }
  }, [search]);

  return (
    <StickyWrapper isSticky={isSticky} offsetTop={offsetTop ?? APP_BAR_HEIGHT + 4}>
      <Wrapper displayCard={displayCard}>
        <Stack
          spacing={isMobile ? 1 : 2}
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          id="search-bar"
        >
          {displaySorting && (
            <CustomGenericSelection
              placeholder={t('translations:period.sortBy')}
              options={sortingOptions}
              selected={sorting}
              onChange={handleSortingChange}
              enableDoubleClick
              sortingOrder={sortingOrder}
            />
          )}
          <TextField
            size="small"
            fullWidth
            id="search"
            name="search"
            label={t('translations:common.search')}
            value={search}
            onChange={onSearch}
            // adding button to end of input
            InputProps={{
              endAdornment: (
                <IconButton onClick={onResetSearch} disabled={!search}>
                  <CustomIcon icon={COMMON_ICONS.CLEAR} />
                </IconButton>
              ),
            }}
          />
        </Stack>
      </Wrapper>
    </StickyWrapper>
  );
}
