import { Collapse, List } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ItemRoute } from '../../utils/inAppRoutes'
import { NavItem } from './NavItem'

interface Props {
  onClick: (path: string) => void
  routes: ItemRoute[]
  name: string
  icon?: ItemRoute['icon']
}

export const NavGroupedItem = (props: Props): React.ReactElement => {
  const { onClick, routes, name, icon } = props
  const { pathname } = useLocation()
  const childSelected = routes.some((route) => pathname.includes(route.path))
  const [open, setOpen] = useState(childSelected)

  useEffect(() => {
    setOpen(childSelected)
  }, [childSelected])

  return (
    <>
      <NavItem
        onClick={() => setOpen(!open)}
        name={name}
        icon={icon}
        isGroupHeader
        open={open}
        routes={routes}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          {routes.map((route) => (
            <NavItem
              key={route.path}
              path={route.path}
              icon={route.icon}
              name={route.name}
              onClick={() => onClick(route.path)}
              isChild
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}
