import { Badge } from '@mui/material'
import * as React from 'react'

interface Props extends React.ComponentProps<typeof Badge> {
  children: React.ReactNode
}

export function CustomBadge(props: Props): React.ReactElement {
  const { children } = props

  return <Badge {...props}>{children}</Badge>
}
