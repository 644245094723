import { orderBy } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IHousehold } from '../../../../../shared-resources/INTERFACES'
import CustomDialog from '../../../components/CustomDialog'
import CustomLoadingGuard from '../../../components/CustomLoadingGuard'
import CustomSpacer from '../../../components/CustomSpacer'
import { useAppDispatch, useAppSelector } from '../../../hooks/useReactRedux'
import { initialDataSlice } from '../../../slices/initialDataSlice'
import { deleteHousehold, getHouseholds } from '../../../utils/apiUtils'
import { COMPONENT_MODES, LOCAL_STORAGE_KEYS } from '../../../utils/constants'
import { ManagementPageHeader } from '../ManagementPageHeader'
import AddEditHousehold from './AddEditHousehold'
import Household from './Household'

export default function Households(): React.ReactElement {
  const [households, setHouseholds] = React.useState<IHousehold[]>([])
  const [loading, setLoading] = React.useState(true)
  const { user } = useAppSelector((state) => state.initialData)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [modal, setModal] = React.useState({
    open: false,
    mode: COMPONENT_MODES.EDIT,
    household: undefined as IHousehold | undefined,
  })
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    householdId: '',
  })

  React.useEffect(() => {
    getData()
  }, [user])

  const getData = async (showLoading = true) => {
    if (!user?._id) return
    setLoading(showLoading)
    try {
      const res = await getHouseholds()
      setHouseholds(res)
    } catch (error) {
      //
    }
    setLoading(false)
  }

  const handleDelete = async () => {
    handleCloseConfirmation()
    try {
      await deleteHousehold(confirmModal.householdId)
    } catch (error) {
      //
    }
  }

  const handleCloseAddEditModal = () => {
    setModal({ open: false, mode: COMPONENT_MODES.EDIT, household: undefined })
  }

  const handleOpenAddEditModal = ({
    mode,
    household,
  }: {
    mode: COMPONENT_MODES
    household?: IHousehold
  }) => {
    setModal({ open: true, mode, household })
  }

  const onSuccess = () => {
    handleCloseAddEditModal()
  }

  const handleCloseConfirmation = () => {
    setConfirmModal({ open: false, householdId: '' })
  }

  const handleOpenConfirmation = (householdId: string) => {
    setConfirmModal({ open: true, householdId })
  }

  const handleSelectHousehold = (household: IHousehold) => {
    dispatch(initialDataSlice.actions.setHousehold(household))
    // set to local storage
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_HOUSEHOLD_ID, household._id as string)
  }

  return (
    <div>
      <ManagementPageHeader
        header={t('translations:navigation.households')}
        buttonTitle={t('translations:management.addHousehold')}
        handleClick={() => handleOpenAddEditModal({ mode: COMPONENT_MODES.ADD })}
      />
      <CustomSpacer vertical size="medium" />
      <CustomLoadingGuard loading={loading}>
        {orderBy(households, 'createdAt', 'desc').map((household) => (
          <div key={household._id.toString()}>
            <Household
              household={household}
              onDelete={handleOpenConfirmation}
              onEdit={handleOpenAddEditModal}
              onSelect={handleSelectHousehold}
            />
            <CustomSpacer vertical size="medium" />
          </div>
        ))}
        {modal.open && (
          <AddEditHousehold
            mode={modal.mode}
            household={modal?.household}
            onClose={handleCloseAddEditModal}
            onSuccess={onSuccess}
          />
        )}
        {confirmModal.open && (
          <CustomDialog
            title={t('translations:common.delete')}
            description={t('translations:common.deleteGenericDesc')}
            onClose={handleCloseConfirmation}
            onConfirm={handleDelete}
          />
        )}
      </CustomLoadingGuard>
    </div>
  )
}
