import { Badge } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IExpenseSnapshotPopulated } from '../../../shared-resources/INTERFACES'
import { isExpenseSnapshotFullyExecuted } from '../utils/helpers'

type Props = {
  expense: IExpenseSnapshotPopulated
  children: React.ReactNode
}
export default function ExecuteBadge(props: Props): React.ReactElement {
  const { expense, children } = props
  const { t } = useTranslation()
  const { isFullyExecuted } = isExpenseSnapshotFullyExecuted(expense)
  const tomorrow = new Date().setDate(new Date().getDate() + 1)
  const isDue = new Date(expense.duoDate).getTime() < tomorrow

  return (
    <Badge
      color="primary"
      badgeContent={t('translations:common.upcomingExecute')}
      invisible={!expense.duoDate || isFullyExecuted || !isDue}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </Badge>
  )
}
