import { useSearchQueryParam } from 'hooks/useSearchQueryParam';
import { orderBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from 'views/Period/Expanses/SearchBar';
import { IPopulatedNote } from '../../../../../shared-resources/INTERFACES';
import CustomLoadingGuard from '../../../components/CustomLoadingGuard';
import CustomSpacer from '../../../components/CustomSpacer';
import { useDebounce } from '../../../hooks/useDebounce';
import { useAppSelector } from '../../../hooks/useReactRedux';
import { getNotes } from '../../../utils/apiUtils';
import { ManagementPageHeader } from '../ManagementPageHeader';
import NoteItem from './NoteItem';

export default function Notes(): React.ReactElement {
  const [notes, setNotes] = React.useState<IPopulatedNote[]>([]);
  const [loading, setLoading] = React.useState(true);
  const { handleSearchChange, search, handleResetSearch } = useSearchQueryParam({
    queryKey: 's',
  });
  const debounceSearch = useDebounce(search, 500);
  const { currentHousehold } = useAppSelector((state) => state.initialData);
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchNotes();
  }, [currentHousehold]);

  const fetchNotes = async () => {
    setLoading(true);
    try {
      const data = await getNotes();
      setNotes(data);
    } catch (error) {
      //
    }
    setLoading(false);
  };

  return (
    <div>
      <ManagementPageHeader header={t('translations:navigation.notes')} />
      <CustomSpacer vertical size="medium" />
      <SearchBar search={search} onSearch={handleSearchChange} onResetSearch={handleResetSearch} />
      <CustomSpacer vertical size="medium" />
      <CustomLoadingGuard loading={loading}>
        {orderBy(
          matchSorter(notes, debounceSearch, {
            keys: ['content'],
          }),
          'createdAt',
          'desc'
        ).map((note) => (
          <>
            <NoteItem note={note} key={note._id.toString()} />
            <CustomSpacer vertical size="medium" />
          </>
        ))}
      </CustomLoadingGuard>
    </div>
  );
}
