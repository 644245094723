import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mui/material'
import * as React from 'react'

interface Props extends FontAwesomeIconProps {
  tooltipTitle?: string
}
export default function CustomIcon(props: Props): React.ReactElement {
  const { size = 'xs', tooltipTitle, ...rest } = props

  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <div>
        <FontAwesomeIcon {...rest} size={size} />
      </div>
    </Tooltip>
  ) : (
    <FontAwesomeIcon {...rest} size={size} />
  )
}
