import { Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { COMMON_STATUES } from '../../../../../shared-resources/CONSTANTS'
import { IBalance, IPopulatedBalance } from '../../../../../shared-resources/INTERFACES'
import CustomButton from '../../../components/CustomButton'
import { CustomCard } from '../../../components/CustomCard'
import { CustomCardContent } from '../../../components/CustomCardContent'
import { COMPONENT_MODES } from '../../../utils/constants'
import { BaseBalance } from './BaseBalance'

interface IProps {
  balance: IPopulatedBalance
  onEdit: ({ mode, balance }: { mode: COMPONENT_MODES; balance: IPopulatedBalance }) => void
  onDelete: (id: string) => void
}

export default function Balance({ balance, onDelete, onEdit }: IProps): React.ReactElement {
  const { t } = useTranslation()
  const isInactive = balance.status === COMMON_STATUES.INACTIVE

  return (
    <CustomCard
      sx={{
        borderLeft: `5px solid ${balance.color}`,
      }}
    >
      <CustomCardContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="space-between">
            <BaseBalance balance={balance as unknown as IBalance} />
            <Typography>
              {t('translations:common.household')}: {balance.householdRef?.name}
            </Typography>
          </Stack>
          <Stack direction="column">
            <CustomButton disabled={isInactive} onClick={() => onDelete(balance._id.toString())}>
              {t('translations:common.delete')}
            </CustomButton>
            <CustomButton
              disabled={isInactive}
              onClick={() =>
                onEdit({
                  mode: COMPONENT_MODES.EDIT,
                  balance,
                })
              }
            >
              {t('translations:common.edit')}
            </CustomButton>
          </Stack>
        </Stack>
      </CustomCardContent>
    </CustomCard>
  )
}
