import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IExpenseSnapshotPopulated } from '../../../shared-resources/INTERFACES';
import { getExpensesSnapshotByPeriod } from '../utils/apiUtils';

export interface IInitialState {
  loading: boolean;
  expenses: IExpenseSnapshotPopulated[];
}

const initialState: IInitialState = {
  loading: false,
  expenses: [],
};

export const getExpensesThunk = createAsyncThunk(
  'expensesSlice/getExpensesThunk',
  async (periodId: string) => {
    const data = await getExpensesSnapshotByPeriod(periodId);
    return data || [];
  }
);

export const getExpensesThunkSilently = createAsyncThunk(
  'expensesSlice/getExpensesSnapshotByPeriod',
  async (periodId: string) => {
    const data = await getExpensesSnapshotByPeriod(periodId);
    return data || [];
  }
);

export const expensesSlice = createSlice({
  name: 'expensesSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getExpensesThunk.pending.toString()]: (state: IInitialState) => {
      state.loading = true;
    },
    [getExpensesThunk.rejected.toString()]: (state: IInitialState) => {
      state.loading = false;
    },
    [getExpensesThunk.fulfilled.toString()]: (
      state: IInitialState,
      action: PayloadAction<IExpenseSnapshotPopulated[]>
    ) => {
      state.expenses = action.payload;
      state.loading = false;
    },
    [getExpensesThunkSilently.rejected.toString()]: (state: IInitialState) => {
      state.loading = false;
      state.expenses = [];
    },
    [getExpensesThunkSilently.fulfilled.toString()]: (
      state: IInitialState,
      action: PayloadAction<IExpenseSnapshotPopulated[]>
    ) => {
      if (action.payload.length === 0) {
        state.expenses = [];
        state.loading = false;
        return;
      }
      state.expenses = action.payload;
      state.loading = false;
    },
    [getExpensesThunkSilently.rejected.toString()]: (state: IInitialState) => {
      state.loading = false;
      state.expenses = [];
    },
  },
});
