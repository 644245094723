import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBalanceSnapshotPopulated } from '../../../shared-resources/INTERFACES';
import { getBalancesSnapshotByPeriod } from '../utils/apiUtils';

export interface IInitialState {
  loading: boolean;
  balances: IBalanceSnapshotPopulated[];
}

const initialState: IInitialState = {
  loading: false,
  balances: [] as IBalanceSnapshotPopulated[],
};

export const getBalancesThunk = createAsyncThunk(
  'balancesSlice/getBalancesThunk',
  async (periodId: string) => {
    const data = await getBalancesSnapshotByPeriod(periodId);
    return data;
  }
);
export const getBalancesThunkSilently = createAsyncThunk(
  'balancesSlice/getBalancesThunkSilently',
  async (periodId: string) => {
    const data = await getBalancesSnapshotByPeriod(periodId);
    return data;
  }
);

export const balancesSlice = createSlice({
  name: 'balancesSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getBalancesThunk.pending.toString()]: (state: IInitialState) => {
      state.loading = true;
    },
    [getBalancesThunk.rejected.toString()]: (state: IInitialState) => {
      state.loading = false;
      state.balances = [];
    },
    [getBalancesThunk.fulfilled.toString()]: (
      state: IInitialState,
      action: PayloadAction<IBalanceSnapshotPopulated[]>
    ) => {
      if (action.payload.length === 0) {
        state.balances = [];
        state.loading = false;
        return;
      }
      state.balances = action.payload;
      state.loading = false;
    },
    [getBalancesThunkSilently.fulfilled.toString()]: (
      state: IInitialState,
      action: PayloadAction<IBalanceSnapshotPopulated[]>
    ) => {
      if (action.payload.length === 0) {
        state.balances = [];
        state.loading = false;
        return;
      }
      state.balances = action.payload;
      state.loading = false;
    },
    [getBalancesThunkSilently.rejected.toString()]: (state: IInitialState) => {
      state.loading = false;
      state.balances = [];
    },
  },
});
