import { List, ListSubheader, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AutoExecutedTransaction, Transaction } from '../../../../shared-resources/INTERFACES';
import { useAppSelector } from '../../hooks/useReactRedux';
import { MappedAutoExecutedFile } from '../../types/mappedAutoExecutedFiles';
import { CustomCard } from '../CustomCard';
import { FileRow } from './FileRow';
import { findKeyByValue } from './helpers';

interface Props {
  rawRows?: Transaction[];
  selectedBalances?: Record<string, string>;
  autoExecutedFiles: AutoExecutedTransaction[];
}
export const FileRows = (props: Props) => {
  const { rawRows, selectedBalances, autoExecutedFiles } = props;
  const { balances } = useAppSelector((state) => state.balances);
  const selectedBalanceValues = selectedBalances && Object.values(selectedBalances);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mappedAutoExecutedFiles: MappedAutoExecutedFile[] = autoExecutedFiles?.map((element) => {
    const row = rawRows?.find((rawRow) => rawRow.uuid === element.uuid);
    return {
      ...row,
      type: element.type,
      expenseSnapshotRef: element.expenseRef,
    };
  });
  const autoExecutedFileIds = autoExecutedFiles.map((autoExecutedFile) => autoExecutedFile.uuid);

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: isMobile ? '90svh' : 300,
        '& ul': { padding: 0 },
        p: 1,
      }}
      subheader={<li />}
    >
      {mappedAutoExecutedFiles?.map((autoExecutedFile) => (
        <FileRow key={autoExecutedFile?.uuid} rawRow={autoExecutedFile} isAutoExecutedFile />
      ))}
      {selectedBalanceValues?.map((selectedBalance) => {
        const balanceRef = balances.find((balance) => balance._id === selectedBalance)?.balanceRef;
        const balanceName = balanceRef?.name;
        const balanceColor = balanceRef?.color;
        const cardNameFromBalance = findKeyByValue(selectedBalance, selectedBalances);
        const cardNameRows = rawRows?.filter(
          (row) =>
            row.cardNumber === cardNameFromBalance &&
            row.uuid &&
            !autoExecutedFileIds.includes(row.uuid)
        ) as MappedAutoExecutedFile[];

        return (
          <li key={`section-${selectedBalance}`}>
            <ul>
              <ListSubheader
                sx={{
                  p: 0,
                }}
              >
                <CustomCard
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <Typography
                    sx={{
                      p: 2,
                    }}
                  >
                    <b
                      style={{
                        backgroundColor: balanceColor,
                        color: theme.palette.getContrastText(balanceColor ?? ''),
                        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
                        borderRadius: 5,
                      }}
                    >
                      {balanceName}
                    </b>{' '}
                    - ({cardNameRows?.length})
                  </Typography>
                </CustomCard>
              </ListSubheader>
              {cardNameRows?.map((item, index) => (
                <FileRow key={index} rawRow={item} />
              ))}
            </ul>
          </li>
        );
      })}
    </List>
  );
};
