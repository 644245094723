import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPeriod } from '../../../shared-resources/INTERFACES';
import { getPeriodById } from '../utils/apiUtils';

export type IPeriodSlice = Pick<
  IPeriod,
  '_id' | 'createdAt' | 'endDate' | 'startDate' | 'householdRef'
>;

export interface IInitialState {
  loading: boolean;
  period: IPeriodSlice;
}

const initialState: IInitialState = {
  loading: false,
  period: {
    _id: '',
    createdAt: '',
    endDate: '',
    startDate: '',
    householdRef: '',
  },
};

export const getCurrentPeriodThunk = createAsyncThunk(
  'currentPeriodSlice/getCurrentPeriodThunk',
  async (periodId: string) => {
    const data = await getPeriodById(periodId);
    return data;
  }
);

export const currentPeriodSlice = createSlice({
  name: 'currentPeriodSlice',
  initialState,
  reducers: {
    resetCurrentPeriod: (state: IInitialState) => {
      state.period = initialState.period;
    },
  },
  extraReducers: {
    [getCurrentPeriodThunk.pending.toString()]: (state: IInitialState) => {
      state.loading = true;
    },
    [getCurrentPeriodThunk.rejected.toString()]: (state: IInitialState) => {
      state.loading = false;
      state.period = initialState.period;
    },
    [getCurrentPeriodThunk.fulfilled.toString()]: (
      state: IInitialState,
      action: PayloadAction<IPeriodSlice>
    ) => {
      if (!action.payload._id) {
        state.period = initialState.period;
        state.loading = false;
        return;
      }
      state.period = action.payload;
      state.loading = false;
    },
  },
});
