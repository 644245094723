import { ThemeOptions, colors, createTheme, darken } from '@mui/material';
import { merge } from 'lodash';

const primary = '#BB86FC';
const secondary = '#03DAC6';

const darkPalette: ThemeOptions['palette'] = {
  mode: 'dark',
  primary: {
    main: primary,
  },
  secondary: {
    main: secondary,
  },
  background: {
    default: colors.grey[900], // Dark Grey
    paper: colors.grey[800], // Even Darker Grey
  },
};

const lightPalette: ThemeOptions['palette'] = {
  mode: 'light',
  primary: {
    main: darken(primary, 0.2),
  },
  secondary: {
    main: darken(secondary, 0.2),
  },
  background: {
    default: colors.grey[100], // Light Grey
    paper: colors.grey[300], // Even Lighter Grey
  },
};

const baseTheme: ThemeOptions = {
  typography: {
    fontFamily: 'Rubik',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none', // makes the button text lowercase
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(2px)',
        },
      },
    },
  },
};

export const darkMuiTheme = createTheme({ ...baseTheme, palette: darkPalette });
export const lightMuiTheme = createTheme({ ...baseTheme, palette: lightPalette });

export const retrieveTheme = (mode: 'light' | 'dark') => {
  const theme = createTheme(merge({}, baseTheme, mode === 'light' ? lightMuiTheme : darkMuiTheme));
  return { theme };
};
