import { Chip, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IHousehold } from '../../../../../shared-resources/INTERFACES'
import CustomButton from '../../../components/CustomButton'
import { CustomCard } from '../../../components/CustomCard'
import { CustomCardContent } from '../../../components/CustomCardContent'
import CustomSpacer from '../../../components/CustomSpacer'
import { useAppSelector } from '../../../hooks/useReactRedux'
import { COMPONENT_MODES } from '../../../utils/constants'
import { getCurrencySymbol } from '../../../utils/helpers'
import { STATUS_LABELS } from '../../../utils/labels'

interface IProps {
  household: IHousehold
  onEdit: ({ mode, household }: { mode: COMPONENT_MODES; household: IHousehold }) => void
  onDelete: (id: string) => void
  onSelect: (household: IHousehold) => void
}

export default function Household({
  household,
  onDelete,
  onEdit,
  onSelect,
}: IProps): React.ReactElement {
  const { t } = useTranslation()
  const { currentHousehold } = useAppSelector((state) => state.initialData)

  return (
    <CustomCard>
      <CustomCardContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="space-between">
            <Stack direction="row" justifyContent="space-between">
              <Typography>{household.name}</Typography>
              <CustomSpacer horizontal size="small" />
              {household?.status && (
                <Chip label={STATUS_LABELS?.[household?.status]} size="small" color="success" />
              )}
            </Stack>
            <Typography>{getCurrencySymbol(household.currency)}</Typography>
          </Stack>
          <Stack direction="column">
            <CustomButton onClick={() => onDelete(household._id.toString())}>
              {t('translations:common.delete')}
            </CustomButton>
            <CustomButton
              onClick={() =>
                onEdit({
                  mode: COMPONENT_MODES.EDIT,
                  household,
                })
              }
            >
              {t('translations:common.edit')}
            </CustomButton>
            <CustomButton
              onClick={() => onSelect(household)}
              disabled={household._id === currentHousehold?._id}
            >
              {t('translations:management.selectHousehold')}
            </CustomButton>
          </Stack>
        </Stack>
      </CustomCardContent>
    </CustomCard>
  )
}
