import { Box } from '@mui/system'
import * as React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
  title?: string
  children: React.ReactNode
}
export default function Page(props: Props): React.ReactElement {
  const { title, children } = props
  return (
    <>
      <Helmet>
        <title>Fany{title ? ` - ${title}` : ''}</title>
      </Helmet>
      <Box
        sx={{
          p: { xs: 2, sm: 3 },
        }}
      >
        {children}
      </Box>
    </>
  )
}
