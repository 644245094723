import { FirebaseApp } from 'firebase/app'
import { Auth, User } from 'firebase/auth'
import React from 'react'
import { useFirebaseAuth } from '../hooks/useFirebaseAuth'

export type FirebaseState = {
  firebaseUser: User | null
  initializing: boolean
  firebaseApp: FirebaseApp | null
  firebaseAuth: Auth | null
}
export const fireBaseInitialState: FirebaseState = {
  firebaseUser: null,
  initializing: true,
  firebaseApp: null,
  firebaseAuth: null,
}
export const FirebaseContext = React.createContext(fireBaseInitialState)

type Props = {
  children: React.ReactNode
}

export function FirebaseProvider(props: Props): React.ReactElement {
  const { children } = props
  const { firebaseUser, initializing, firebaseApp, firebaseAuth } =
    useFirebaseAuth()
  const memoizedState = React.useMemo(
    () => ({ firebaseUser, initializing, firebaseApp, firebaseAuth }),
    [firebaseUser, initializing, firebaseApp, firebaseAuth]
  )

  return (
    <FirebaseContext.Provider value={memoizedState}>
      {children}
    </FirebaseContext.Provider>
  )
}
