import { CardHeader, Stack } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { IBalanceSnapshot } from '../../../../../shared-resources/INTERFACES'
import CustomButton from '../../../components/CustomButton'
import CustomInput from '../../../components/CustomInput'
import CustomModal from '../../../components/CustomModal'
import CustomSpacer from '../../../components/CustomSpacer'
import { useAppSelector } from '../../../hooks/useReactRedux'
import { i18n } from '../../../i18n/i18n'
import { postBalanceSnapshot } from '../../../utils/apiUtils'
import { COMPONENT_MODES } from '../../../utils/constants'
import { getComponentModeTitle } from '../../../utils/helpers'
import { BalanceSelection } from '../../Managmenet/Expenses/BalanceSelection'

type Props = {
  onClose: () => void
}

const validationSchema = Yup.object().shape({
  balanceRef: Yup.string().required(i18n.t('translations:errors.required')),
  periodRef: Yup.string().required(i18n.t('translations:errors.required')),
  amount: Yup.number().required(i18n.t('translations:errors.required')),
})

const MODE = COMPONENT_MODES.ADD
export function AddBalanceSnapshot(props: Props): React.ReactElement {
  const { onClose } = props
  const { period } = useAppSelector((state) => state.currentPeriod)
  const [loading, setLoading] = React.useState(false)
  const formik = useFormik({
    initialValues: {
      amount: 0,
      balanceRef: undefined,
      periodRef: period._id.toString(),
    } as unknown as IBalanceSnapshot,
    validationSchema,
    onSubmit,
  })

  const handlePostBalanceSnapshot = async (newBalanceSnapshot: IBalanceSnapshot) => {
    setLoading(true)
    try {
      await postBalanceSnapshot({
        ...newBalanceSnapshot,
        balanceRef: newBalanceSnapshot.balanceRef,
      })
      onClose()
    } catch (error) {
      setLoading(false)
    }
  }

  function onSubmit() {
    handlePostBalanceSnapshot(formik.values)
  }

  // eslint-disable-next-line prettier/prettier, comma-spacing
  const handleChangeByKey = <T,>({ key, value }: { key: string; value: T }) => {
    formik.setFieldValue(key, value)
  }

  const handleAmountChange = (event: { target: { name: string; value: string } }) => {
    const { name, value } = event.target
    formik.setFieldValue(name, value)
  }

  return (
    <CustomModal onClose={onClose}>
      <form
        style={{
          height: '100%',
        }}
        onSubmit={formik.handleSubmit}
      >
        <Stack flexDirection="column" justifyContent="space-between" height="100%" overflow="auto">
          <Stack flexDirection="column">
            <CardHeader title={getComponentModeTitle(COMPONENT_MODES.ADD)} />
            <BalanceSelection
              selectedBalance={formik.values?.balanceRef as string}
              onChange={(value) => handleChangeByKey({ key: 'balanceRef', value })}
              error={formik.touched.balanceRef && Boolean(formik.errors.balanceRef)}
              helperText={formik.touched.balanceRef && formik.errors.balanceRef?.toString()}
            />
            <CustomSpacer vertical size="medium" />
            <CustomInput
              value={formik.values.amount}
              handleChange={handleAmountChange}
              name="amount"
              fullWidth
            />
            <CustomSpacer vertical size="large" />
          </Stack>
          <CustomButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            isLoading={loading}
          >
            {getComponentModeTitle(MODE)}
          </CustomButton>
        </Stack>
      </form>
    </CustomModal>
  )
}
