import { Stack, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { IExpense } from '../../../../../shared-resources/INTERFACES'
import CustomIcon from '../../../components/CustomIcon'
import CustomSpacer from '../../../components/CustomSpacer'
import { getDate } from '../../../utils/helpers'
import { COMMON_ICONS, EXPENSE_TYPE_EMOJIS } from '../../../utils/icons'

interface Props {
  expense: IExpense
}

export function BaseExpense({ expense }: Props): React.ReactElement {
  const theme = useTheme()
  return (
    <Stack direction="column">
      <Typography variant="caption" color="GrayText">
        {getDate({ date: expense.createdAt })}
      </Typography>
      <CustomSpacer vertical size="small" />
      <Stack direction="row" alignItems="center">
        <Typography>{EXPENSE_TYPE_EMOJIS[expense.type]}</Typography>
        <CustomSpacer horizontal size="small" />
        {expense.isPeriodical && (
          <>
            <CustomIcon icon={COMMON_ICONS.PERIODICAL} color={theme.palette.success.light} />
            <CustomSpacer horizontal size="small" />
          </>
        )}
        <Typography>{expense.name}</Typography>
        <CustomSpacer horizontal size="small" />
      </Stack>
    </Stack>
  )
}
