/* eslint-disable no-nested-ternary */
import { Stack, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { AVERAGE_WIDGET_TYPES } from '../../../../../shared-resources/CONSTANTS';
import CustomIcon from '../../../components/CustomIcon';
import CustomSpacer from '../../../components/CustomSpacer';
import { useCurrency } from '../../../hooks/useCurrency';
import { COMMON_ICONS, EXPENSE_TYPE_EMOJIS } from '../../../utils/icons';
import { ExpenseTypeWithTitle } from '../../AddEditExpenseSnapshot/TypeSelection';
import { ItemProps } from './AverageGenericList';

interface Props {
  item: ItemProps;
  type: AVERAGE_WIDGET_TYPES;
  totalExpenses: number;
  totalIncomes: number;
}

export function AverageGenericListItem(props: Props): React.ReactElement {
  const { item, type, totalExpenses, totalIncomes } = props;
  const { formatAmount } = useCurrency();
  const theme = useTheme();
  const name = item.expenseRef?.name || item.balanceRef?.name || item.type;

  return (
    <Stack
      sx={{
        ml: 1,
        mr: 1,
        p: 1,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">
          {type === AVERAGE_WIDGET_TYPES.EXPENSE ? (
            <Stack direction="row" alignItems="center">
              {`${
                item.expenseRef?.type
                  ? EXPENSE_TYPE_EMOJIS?.[item.expenseRef?.type as keyof typeof EXPENSE_TYPE_EMOJIS]
                  : ''
              } ${name}`}
              {item.expenseRef?.isPeriodical && (
                <>
                  <CustomSpacer horizontal size="small" />
                  <CustomIcon icon={COMMON_ICONS.PERIODICAL} color={theme.palette.success.light} />
                </>
              )}
              <CustomSpacer horizontal size="small" />
            </Stack>
          ) : type === AVERAGE_WIDGET_TYPES.TYPE && item.type ? (
            <ExpenseTypeWithTitle type={item.type} />
          ) : (
            item.balanceRef?.name
          )}
        </Typography>
        <Stack direction="row" alignItems="center">
          <Typography variant="caption" color={theme.palette.error.main}>
            <b>{((item.amount / totalExpenses) * 100).toFixed(2)}%</b>
          </Typography>
          <Typography
            sx={{
              ml: 0.25,
              mr: 0.25,
            }}
            variant="caption"
          >
            /
          </Typography>
          <Typography variant="caption" color={theme.palette.success.main}>
            <b>{((item.amount / totalIncomes) * 100).toFixed(2)}%</b>
          </Typography>
          <CustomSpacer horizontal size="small" />
          <Typography variant="caption">-</Typography>
          <CustomSpacer horizontal size="small" />
          <Typography variant="subtitle2">{formatAmount(item.amount)}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
