import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PERIOD_ACTION_ICONS } from '../utils/icons'
import CustomIcon from './CustomIcon'

export const FileIcon = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <CustomIcon
      tooltipTitle={t('translations:tooltips.fileIcon')}
      color={theme.palette.grey[600]}
      icon={PERIOD_ACTION_ICONS.ADD_EXPENSE_FROM_FILE}
    />
  )
}
