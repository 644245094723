import { Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import PreserveLtr from 'components/PreserveLtr'
import * as React from 'react'
import { useCurrency } from '../../../hooks/useCurrency'

interface Props {
  title: string
  subTitle?: string
  value: number
  totalItems: number
}

export function AverageItem(props: Props): React.ReactElement {
  const { value, title, subTitle, totalItems } = props
  const { formatAmount } = useCurrency()
  const md = 12 / totalItems

  return (
    <Grid item xs={12} sm={12} md={md}>
      <Stack direction="column" alignItems="center" justifyContent="space-between">
        <Stack direction="column">
          <Typography variant="subtitle2" color="GrayText">
            {title}
          </Typography>
          <Typography variant="caption">{subTitle}</Typography>
        </Stack>
        <Typography variant="h6">
          <PreserveLtr>{formatAmount(value)}</PreserveLtr>
        </Typography>
      </Stack>
    </Grid>
  )
}
