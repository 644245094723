import { Grid, Stack, useTheme } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BALANCE_TYPES } from '../../../shared-resources/CONSTANTS';
import { IBalanceSnapshotPopulated } from '../../../shared-resources/INTERFACES';
import { useCurrency } from '../hooks/useCurrency';
import { useAppSelector } from '../hooks/useReactRedux';
import Balances from '../views/Period/PeriodSummary/Balances';
import { StreamChart } from './Charts/StreamChart';
import CustomButton from './CustomButton';
import CustomModal from './CustomModal';
import CustomSpacer from './CustomSpacer';
import { CustomTag } from './CustomTag';
import CustomTypography from './CustomTypography';

interface Props {
  balances: IBalanceSnapshotPopulated[];
  title: string;
  isCreditCard?: boolean;
  pastData?: number[];
}

const calculatePastMonthChange = (pastData: number[]) => {
  if (pastData.length < 2) {
    return 0;
  }

  const lastMonth = pastData[pastData.length - 2];
  const currentMonth = pastData[pastData.length - 1];
  const diff = currentMonth - lastMonth;

  return (diff / lastMonth) * 100;
};

export const BalancesAccordion = (props: Props): React.ReactElement => {
  const { title, balances, isCreditCard, pastData } = props;
  const { palette } = useTheme();
  const { formatAmount } = useCurrency();
  const { expenses } = useAppSelector((state) => state.expenses);
  const balanceIds = balances.map((b) => b._id.toString());
  const [openModal, setOpenModal] = React.useState(false);
  const lastMonthChange = calculatePastMonthChange(pastData || []);
  const { t } = useTranslation();

  const totalBalanceAmount = isCreditCard
    ? expenses.reduce((acc, curr) => {
        if (
          balanceIds.includes(curr.balanceSnapshotRef._id.toString()) &&
          curr.balanceSnapshotRef.balanceRef.type === BALANCE_TYPES.CC
        ) {
          return acc + curr.amount;
        }

        return acc;
      }, 0)
    : balances.reduce((acc, curr) => acc + curr.amount, 0);

  return (
    <>
      <CustomButton
        variant="contained"
        onClick={() => setOpenModal((prev) => !prev)}
        disabled={!balances.length}
        fullWidth
        sx={{
          backgroundColor: !balances.length ? palette.action.disabled : palette.background.default,
          height: '100%',
          // add hover effect
          '&:hover': {
            backgroundColor: palette.action.hover,
          },
        }}
      >
        <Grid container>
          <Grid item xs={7} sm={7}>
            <Stack
              direction="column"
              spacing={1}
              justifyContent="space-between"
              sx={{ width: '100%', height: '100%' }}
            >
              <Stack alignItems="center" direction="row">
                <CustomTypography color={palette.text.secondary}>{title}</CustomTypography>
                <CustomSpacer horizontal size="small" />
                <CustomTag>{balances.length}</CustomTag>
              </Stack>
              <Stack direction="column" alignItems="flex-start">
                {pastData && (
                  <CustomTypography variant="caption" color={palette.text.secondary}>
                    {t('translations:period.balancesAccordion.fromLastMonth')}{' '}
                    {Math.abs(lastMonthChange).toFixed(0)}%{' '}
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {lastMonthChange < 0 ? '-' : lastMonthChange > 0 ? '+' : ''}
                  </CustomTypography>
                )}
                <CustomTypography variant="h6">{formatAmount(totalBalanceAmount)}</CustomTypography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={5} sm={5}>
            <Stack
              direction="column"
              spacing={1}
              justifyContent="space-between"
              sx={{ width: '100%', height: '100%' }}
            >
              <div style={{ height: 80 }}>
                <StreamChart data={pastData} />
              </div>
            </Stack>
          </Grid>
        </Grid>
      </CustomButton>
      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <Balances balances={balances} />
      </CustomModal>
    </>
  );
};
