import { CardHeader, FormControlLabel, Switch, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { EXPANSE_TYPES } from '../../../../../shared-resources/CONSTANTS'
import { IPopulatedExpense } from '../../../../../shared-resources/INTERFACES'
import CustomButton from '../../../components/CustomButton'
import { CustomCardContent } from '../../../components/CustomCardContent'
import CustomModal from '../../../components/CustomModal'
import CustomSpacer from '../../../components/CustomSpacer'
import { i18n } from '../../../i18n/i18n'
import { postExpense, putExpense } from '../../../utils/apiUtils'
import { COMPONENT_MODES } from '../../../utils/constants'
import { getComponentModeTitle } from '../../../utils/helpers'
import { TypeSelection } from '../../AddEditExpenseSnapshot/TypeSelection'

type Props = {
  mode: COMPONENT_MODES
  expense?: IPopulatedExpense
  onClose: () => void
  onSuccess: () => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18n.t('translations:errors.tooShort'))
    .max(50, i18n.t('translations:errors.tooLong'))
    .required(i18n.t('translations:errors.required')),
})

export function AddEditExpense({ mode, expense, onClose, onSuccess }: Props): React.ReactElement {
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      name: expense?.name || '',
      isPeriodical: expense?.isPeriodical || false,
      type: expense?.type || EXPANSE_TYPES.OTHER,
    } as unknown as IPopulatedExpense,
    validationSchema,
    onSubmit,
  })

  const handlePostExpense = async (newExpense: IPopulatedExpense) => {
    setLoading(true)
    try {
      await postExpense({
        ...newExpense,
      })
      // on success
      onSuccess?.()
    } catch (error) {
      //
    }
    setLoading(false)
  }

  const handlePutExpense = async (newExpense: IPopulatedExpense) => {
    setLoading(true)
    try {
      await putExpense({
        newExpense: {
          ...newExpense,
        },
        expenseId: expense?._id?.toString() as string,
      })
      // on success
      onSuccess?.()
    } catch (error) {
      //
    }
    setLoading(false)
  }

  function onSubmit() {
    if (mode === COMPONENT_MODES.ADD) {
      handlePostExpense(formik.values)
    }
    if (mode === COMPONENT_MODES.EDIT) {
      handlePutExpense(formik.values)
    }
  }

  // eslint-disable-next-line comma-spacing
  const handleChangeByKey = <T,>({ key, value }: { key: string; value: T }) => {
    formik.setFieldValue(key, value)
  }

  return (
    <CustomModal onClose={onClose}>
      <CardHeader title={getComponentModeTitle(mode)} />
      <CustomCardContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            size="small"
            fullWidth
            id="name"
            name="name"
            label={t('translations:common.description')}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <CustomSpacer vertical size="medium" />
          <TypeSelection
            selectedType={formik.values.type}
            onChange={(value) => handleChangeByKey({ key: 'type', value })}
          />
          <CustomSpacer vertical size="large" />
          <FormControlLabel
            checked={formik.values.isPeriodical}
            value={formik.values.isPeriodical}
            control={<Switch />}
            label={t('translations:common.periodicalExpense')}
            onChange={formik.handleChange}
            name="isPeriodical"
            id="isPeriodical"
          />
          <CustomSpacer vertical size="large" />
          <CustomButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            isLoading={loading}
          >
            {getComponentModeTitle(mode)}
          </CustomButton>
        </form>
      </CustomCardContent>
    </CustomModal>
  )
}
