import { Typography, useTheme } from '@mui/material';
import * as React from 'react';

interface Props extends React.ComponentProps<typeof Typography> {
  searchValue?: string;
  children: React.ReactNode;
}

export default function CustomTypography(props: Props): React.ReactElement {
  const { searchValue, children, ...rest } = props;
  const [highlightedText, setHighlightedText] = React.useState<string[]>([]);
  const theme = useTheme();
  const color = rest.color ?? theme.palette.text.primary;

  // Scroll into view when there is a highlighted text
  React.useEffect(() => {
    if (searchValue && highlightedText.length > 3) {
      const element = document.getElementById('mark');
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [highlightedText]);

  React.useEffect(() => {
    if (searchValue) {
      const regex = new RegExp(searchValue.trim(), 'gi');
      const matches = children?.toString().match(regex);
      if (matches) {
        setHighlightedText(matches);
      } else {
        setHighlightedText([]);
      }
    }
  }, [searchValue]);

  if (searchValue && highlightedText.length > 0) {
    return (
      <Typography {...rest} id="mark" color={color}>
        {children
          ?.toString()
          .split(new RegExp(`(${highlightedText.join('|')})`, 'gi'))
          .map((part: string, index: number) =>
            highlightedText.includes(part) ? <mark key={index}>{part}</mark> : part
          )}
      </Typography>
    ) as React.ReactElement;
  }

  return (
    <Typography {...rest} color={color}>
      {children}
    </Typography>
  );
}
