import { useMediaQuery, useTheme } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { useCurrency } from '../../hooks/useCurrency';
import { ChartTooltip } from './ChartTooltip';

export interface LineChartProps {
  data: {
    id: string;
    data: {
      x: string;
      y: number;
    }[];
  }[];
}

const getXTickValues = (data: LineChartProps['data'], maxTickValues: number) => {
  const dataLength = data[0].data.length;
  let bottomTickValues;

  if (dataLength > maxTickValues) {
    const tickInterval = Math.floor(dataLength / maxTickValues);
    bottomTickValues = data[0].data
      .map((d, i) => (i % tickInterval === 0 ? d.x : ''))
      .filter((value) => value !== '');
  } else {
    bottomTickValues = data[0].data.map((d) => d.x);
  }

  return bottomTickValues;
};

export const LineChart = (props: LineChartProps): JSX.Element => {
  const { data } = props;
  const { formatAmount } = useCurrency();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Find the minimum y-value across all series in your dataset
  const minYValue = Math.min(...data.flatMap((series) => series.data.map((point) => point.y)));

  // Find the maximum y-value across all series in your dataset
  const maxYValue = Math.max(...data.flatMap((series) => series.data.map((point) => point.y)));

  const COLORS_SET = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.success.main,
  ];

  const defs = COLORS_SET.map((color, index) => ({
    id: `gradient${index}`,
    type: 'linearGradient',
    colors: [
      { offset: 0, color },
      { offset: 100, color, opacity: 0 },
    ],
  }));

  const maxTickValues = isMobile ? 4 : 8;
  const bottomTickValues = getXTickValues(data, maxTickValues);

  return (
    <ResponsiveLine
      // setting the font family to inherit from the theme
      data={data}
      theme={{
        fontFamily: theme.typography.fontFamily,
        textColor: theme.palette.text.primary,
        tooltip: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          zIndex: theme.zIndex.drawer + 1,
        },
      }}
      margin={{
        top: 50,
        // eslint-disable-next-line no-nested-ternary
        right: 10,
        bottom: 50,
        left: isMobile ? 25 : 80,
      }}
      // adding custom gradient the fade out the chart in the end
      colors={COLORS_SET}
      fill={COLORS_SET.map((_, index) => ({
        match: (series) => series.id === data[index].id,
        id: `gradient${index}`,
      }))}
      defs={defs}
      enableArea
      curve="cardinal"
      axisTop={null}
      axisRight={null}
      pointSize={3}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={3}
      enableGridX={false}
      enableGridY={false}
      axisLeft={
        isMobile
          ? null
          : {
              tickSize: 5,
              tickPadding: 60,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: 'end',
              format: (value) => formatAmount(Number(value), 0),
              tickValues: 10,
            }
      }
      axisBottom={{
        tickValues: bottomTickValues,
      }}
      pointBorderColor={{ from: 'serieColor' }}
      useMesh
      pointLabel={(point) => formatAmount(Number(point.y))}
      // eslint-disable-next-line react/no-unstable-nested-components
      tooltip={({ point }) => <ChartTooltip point={point} />}
      yScale={{
        type: 'linear',
        stacked: false,
        // eslint-disable-next-line max-len
        min: minYValue > 0 ? 0 : 'auto', // if minimum value is greater than ZERO, start y axis from ZERO
        // eslint-disable-next-line max-len
        max: maxYValue < 0 ? 0 : 'auto', // if maximum value is lesser than ZERO, start y axis from ZERO
      }}
    />
  );
};
