import { Card, CardContent, CardHeader, Divider, Grid, List } from '@mui/material'
import { uniqueId } from 'lodash'
import * as React from 'react'
import { AVERAGE_WIDGET_TYPES, EXPANSE_TYPES } from '../../../../../shared-resources/CONSTANTS'
import { IAverageWidget, IBalance, IExpense } from '../../../../../shared-resources/INTERFACES'
import { WidgetTitle } from '../WidgetTitle'
import { AverageGenericListItem } from './AverageGenericListItem'

interface BaseProps {
  totalExpenses: number
  totalIncomes: number
  title: string
}
interface ExpenseProps extends BaseProps {
  items: IAverageWidget['averageExpensesPerMonthPerExpense']
  type: AVERAGE_WIDGET_TYPES.EXPENSE
}

interface TypeProps extends BaseProps {
  items: IAverageWidget['averageExpensesPerMonthPerType']
  type: AVERAGE_WIDGET_TYPES.TYPE
}

interface BalanceProps extends BaseProps {
  items: IAverageWidget['averageExpensesPerMonthPerBalance']
  type: AVERAGE_WIDGET_TYPES.BALANCE
}

interface IncomeProps extends BaseProps {
  items: IAverageWidget['averageIncomesPerMonthPerBalance']
  type: AVERAGE_WIDGET_TYPES.INCOME
}

export interface ItemProps {
  expenseRef?: IExpense
  type?: EXPANSE_TYPES
  balanceRef?: IBalance
  amount: number
}

export function AverageGenericList(
  props: ExpenseProps | TypeProps | BalanceProps | IncomeProps
): React.ReactElement {
  const { title, items, type, totalExpenses, totalIncomes } = props
  const sortedItems = items.sort((a, b) => b.amount - a.amount)

  return (
    <Grid
      item
      sm={12}
      md={6}
      lg={6}
      sx={{
        width: '100%',
      }}
    >
      <Card
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <CardHeader title={<WidgetTitle title={title} showTotalIndication />} />
        <CardContent>
          <List
            sx={{
              overflow: 'auto',
              maxHeight: 250,
            }}
          >
            {sortedItems.map((item: ItemProps, index) => (
              <React.Fragment key={uniqueId()}>
                <AverageGenericListItem
                  item={item}
                  type={type}
                  totalExpenses={totalExpenses}
                  totalIncomes={totalIncomes}
                />
                {index !== items.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
    </Grid>
  )
}
