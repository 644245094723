import { Box } from '@mui/material'
import * as React from 'react'

type Props = ({ horizontal: true; vertical?: never } | { horizontal?: never; vertical: true }) &
  (
    | { customSize: number; size?: never }
    | {
        customSize?: never
        size: 'xsmall' | 'small' | 'medium' | 'large'
      }
  )

const sizeMap = {
  xsmall: 0.25,
  small: 0.5,
  medium: 2,
  large: 4,
}

export default function CustomSpacer(props: Props): React.ReactElement {
  const { size = 'small', horizontal, vertical, customSize = 0 } = props

  return (
    <Box
      sx={{
        my: vertical ? customSize || sizeMap[size] : 0,
        mx: horizontal ? customSize || sizeMap[size] : 0,
      }}
    />
  )
}
