import { Stack, useTheme } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomLoadingGuard from '../../components/CustomLoadingGuard';
import CustomPaper from '../../components/CustomPaper';
import CustomSpacer from '../../components/CustomSpacer';
import Page from '../../components/Page';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useAppDispatch, useAppSelector } from '../../hooks/useReactRedux';
import { getAllPeriodsThunk } from '../../slices/allPeriodSlice';
import { getBalancesThunk } from '../../slices/balancesSlice';
import { getCurrentPeriodThunk } from '../../slices/currentPeriodSlice';
import { getExpensesThunk } from '../../slices/expensesSlice';
import { APP_ROUTES, LOCAL_STORAGE_KEYS } from '../../utils/constants';
import Expanses from './Expanses/Expanses';
import FloatButton from './FloatButton';
import PeriodControl from './PeriodSummary/PeriodControl/PeriodControl';
import PeriodSummary from './PeriodSummary/PeriodSummary';

export default function Period(): React.ReactElement {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const theme = useTheme();
  const { storedValue, setValue } = useLocalStorage(LOCAL_STORAGE_KEYS.LAST_PERIOD);
  const { loading, periods = [] } = useAppSelector((state) => state.allPeriod);

  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(getAllPeriodsThunk());
  }, [dispatch]);

  React.useEffect(() => {
    // in case of loading, return
    if (loading) return;

    // in case no id, navigate to the first period id
    if (!id) {
      // eslint-disable-next-line no-unused-expressions
      if (storedValue && periods?.find((period) => period._id === storedValue)) {
        navigate(`/${APP_ROUTES.PERIOD}/${storedValue}`, { replace: true });
        return;
      }

      if (periods.length > 0) {
        navigate(`/${APP_ROUTES.PERIOD}/${periods[0]._id}`, { replace: true });
        setValue(periods[0]._id);
        return;
      }
      return;
    }
    // in case there is id
    dispatch(getCurrentPeriodThunk(id));
    dispatch(getBalancesThunk(id));
    dispatch(getExpensesThunk(id));
  }, [dispatch, id, periods, navigate, loading]);

  return (
    <Page title="Period">
      <CustomLoadingGuard loading={loading}>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            width: '100%',
          }}
        >
          <PeriodControl />
          {periods?.length > 0 && (
            <>
              <CustomPaper
                sx={{
                  bgcolor: theme.palette.background.paper,
                }}
              >
                <PeriodSummary />
              </CustomPaper>
              <CustomPaper
                sx={{
                  bgcolor: theme.palette.background.paper,
                }}
              >
                <Expanses />
              </CustomPaper>
            </>
          )}
          <FloatButton />
        </Stack>
      </CustomLoadingGuard>
      <CustomSpacer vertical customSize={14} />
    </Page>
  );
}
