import { Divider, Stack, Typography } from '@mui/material'
import { isNaN } from 'lodash'
import { useTranslation } from 'react-i18next'
import { IEventPopulated } from '../../../../shared-resources/INTERFACES'
import CustomIcon from '../../components/CustomIcon'
import CustomSpacer from '../../components/CustomSpacer'
import { i18n } from '../../i18n/i18n'
import { getDate } from '../../utils/helpers'
import { COMMON_ICONS } from '../../utils/icons'

interface Props {
  dataBefore: IEventPopulated['entityData']['dataBefore']
  dataAfter: IEventPopulated['entityData']['dataAfter']
}

const translateKey = (key: string) => {
  switch (key) {
    case 'name':
      return i18n.t('translations:common.name')
    case 'balanceRef':
      return i18n.t('translations:common.balance')
    case 'expenseRef':
      return i18n.t('translations:common.expense')
    case 'type':
      return i18n.t('translations:common.type')
    case 'isPeriodical':
      return i18n.t('translations:common.periodicalExpense')
    case 'updatedAt':
      return i18n.t('translations:common.updatedAt')
    case 'amount':
      return i18n.t('translations:common.amount')
    case 'balanceSnapshot':
      return i18n.t('translations:entityNames.balance-snapshot')
    case 'expenseSnapshot':
      return i18n.t('translations:entityNames.expense-snapshot')
    case 'balanceSnapshotRef':
      return i18n.t('translations:common.balance')
    case 'expenseSnapshotRef':
      return i18n.t('translations:common.expense')
    case 'businessNames':
      return i18n.t('translations:common.businessNames')
    case 'realized':
      return i18n.t('translations:common.realized')
    default:
      return key
  }
}

const formatValue = (value: unknown) => {
  // Check if value is a date or is string that can be valid date
  if (value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)))) {
    return getDate({ date: value, format: 'DD/MM/YYYY, HH:mm' })
  }

  // Check if value is a boolean
  if (typeof value === 'boolean') {
    return value ? i18n.t('translations:common.active') : i18n.t('translations:common.inactive')
  }

  // Check if value is an array
  if (value instanceof Array) {
    return value.join(', ')
  }

  // Check if value is an string
  if (typeof value === 'string') {
    return value
  }

  // Check if value is an number
  if (typeof value === 'number') {
    return value.toFixed(2)
  }

  return 'Unsupported format'
}

export const EntityChanges = (props: Props) => {
  const { dataBefore, dataAfter } = props
  const { t } = useTranslation()
  const typographyVariant = 'body2'

  return (
    <Stack direction="column" justifyContent="space-between">
      {dataBefore &&
        dataAfter &&
        Object.keys(dataBefore).map((key) => {
          return (
            <Stack key={key} direction="column" alignItems="flex-start">
              <Divider
                sx={{
                  my: 1,
                  width: '100%',
                }}
              />
              <Typography>
                {t('translations:history.changedField')}: {translateKey(key)}
              </Typography>
              <Typography variant={typographyVariant} color="red">
                {formatValue(dataBefore[key as keyof typeof dataBefore])}
              </Typography>
              <CustomSpacer vertical size="small" />
              <CustomIcon icon={COMMON_ICONS.ARROW_DOWN} />
              <CustomSpacer vertical size="small" />
              <Typography variant={typographyVariant} color="green">
                {formatValue(dataAfter[key as keyof typeof dataAfter])}
              </Typography>
            </Stack>
          )
        })}
    </Stack>
  )
}
