import { Typography, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../../../shared-resources/INTERFACES';
import { formatFiles } from './UploadFromFile/helpers';

interface Props {
  onFilesChanged: (files: Transaction[]) => void;
}

const accept: Accept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

export const Dropzone = (props: Props) => {
  const { onFilesChanged } = props;
  const [error, setError] = useState(false);
  const theme = useTheme();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      const formattedFiles = await formatFiles(acceptedFiles);
      if (formattedFiles.length) {
        onFilesChanged(formattedFiles);
        setError(false);
      }
    } catch (e) {
      setError(true);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
  });
  const { t } = useTranslation();

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div
        {...getRootProps({
          style: {
            flex: 1,
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '40px 0',
            borderWidth: '2px',
            borderRadius: '2px',
            borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
            borderStyle: 'dashed',
            backgroundColor: theme.palette.background.default,
            outline: 'none',
            transition: 'border .24s ease-in-out',
            width: '100%',
          },
        })}
      >
        <input {...getInputProps()} />
        <Typography align="center">
          {error ? 'ERROR' : t('translations:addExpensesFromFile.dropzone.description')}
        </Typography>
      </div>
    </section>
  );
};
