import { createContext } from 'react'
import { IExpenseSnapshotPopulated } from '../../../../../shared-resources/INTERFACES'
import { ACTION_ITEMS } from '../../../utils/constants'

interface IExpanseItemContext {
  handleAction: ({
    actionType,
    selectedExpense,
  }: {
    actionType: ACTION_ITEMS
    selectedExpense: IExpenseSnapshotPopulated
  }) => void
  isFullyExecuted: boolean
  expense: IExpenseSnapshotPopulated
  fullExecutionIndication: boolean
}

export const ExpanseItemContext = createContext({} as IExpanseItemContext)
