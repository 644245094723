import React from 'react'
import { Transaction } from '../../../../shared-resources/INTERFACES'

interface UploadFromFileContext {
  handleDeleteRow: (uuid: string) => void
  handleOpenExpensesModal: (file: Transaction) => void
  expensesModalFile: Transaction
  onSuccessfulExecute: (uuid: string) => void
  selectedBalances?: Record<string, string>
}
export const UploadFromFileContext = React.createContext<UploadFromFileContext>(
  null as unknown as UploadFromFileContext
)
