import { v5 as uuidv5 } from 'uuid'

/* eslint-disable no-unused-vars */
export enum SUPPORTED_CURRENCIES {
  ILS = 'ils',
}

export enum COMMON_STATUES {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export enum USER_ROLE {
  OWNER = 100,
  CONTRIBUTOR = 50,
}

export enum BALANCE_TYPES {
  CC = 'CC',
  SAVINGS = 'SAVINGS',
  BANK = 'BANK',
  INCOME = 'INCOME',
}

export enum EXPANSE_TYPES {
  HOUSING = 'HOUSING',
  FOOD = 'FOOD',
  AUTHORITIES = 'AUTHORITIES',
  OTHER = 'OTHER',
  CHILDREN = 'CHILDREN',
  TRANSPORTATION = 'TRANSPORTATION',
  ENTERTAINMENT = 'ENTERTAINMENT',
  REGULAR = 'REGULAR',
  SHOPPING = 'SHOPPING',
  INSURANCE = 'INSURANCE',
  MEDICAL = 'MEDICAL',
  SAVINGS = 'SAVINGS',
  PERSONAL = 'PERSONAL',
  CLOTHING = 'CLOTHING',
  GIFTS = 'GIFTS',
  VACATION = 'VACATION',
}

export const CRUD_OPERATION = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
}

export type ParamsType = {
  id: string
}

export enum NOTE_MODELS {
  EXPENSE_SNAPSHOT = 'ExpenseSnapshot',
  EXECUTION_UPDATE = 'ExecutionUpdate',
}

export const modulesKeyId = ':id'

export enum AVERAGE_WIDGET_TYPES {
  EXPENSE = 'expense',
  TYPE = 'type',
  BALANCE = 'balance',
  INCOME = 'income',
}

export enum AutoExecutedFileTypes {
  EXPENSE_SNAPSHOT = 'expenseSnapshot',
  EXECUTION_UPDATE = 'executionUpdate',
}

export const enum BUSINESS_NAME_ACTION_TYPES {
  DELETE = 'delete',
  EXCLUDE = 'exclude',
}

const NAMESPACE_UUID = '123e4567-e89b-12d3-a456-426614174000'

export function generateUUIDFromObject<T extends object>(obj: T): string {
  const sortedObject = Object.keys(obj)
    .sort()
    .reduce<Partial<T>>((result, key) => {
      result[key as keyof T] = obj[key as keyof T]
      return result
    }, {})

  const objectString = JSON.stringify(sortedObject)
  return uuidv5(objectString, NAMESPACE_UUID)
}

export enum OvertimeWidgetTimeUnit {
  LAST_12_MONTHS = 'last12Months',
  LAST_6_MONTHS = 'last6Months',
  ALL_TIME = 'allTime',
}
