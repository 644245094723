import { Stack } from '@mui/material';
import CustomButton from 'components/CustomButton';
import { useSearchQueryParam } from 'hooks/useSearchQueryParam';
import { orderBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { COMPONENT_MODES } from 'utils/constants';
import { getComponentModeTitle } from 'utils/helpers';
import { SearchBar } from 'views/Period/Expanses/SearchBar';
import { PopulateCCName } from '../../../../../shared-resources/INTERFACES';
import CustomDialog from '../../../components/CustomDialog';
import CustomLoadingGuard from '../../../components/CustomLoadingGuard';
import CustomSpacer from '../../../components/CustomSpacer';
import { useDebounce } from '../../../hooks/useDebounce';
import { deleteCCName, getCCNames } from '../../../utils/apiUtils';
import { AddCCName } from '../../AddCCName/AddCCName';
import { ManagementPageHeader } from '../ManagementPageHeader';
import { CCNameItem } from './CCNameItem';

export default function CCNames(): React.ReactElement {
  const [ccNames, setCCNames] = React.useState<PopulateCCName[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [addCCNameModal, setAddCCNameModal] = React.useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState({
    idToDelete: '',
    open: false,
  });
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { handleSearchChange, search, handleResetSearch } = useSearchQueryParam({
    queryKey: 's',
  });

  const debounceSearch = useDebounce(search, 500);
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchCCNames();
  }, []);

  const fetchCCNames = async () => {
    setLoading(true);
    try {
      const data = await getCCNames();
      setCCNames(data);
    } catch (error) {
      //
    }
    setLoading(false);
  };

  const handleClose = (closeOptions: { fetchData: boolean }) => {
    const { fetchData } = closeOptions;
    if (fetchData) {
      fetchCCNames();
    }

    setAddCCNameModal(false);
  };

  const handleDelete = async (id: string) => {
    setIsDeleting(true);
    try {
      await deleteCCName(id);
      fetchCCNames();
      closeDeleteModal();
    } catch (error) {
      //
    }
    setIsDeleting(false);
  };

  const openDeleteModal = (id: string) => {
    setDeleteConfirmation({
      idToDelete: id,
      open: true,
    });
  };

  const closeDeleteModal = () => {
    setDeleteConfirmation({
      idToDelete: '',
      open: false,
    });
  };

  return (
    <div>
      <ManagementPageHeader header={t('translations:ccName.ccNames')} />
      <CustomSpacer vertical size="medium" />
      <Stack direction="row" alignItems="center" width="100%">
        <SearchBar
          search={search}
          onSearch={handleSearchChange}
          onResetSearch={handleResetSearch}
        />
        <CustomButton onClick={() => setAddCCNameModal(true)}>
          {getComponentModeTitle(COMPONENT_MODES.ADD)}
        </CustomButton>
      </Stack>
      <CustomSpacer vertical size="medium" />
      <CustomLoadingGuard loading={loading}>
        {orderBy(
          matchSorter(ccNames, debounceSearch, {
            keys: ['name'],
          }),
          'createdAt',
          'desc'
        ).map((ccName) => (
          <>
            <CCNameItem ccName={ccName} key={ccName._id} handleDelete={openDeleteModal} />
            <CustomSpacer vertical size="medium" />
          </>
        ))}
      </CustomLoadingGuard>
      {addCCNameModal && <AddCCName onClose={handleClose} ccNames={ccNames} />}
      {deleteConfirmation.open && (
        <CustomDialog
          onClose={closeDeleteModal}
          onConfirm={() => handleDelete(deleteConfirmation.idToDelete)}
          title={t('translations:common.delete')}
          description={t('translations:common.deleteGenericDesc')}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
}
