import { IconButton, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { PopulateCCName } from '../../../../../shared-resources/INTERFACES'
import { CustomCard } from '../../../components/CustomCard'
import { CustomCardContent } from '../../../components/CustomCardContent'
import CustomIcon from '../../../components/CustomIcon'
import CustomSpacer from '../../../components/CustomSpacer'
import { COMMON_ICONS } from '../../../utils/icons'

interface Props {
  ccName: PopulateCCName
  handleDelete: (id: string) => void
}

export function CCNameItem({ ccName, handleDelete }: Props): React.ReactElement {
  return (
    <CustomCard
      sx={{
        borderLeft: `4px solid ${ccName.balanceRef.color}`,
      }}
    >
      <CustomCardContent>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Typography>{ccName.name}</Typography>
            <CustomSpacer horizontal size="small" />
            <Typography>{ccName.balanceRef.name}</Typography>
            <CustomSpacer horizontal size="small" />
          </Stack>
          <IconButton
            onClick={() => handleDelete(ccName._id)}
            sx={{
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            <CustomIcon icon={COMMON_ICONS.DELETE} />
          </IconButton>
        </Stack>
      </CustomCardContent>
    </CustomCard>
  )
}
