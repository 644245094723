import { Card, SxProps } from '@mui/material'

type Props = {
  children?: React.ReactNode
  sx?: SxProps
  onClick?: () => void
}

export function CustomCard(props: Props): React.ReactElement {
  const { children, sx, onClick } = props
  return (
    <Card
      onClick={onClick}
      sx={{
        p: 1,
        ...sx,
      }}
    >
      {children}
    </Card>
  )
}
