import { Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomActionMoreButton from 'components/ActionMoreButton';
import { CustomCard } from 'components/CustomCard';
import CustomTypography from 'components/CustomTypography';
import NotesMenu from 'components/NotesMenu';
import PreserveLtr from 'components/PreserveLtr';
import { SelectExpenseSnapshot } from 'components/SelectExpesnseSnapshot';
import { useCurrency } from 'hooks/useCurrency';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NOTE_MODELS } from '../../../../../shared-resources/CONSTANTS';
import { IExecutionUpdatePopulated, INote } from '../../../../../shared-resources/INTERFACES';
import CustomDialog from '../../../components/CustomDialog';
import CustomSpacer from '../../../components/CustomSpacer';
import { CustomUserAvatar } from '../../../components/CustomUserAvatar';
import { FileIcon } from '../../../components/FileIcon';
import { UploadedFileBox } from '../../../components/UploadFromFile/UploadedFileBox';
import { useAppSelector } from '../../../hooks/useReactRedux';
import { i18n } from '../../../i18n/i18n';
import { deleteExecutionUpdate } from '../../../utils/apiUtils';
import { ACTION_ITEMS } from '../../../utils/constants';
import { getDate } from '../../../utils/helpers';
import { AddEditNote } from '../../AddEditNote/AddEditNote';

type Props = {
  executionUpdate: IExecutionUpdatePopulated;
  onUpdate?: () => void;
  searchValue?: string;
};

const ACTIONS = [
  {
    label: i18n.t('translations:expenses.actions.moveExecuteUpdate'),
    action: ACTION_ITEMS.SELECT_EXPENSE_SNAP,
  },
  {
    label: i18n.t('translations:note.addNote'),
    action: ACTION_ITEMS.ADD_NOTE,
  },
  { label: i18n.t('translations:common.delete'), action: ACTION_ITEMS.DELETE },
];

type IInit = {
  actionType?: ACTION_ITEMS;
  open: boolean;
  executionUpdate: IExecutionUpdatePopulated;
};

const openModalInit: IInit = {
  open: false,
  executionUpdate: {} as IExecutionUpdatePopulated,
};

export default function ExecutionUpdateItem(props: Props): React.ReactElement {
  const { executionUpdate, onUpdate, searchValue } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const { period } = useAppSelector((state) => state.currentPeriod);
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openModal, setOpenModal] = React.useState(openModalInit);
  const { formatAmount } = useCurrency();
  const displayUploadedFileBox = executionUpdate.businessName;

  const executerVariant = 'caption' as const;
  const executerColor = 'GrayText' as const;

  const handleDeleteExecutionUpdate = async () => {
    setIsLoading(true);
    try {
      await deleteExecutionUpdate({
        deleteExecutionUpdateId: executionUpdate._id.toString(),
        periodRef: period._id.toString(),
      });
      setOpenModal(openModalInit);
      onUpdate?.();
    } catch (error) {
      //
    }
  };

  const handleAction = ({
    actionType,
    selectedExecutionUpdate,
  }: {
    actionType: ACTION_ITEMS;
    selectedExecutionUpdate: IExecutionUpdatePopulated;
  }) => {
    setOpenModal({
      open: true,
      executionUpdate: selectedExecutionUpdate,
      actionType,
    });
  };

  return (
    <CustomCard sx={{ mx: 1, bgcolor: 'background.default' }}>
      <Stack sx={{ px: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="column" sx={{ p: 2 }} width="100%">
          {displayUploadedFileBox ? (
            <>
              <UploadedFileBox searchValue={searchValue} rawRow={executionUpdate} />
              <Divider sx={{ my: 1 }} />
            </>
          ) : (
            <>
              <CustomTypography searchValue={searchValue} align="left">
                <PreserveLtr>{formatAmount(executionUpdate.amount)}</PreserveLtr>
              </CustomTypography>
              <Divider sx={{ my: 1 }} />
            </>
          )}
          <Stack direction="row" alignItems="center">
            <Stack
              direction={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'flex-start' : 'center'}
            >
              {executionUpdate.uuid && (
                <>
                  <FileIcon />
                  <CustomSpacer horizontal size="small" />
                </>
              )}
              <Stack direction="row" alignItems="center">
                <Typography variant={executerVariant} noWrap color={executerColor}>
                  {t('translations:common.addedBy')}:
                </Typography>
                <CustomSpacer horizontal size="small" />
                <CustomUserAvatar
                  size="small"
                  profilePicURL={executionUpdate?.userRef?.profilePic}
                  alt={executionUpdate?.userRef?.fullName}
                  isExternalUser
                />
                <CustomSpacer horizontal size="small" />
                {isMobile && <CustomSpacer vertical size="small" />}
                <Typography variant={executerVariant} noWrap color={executerColor}>
                  {executionUpdate?.userRef?.fullName},
                </Typography>
                <CustomSpacer horizontal size="small" />
              </Stack>
              <Typography variant={executerVariant} noWrap color={executerColor}>
                {getDate({
                  date: executionUpdate.createdAt,
                })}
              </Typography>
            </Stack>
            <CustomSpacer horizontal size="small" />
          </Stack>
        </Stack>
        {openModal.open && openModal.actionType === ACTION_ITEMS.DELETE && (
          <CustomDialog
            onConfirm={handleDeleteExecutionUpdate}
            onClose={() => setOpenModal(openModalInit)}
            title={t('translations:expenses.actions.deleteExpanse')}
            description={t('translations:expenses.actions.deleteExpanseDescription')}
            isLoading={isLoading}
          />
        )}
        {openModal.open && openModal.actionType === ACTION_ITEMS.ADD_NOTE && (
          <AddEditNote
            onClose={() => setOpenModal(openModalInit)}
            documentRef={openModal.executionUpdate._id.toString()}
            modalName={NOTE_MODELS.EXECUTION_UPDATE}
          />
        )}
        {openModal.open && openModal.actionType === ACTION_ITEMS.SELECT_EXPENSE_SNAP && (
          <SelectExpenseSnapshot
            currentExecutionId={executionUpdate._id.toString()}
            onClosed={() => setOpenModal(openModalInit)}
          />
        )}
        <Stack direction="row" alignItems="center">
          <NotesMenu notes={executionUpdate?.notes as INote[]} searchValue={searchValue} />
          <CustomActionMoreButton
            actions={ACTIONS}
            onClick={(action) =>
              handleAction({
                actionType: action,
                selectedExecutionUpdate: executionUpdate,
              })
            }
          />
        </Stack>
      </Stack>
    </CustomCard>
  );
}
