import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Button, ButtonProps } from '@mui/material'
import * as React from 'react'
import CustomIcon from './CustomIcon'
import CustomLoading from './CustomLoading'
import CustomSpacer from './CustomSpacer'

interface IProps {
  children?: React.ReactNode
  isLoading?: boolean
  icon?: FontAwesomeIconProps['icon']
}

export default function CustomButton(props: IProps & ButtonProps): React.ReactElement {
  const { children, isLoading, disabled, icon, sx } = props
  return (
    <Button
      {...props}
      disabled={isLoading || disabled}
      sx={{
        ...sx,
        alignItems: 'center',
        whiteSpace: 'nowrap',
      }}
    >
      {icon && (
        <>
          <CustomIcon icon={icon} size="sm" />
          <CustomSpacer horizontal size="small" />
        </>
      )}
      {isLoading ? <CustomLoading isCircular color="inherit" /> : children}
    </Button>
  )
}
