import { Box, Button, Container, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

export function ErrorComponent() {
  const { t } = useTranslation()

  const handleReload = () => {
    window.location.reload()
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        bgcolor: 'background.default',
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">⚠️</Typography>
            <Typography variant="h6">{t('translations:errors.oops')}</Typography>
            <Button onClick={handleReload} variant="contained">
              {t('translations:errorPage.reload')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
