import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import CustomSpacer from '../../components/CustomSpacer'
import Page from '../../components/Page'
import { i18n } from '../../i18n/i18n'
import { APP_ROUTES, SETTING_TABS } from '../../utils/constants'
import { Application } from './Application'
import { Profile } from './Profile'

const TAB_TITLES: Record<string, string> = {
  [SETTING_TABS.PROFILE]: i18n.t('translations:settings.profile.title'),
  [SETTING_TABS.APPLICATION]: i18n.t('translations:settings.application.title'),
}

const TABS = {
  [SETTING_TABS.PROFILE]: 0,
  [SETTING_TABS.APPLICATION]: 1,
}
const TAB_KEYS = Object.keys(TABS)

export const Settings = () => {
  const { tab } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const convertedTab = TAB_KEYS.findIndex((key) => key === tab)

  React.useEffect(() => {
    if (!tab) {
      navigate(`/${APP_ROUTES.SETTINGS}/${TAB_KEYS[0]}`, {
        replace: true,
      })
    }
  }, [tab])

  const handleChange = (newTab: string) => {
    navigate(`/${APP_ROUTES.SETTINGS}/${newTab}`, {
      replace: true,
    })
  }

  return !tab ? null : (
    <Page title={t('translations:navigation.settings')}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={convertedTab}>
          {Object.keys(TABS).map((tabKey) => (
            <Tab
              key={tabKey}
              label={TAB_TITLES[tabKey]}
              onClick={() => handleChange(tabKey)}
            />
          ))}
        </Tabs>
      </Box>
      <CustomSpacer vertical size="medium" />
      {convertedTab === TABS.profile && <Profile />}
      {convertedTab === TABS.balances && <Application />}
    </Page>
  )
}
