import { Grid } from '@mui/material'
import * as React from 'react'
import { IExpenseSnapshotPopulated } from '../../../../../shared-resources/INTERFACES'
import ExecutionUpdates from './ExecutionUpdates'

type Props = {
  expense: IExpenseSnapshotPopulated
  searchValue?: string
}
export default function ExpanseItemDetails(props: Props): React.ReactElement {
  const { searchValue, expense, ...rest } = props

  return (
    <Grid container {...rest} alignItems="center" spacing={2} sx={{ alignItems: 'flex-end' }}>
      <Grid item sm={12} xs={12}>
        <ExecutionUpdates
          executionUpdates={expense.executionUpdatesRef}
          searchValue={searchValue}
        />
      </Grid>
    </Grid>
  )
}
