import { Modal, SxProps, useTheme } from '@mui/material';
import * as React from 'react';
import { CustomCard } from './CustomCard';

interface Props {
  onClose?: () => void;
  open?: boolean;
  children: React.ReactNode;
  sx?: SxProps;
}

const ModalCard = ({ sx, children }: { children: Props['children']; sx?: Props['sx'] }) => {
  const theme = useTheme();

  return (
    <CustomCard
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        boxShadow: 24,
        overflow: 'auto',
        p: 4,
        maxHeight: '90svh',
        [theme.breakpoints.down('md')]: {
          maxHeight: '80svh',
          width: '80%',
        },
        [theme.breakpoints.up('md')]: {
          width: 650,
        },
        [theme.breakpoints.up('lg')]: {
          width: 850,
        },
        ...sx,
      }}
    >
      {children}
    </CustomCard>
  );
};

export default function CustomModal(props: Props): React.ReactElement {
  const { sx, open = true, children, onClose } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <ModalCard sx={sx}>{children}</ModalCard>
    </Modal>
  );
}
