import { Typography, useTheme } from '@mui/material';
import * as React from 'react';
import CustomTypography from './CustomTypography';

interface Props extends React.ComponentProps<typeof Typography> {
  children: React.ReactNode;
  backgroundColor?: string;
  searchValue?: string;
}

export function CustomTag(props: Props): React.ReactElement {
  const { searchValue, children, variant, backgroundColor, color, ...rest } = props;
  const { spacing, palette } = useTheme();
  const tagBackgroundColor = backgroundColor || palette.grey[400];
  const tagColor = palette.getContrastText(tagBackgroundColor);

  return (
    <div>
      <CustomTypography
        searchValue={searchValue}
        variant={variant || 'caption'}
        {...rest}
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxHeight: spacing(1.8),
          lineHeight: spacing(1.8),
          backgroundColor: tagBackgroundColor,
          color: color || tagColor,
          borderRadius: spacing(0.5),
          padding: spacing(0.5, 1),
          ...rest.sx,
        }}
      >
        {children}
      </CustomTypography>
    </div>
  );
}
