import { useTheme } from '@mui/system'
import * as React from 'react'
import { IExpenseSnapshotPopulated } from '../../../../../shared-resources/INTERFACES'
import { ProgressBarIndication } from '../../../components/ProgressBarIndication'
import { ExpanseItemContext } from './ExpanseItemContext'

type Props = {
  expense: IExpenseSnapshotPopulated
  searchValue?: string
}

export const ExpanseAmountInfo = (props: Props) => {
  const { expense, searchValue } = props
  const { isFullyExecuted } = React.useContext(ExpanseItemContext)
  const executedAmount = expense?.executionUpdatesRef?.reduce((acc, curr) => acc + curr.amount, 0)
  const amountLeft = expense.amount - executedAmount
  const theme = useTheme()

  return (
    <ProgressBarIndication
      searchValue={searchValue}
      amountLeft={amountLeft}
      totalAmount={expense.amount}
      color={expense?.balanceSnapshotRef?.balanceRef?.color || theme.palette.primary.main}
      isFullyExecuted={isFullyExecuted}
      executedAmount={executedAmount}
    />
  )
}
