import { useEffect, useState } from 'react'
import { getAverageExpense } from 'utils/apiUtils'

export const useAverageExpense = (expenseRef?: string) => {
  const [averageExpenseAmount, setAverageExpenseAmount] = useState<number | undefined>()

  useEffect(() => {
    if (!expenseRef) {
      setAverageExpenseAmount(undefined)
      return
    }

    const getAverage = async () => {
      try {
        const { average } = await getAverageExpense(expenseRef)
        setAverageExpenseAmount(average)
      } catch (error) {
        //
      }
    }
    getAverage()
  }, [expenseRef])

  return { averageExpenseAmount }
}
