/* eslint-disable jsx-a11y/anchor-is-valid */
import { Divider, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CustomSpacer from '../../../../components/CustomSpacer'
import { useCurrency } from '../../../../hooks/useCurrency'
import { ExpenseSnapshotWithPeriod } from '../../../../types/expenseSnapshotWithPeriod'
import { APP_ROUTES } from '../../../../utils/constants'
import { getDate } from '../../../../utils/helpers'

interface Props {
  expenseSnapshots: ExpenseSnapshotWithPeriod[]
}

export const ExpansesInUse = (props: Props) => {
  const { expenseSnapshots } = props
  const { formatAmount } = useCurrency()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack direction="column">
      {expenseSnapshots?.map((expense) => (
        <Stack
          key={expense._id.toString()}
          direction={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'start' : 'center'}
        >
          <Typography>{formatAmount(expense.amount)}</Typography>
          <CustomSpacer horizontal size="small" />
          <Typography>{getDate({ date: expense.createdAt })}</Typography>
          <CustomSpacer horizontal size="small" />
          {expense.periodRef ? (
            <Link
              onClick={() => navigate(`/${APP_ROUTES.PERIOD}/${expense.periodRef?._id}`)}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Typography>
                {t('translations:dateAndTime.month')}{' '}
                {getDate({
                  date: expense.periodRef?.startDate,
                  format: 'M',
                })}{' '}
                - {t('translations:expenses.expansePage.goToPeriod')}
              </Typography>
            </Link>
          ) : (
            <Typography>{t('translations:expenses.expansePage.notInUse')}</Typography>
          )}
          <Divider
            sx={{
              my: 0.5,
            }}
          />
        </Stack>
      ))}
    </Stack>
  )
}
