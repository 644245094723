/* eslint-disable jsx-a11y/anchor-is-valid */
import { Divider, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { IExpense, IPopulatedExpense } from '../../../../../../shared-resources/INTERFACES'
import CustomButton from '../../../../components/CustomButton'
import { CustomCard } from '../../../../components/CustomCard'
import { CustomCardContent } from '../../../../components/CustomCardContent'
import CustomDialog from '../../../../components/CustomDialog'
import CustomLoadingGuard from '../../../../components/CustomLoadingGuard'
import Page from '../../../../components/Page'
import { ExpenseSnapshotWithPeriod } from '../../../../types/expenseSnapshotWithPeriod'
import {
  deleteExpense,
  getExpensesById,
  getExpensesSnapshotByExpanse,
} from '../../../../utils/apiUtils'
import { APP_ROUTES, COMPONENT_MODES, MANAGEMENT_TABS } from '../../../../utils/constants'
import { AddEditExpense } from '../AddEditExpense'
import { BaseExpense } from '../BaseExpense'
import { ExpansesBusinessNames } from './ExpansesBusinessNames'
import { ExpansesInUse } from './ExpansesInUse'

export default function ExpensePage() {
  const { id } = useParams()
  const [loading, setLoading] = React.useState(true)
  const [expenseState, setExpenseState] = React.useState<IPopulatedExpense>()
  const [expenseSnapshots, setExpenseSnapshots] = React.useState<ExpenseSnapshotWithPeriod[]>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [modal, setModal] = React.useState({
    open: false,
    mode: COMPONENT_MODES.EDIT,
    expense: undefined as IPopulatedExpense | undefined,
  })
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    expenseId: '',
  })

  React.useEffect(() => {
    if (!id) return
    fetchExpenses({ expenseId: id, showSpinner: true })
  }, [id])

  React.useEffect(() => {
    if (!expenseState?._id) return
    fetchExpensesSnapshot(expenseState._id as string)
  }, [expenseState])

  const fetchExpenses = async ({
    expenseId,
    showSpinner = true,
  }: {
    expenseId: string
    showSpinner?: boolean
  }) => {
    setLoading(showSpinner)
    try {
      const response = await getExpensesById(expenseId)
      setExpenseState(response)
    } catch (error) {
      //
    }
    setLoading(false)
  }

  const fetchExpensesSnapshot = async (expenseId: string) => {
    try {
      const response = await getExpensesSnapshotByExpanse(expenseId)
      setExpenseSnapshots(response as unknown as ExpenseSnapshotWithPeriod[])
    } catch (error) {
      //
    }
  }

  const handleDelete = async (expenseId: string) => {
    try {
      await deleteExpense(expenseId)
      navigate(`${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.EXPENSES}`)
    } catch (error) {
      //
    }
  }

  const handleCloseAddEdit = () => {
    setModal({ open: false, mode: COMPONENT_MODES.EDIT, expense: undefined })
  }

  const handleOpenAddEditModal = ({
    mode,
    expense,
  }: {
    mode: COMPONENT_MODES
    expense?: IPopulatedExpense
  }) => {
    setModal({ open: true, mode, expense })
  }

  const handleSuccess = () => {
    handleCloseAddEdit()
    if (!id) return
    fetchExpenses({ expenseId: id, showSpinner: false })
  }

  const handleCloseConfirmation = () => {
    setConfirmModal({ open: false, expenseId: '' })
  }

  const handleOpenConfirmation = (expenseId: string) => {
    setConfirmModal({ open: true, expenseId })
  }

  return !expenseState ? null : (
    <Page title={id}>
      <CustomCard>
        <CustomLoadingGuard loading={loading}>
          <CustomCardContent>
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Stack direction="column" justifyContent="space-between" width="100%">
                <BaseExpense expense={expenseState as unknown as IExpense} />
                <Typography>{expenseState?.balanceRef?.name}</Typography>
                <Typography>
                  {t('translations:common.household')}: {expenseState?.householdRef?.name}
                </Typography>
                {expenseSnapshots?.length && (
                  <>
                    <Divider
                      sx={{
                        my: 2,
                      }}
                    />
                    <Typography variant="body2">
                      {t('translations:expenses.expansePage.useIn')} {expenseSnapshots.length}{' '}
                      {t('translations:common.expenses')}
                    </Typography>
                    <ExpansesInUse expenseSnapshots={expenseSnapshots} />
                  </>
                )}
                {expenseState.businessNames?.length ? (
                  <Divider
                    sx={{
                      my: 2,
                    }}
                  />
                ) : null}
                {id && (
                  <ExpansesBusinessNames
                    businessNames={expenseState.businessNames}
                    expenseId={id}
                    onUpdate={() => fetchExpenses({ expenseId: id, showSpinner: false })}
                  />
                )}
              </Stack>
              <Stack direction="column">
                <CustomButton onClick={() => handleOpenConfirmation(expenseState._id.toString())}>
                  {t('translations:common.delete')}
                </CustomButton>
                <CustomButton
                  onClick={() =>
                    handleOpenAddEditModal({
                      mode: COMPONENT_MODES.EDIT,
                      expense: expenseState,
                    })
                  }
                >
                  {t('translations:common.edit')}
                </CustomButton>
              </Stack>
            </Stack>
          </CustomCardContent>
        </CustomLoadingGuard>
      </CustomCard>
      {modal.open && (
        <AddEditExpense
          mode={modal.mode}
          expense={modal?.expense}
          onClose={handleCloseAddEdit}
          onSuccess={handleSuccess}
        />
      )}
      {confirmModal.open && (
        <CustomDialog
          title={t('translations:common.delete')}
          description={t('translations:common.deleteGenericDesc')}
          onClose={handleCloseConfirmation}
          onConfirm={() => handleDelete(confirmModal.expenseId)}
        />
      )}
    </Page>
  )
}
