/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigate } from 'react-router-dom'
import {
  APP_ROUTES,
  COMPONENT_MODES,
  MANAGEMENT_TABS,
} from '../../utils/constants'
import AddEditHousehold from '../Managmenet/Households/AddEditHousehold'

export const AddHousehold = (): React.ReactElement => {
  const navigate = useNavigate()

  const handleOnSuccess = async () => {
    navigate(`/${APP_ROUTES.MANAGEMENT}/${MANAGEMENT_TABS.HOUSEHOLDS}`)
  }

  return (
    <AddEditHousehold
      mode={COMPONENT_MODES.ADD}
      onClose={() => {}}
      onSuccess={handleOnSuccess}
      isInInitialState
    />
  )
}
