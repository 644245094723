import { i18n } from '../i18n/i18n'
import { ACTION_ITEMS } from './constants'

export const EXPENSE_ITEM_ACTIONS = [
  {
    label: i18n.t('translations:expenses.actions.execute'),
    action: ACTION_ITEMS.EXECUTE,
  },
  {
    label: i18n.t('translations:expenses.actions.partialExecute'),
    action: ACTION_ITEMS.PARTIAL_EXECUTE,
  },
  {
    label: i18n.t('translations:expenses.actions.closeExpense'),
    action: ACTION_ITEMS.CLOSE_EXPENSE,
  },
  { label: i18n.t('translations:common.edit'), action: ACTION_ITEMS.EDIT },
  {
    label: i18n.t('translations:note.addNote'),
    action: ACTION_ITEMS.ADD_NOTE,
  },
  { label: i18n.t('translations:common.delete'), action: ACTION_ITEMS.DELETE },
]

export const PERIOD_SELECTION_ACTIONS = [
  { label: i18n.t('translations:common.edit'), action: ACTION_ITEMS.EDIT },
  { label: i18n.t('translations:common.delete'), action: ACTION_ITEMS.DELETE },
]
