import { useEffect, useState } from 'react'
import { LocalStorageKeys } from 'src/types/localStorageKeys'

export function useLocalStorageState<T>(key: LocalStorageKeys, initialValue?: T) {
  const readValue = () => {
    // Check if item exists in localStorage
    const item = window.localStorage.getItem(key)
    if (item) {
      return JSON.parse(item)
    }
    return initialValue
  }

  // Use useState hook to create state
  const [storedValue, setStoredValue] = useState<T>(readValue)

  // Update localStorage when state changes
  useEffect(() => {
    if (storedValue === undefined) {
      window.localStorage.removeItem(key)
    } else {
      window.localStorage.setItem(key, JSON.stringify(storedValue))
    }
  }, [key, storedValue])

  return [storedValue, setStoredValue] as const
}
