import { useAppDispatch } from 'hooks/useReactRedux';
import * as React from 'react';
import { getAllPeriodsThunk } from 'src/slices/allPeriodSlice';
import Page from '../../components/Page';
import { OverallWidget } from './OverallWidget';

export default function Dashboard(): React.ReactElement {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getAllPeriodsThunk());
  }, [dispatch]);

  return (
    <Page title="Dashboard">
      <OverallWidget />
    </Page>
  );
}
