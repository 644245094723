import * as React from 'react';
import { IOverallWidget } from 'shared-resources/INTERFACES';
import {
  calculateRemainingExpenseAmount,
  calculateTotals,
} from '../../../../../../../shared-resources/FUNCTIONS';
import { useAppSelector } from '../../../../../hooks/useReactRedux';
import Summary from '../Summary';

export default function SummaryAccordion({
  widgetData,
}: {
  widgetData?: IOverallWidget;
}): React.ReactElement {
  const { balances } = useAppSelector((state) => state.balances);
  const { expenses } = useAppSelector((state) => state.expenses);

  const {
    totalExpenses,
    totalIncomes,
    notRealizedExpenses,
    totalBankBalances,
    bankAccountInEndOfPeriod,
    totalAccumulated,
    totalPeriodicalExpenses,
    totalNonPeriodicalExpenses,
    totalSavingsExpenses,
  } = calculateTotals({
    balances,
    expenses,
  });

  const remainingExpensesAmount = expenses.reduce((acc, curr) => {
    const { remainingExpenseAmount } = calculateRemainingExpenseAmount(curr);

    return acc + remainingExpenseAmount;
  }, 0);

  return (
    <Summary
      totalExpenses={totalExpenses}
      totalIncomes={totalIncomes}
      bankAccountAccumulated={totalAccumulated}
      bankAccountBalances={totalBankBalances}
      bankAccountInEndOfPeriod={bankAccountInEndOfPeriod}
      upcomingExpenses={notRealizedExpenses}
      notRealizedExpenses={remainingExpensesAmount}
      totalPeriodicalExpenses={totalPeriodicalExpenses}
      totalNonPeriodicalExpenses={totalNonPeriodicalExpenses}
      totalSavingsExpenses={totalSavingsExpenses}
      widgetData={widgetData}
    />
  );
}
