import { Stack } from '@mui/material';
import { compareDesc } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEventPopulated } from '../../../../shared-resources/INTERFACES';
import CustomButton from '../../components/CustomButton';
import CustomLoadingGuard from '../../components/CustomLoadingGuard';
import CustomSpacer from '../../components/CustomSpacer';
import Page from '../../components/Page';
import { getEvents } from '../../utils/apiUtils';
import { HistoryItem } from './HistoryItem';

export const History = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<IEventPopulated[]>([]);
  const [count, setCount] = useState(0);
  const [moreLoading, setMoreLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const fetchEvents = async (showSpinner = true) => {
    setLoading(showSpinner);

    try {
      const response = await getEvents({ page, limit: 10 });

      if (!response?.events?.length) return;

      setEvents((prevEvents) => [...prevEvents, ...response.events]);
      setCount(response.count);
    } catch (err) {
      //
    } finally {
      setLoading(false);
      setMoreLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const loadMore = () => {
    setMoreLoading(true);
    setPage((prevPage) => prevPage + 1);
    fetchEvents(false);
  };

  return (
    <Page title={t('translations:navigation.history')}>
      <CustomLoadingGuard loading={loading}>
        {events
          .sort((a, b) => compareDesc(new Date(a?.timestamp), new Date(b?.timestamp)))
          .map((event) => (
            <React.Fragment key={event._id}>
              <HistoryItem key={event._id} event={event} />
              <CustomSpacer vertical size="medium" />
            </React.Fragment>
          ))}
        <Stack direction="row" justifyContent="center">
          {events.length < count && (
            <CustomButton isLoading={moreLoading} onClick={loadMore}>
              {t('translations:common.loadMore')}
            </CustomButton>
          )}
        </Stack>
      </CustomLoadingGuard>
    </Page>
  );
};
