import { useTheme } from '@mui/material'
import { ReactNode } from 'react'
import StickyBox, { StickyBoxCompProps } from 'react-sticky-box'
import { APP_BAR_HEIGHT } from '../utils/constants'

interface Props extends StickyBoxCompProps {
  children: ReactNode
}

export const CustomStickyBox = (props: Props) => {
  const { children, offsetTop = APP_BAR_HEIGHT + 4 } = props
  const theme = useTheme()
  const defaultZIndex = theme.zIndex.appBar - 1
  return (
    <StickyBox
      offsetTop={offsetTop}
      style={{
        zIndex: defaultZIndex,
      }}
      {...props}
    >
      {children}
    </StickyBox>
  )
}
