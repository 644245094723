import { useContext } from 'react'
import { MainContext } from 'src/context/MainContext'
import { formatAmountWithCommas, getCurrencySymbol } from '../utils/helpers'
import { useAppSelector } from './useReactRedux'

export const useCurrency = () => {
  const { currentHousehold } = useAppSelector((state) => state.initialData)
  const { localSettings } = useContext(MainContext)
  const { maskAmount } = localSettings
  const symbol = currentHousehold && getCurrencySymbol(currentHousehold.currency)

  const formatAmount = (amount: number, toFixed = 2) => {
    const formattedAmount = `${symbol || ''}${formatAmountWithCommas(amount, toFixed)}`
    return maskAmount ? formattedAmount.replace(/\d/g, '*') : formattedAmount
  }

  return {
    formatAmount,
  }
}
