import * as React from 'react'
import { MainContext } from '../context/MainContext'

type Props = {
  children: JSX.Element | null
}

export default function Protected(props: Props): React.ReactElement | null {
  const { children } = props
  const { isLoggedIn } = React.useContext(MainContext)

  return isLoggedIn ? children : null
}
