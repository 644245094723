import { ButtonProps } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MainContext } from '../context/MainContext'
import { firebaseAuth } from '../hooks/useFirebaseAuth'
import { COMMON_ICONS } from '../utils/icons'
import CustomButton from './CustomButton'
import CustomDialog from './CustomDialog'

interface Props extends ButtonProps {
  children?: React.ReactNode
}
export function CustomSignoutButton(props: Props): React.ReactElement | null {
  const { ...rest } = props
  const { isLoggedIn } = React.useContext(MainContext)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()

  const handleClick = async (): Promise<void> => {
    setLoading(true)
    try {
      await firebaseAuth.signOut()
    } catch (error) {
      //
    }
    setLoading(false)
  }

  return !isLoggedIn ? null : (
    <>
      <CustomButton
        icon={COMMON_ICONS.SIGNOUT}
        onClick={() => setOpenDialog(true)}
        {...rest}
      >
        {t('translations:signoutButton.title')}
      </CustomButton>
      {openDialog && (
        <CustomDialog
          isLoading={loading}
          title={t('translations:signoutButton.title')}
          description={t('translations:signoutButton.description')}
          onConfirm={handleClick}
          onClose={() => setOpenDialog(false)}
        />
      )}
    </>
  )
}
