/* eslint-disable max-len */
import * as Sentry from '@sentry/react'
import { ErrorComponent } from 'components/ErrorComponent'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import { RTL } from './components/RTL'
import { FirebaseProvider } from './context/FirebaseContext'
import './i18n/i18n'
import { store } from './store'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://772c23479ba0c5e1f208044072196138@o4505691148582912.ingest.sentry.io/4505691151269888',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
    <RTL>
      <Provider store={store}>
        <FirebaseProvider>
          <App />
        </FirebaseProvider>
      </Provider>
    </RTL>
  </Sentry.ErrorBoundary>
)
