export const APP_ROUTES = {
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  PERIOD: 'period',
  MANAGEMENT: 'management',
  ADD_HOUSEHOLD: 'add-household',
  SETTINGS: 'settings',
  HISTORY: 'history',
};

export const LOCAL_STORAGE_KEYS = {
  LAST_ROUTE: 'lastRoute',
  LAST_PERIOD: 'lastPeriod',
  CURRENT_HOUSEHOLD_ID: 'currentHouseholdId',
};

export const API_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};
export type ActionItemType = keyof typeof ACTION_ITEMS;

export enum ACTION_ITEMS {
  EXECUTE = 'EXECUTE',
  PARTIAL_EXECUTE = 'PARTIAL_EXECUTE',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  ADD_PERIOD = 'ADD_PERIOD',
  DELETE_PERIOD = 'DELETE_PERIOD',
  ADD_EXPENSE = 'ADD_EXPENSE',
  ADD_EXPENSES_FROM_FILE = 'ADD_EXPENSE_FROM_FILE',
  EDIT_PERIOD = 'EDIT_PERIOD',
  ADD_BALANCE = 'ADD_BALANCE',
  ADD_NOTE = 'ADD_NOTE',
  CLOSE_EXPENSE = 'CLOSE_EXPENSE',
  EXCLUDE_BUSINESS_NAME = 'EXCLUDE_BUSINESS_NAME',
  SELECT_EXPENSE_SNAP = 'SELECT_EXPENSE_SNAP',
}

export enum COMPONENT_MODES {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  ADD = 'ADD',
}

export const MANAGEMENT_TABS = {
  BALANCES: 'balances',
  EXPENSES: 'expenses',
  HOUSEHOLDS: 'households',
  NOTES: 'notes',
  CC_NAMES: 'ccNames',
  SCRAPER_SECRETS: 'scraperSecrets',
  EXCLUDE_BUSINESS_NAMES: 'excludeBusinessNames',
};

export const SETTING_TABS = {
  PROFILE: 'profile',
  APPLICATION: 'application',
};

export enum IN_ROUTE_TYPES {
  GROUP = 'group',
  ITEM = 'item',
}

export const DATE_PICKER_FORMAT = 'DD/MM/yyyy';
export const DRAWER_WIDTH = 240;
export const FLOAT_PADDING = 16;
export const APP_BAR_HEIGHT = 64;
export const PERIOD_SELECTION_HEIGHT = 48;

export enum PERIOD_VIEW_VARIANTS {
  EXPANDED = 'expanded',
  COLLAPSED = 'collapsed',
}
