/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, Typography } from '@mui/material';
import { useSearchQueryParam } from 'hooks/useSearchQueryParam';
import { orderBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import { useDeferredValue, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { calculateRemainingExpenseAmount } from '../../../../../shared-resources/FUNCTIONS';
import CustomLoadingGuard from '../../../components/CustomLoadingGuard';
import CustomSpacer from '../../../components/CustomSpacer';
import { useAppSelector } from '../../../hooks/useReactRedux';
import { i18n } from '../../../i18n/i18n';
import { ExpanseItemMemo } from '../ExpanseItem/ExpanseItem';
import { SearchBar } from './SearchBar';
import { useAverageExpensesAmount } from './useAverageExpensesAmount';

const SORTING_OPTIONS = [
  {
    label: i18n.t('translations:common.fullyExecuted'),
    value: 'fullyExecuted',
  },
  {
    label: i18n.t('translations:common.createdAt'),
    value: 'createdAt',
  },
  {
    label: i18n.t('translations:common.updatedAt'),
    value: 'updatedAt',
  },
  {
    label: i18n.t('translations:common.name'),
    value: 'expenseRef.name',
  },
  {
    label: i18n.t('translations:common.type'),
    value: 'expenseRef.type',
  },
  {
    label: i18n.t('translations:common.balance'),
    value: 'balanceSnapshotRef._id',
  },
  {
    label: i18n.t('translations:common.amount'),
    value: 'amount',
  },
  {
    label: i18n.t('translations:common.duoDate'),
    value: 'duoDate',
  },
];

export default function Expenses() {
  const { expenses, loading } = useAppSelector((state) => state.expenses);
  const { t } = useTranslation();
  const [sorting, setSorting] = useState(SORTING_OPTIONS[0].value);
  const [sortingOrder, setSortingOrder] = useState<'asc' | 'desc'>('asc');
  const { handleSearchChange, handleResetSearch, search } = useSearchQueryParam({
    queryKey: 's',
  });
  const { averageExpensesAmount } = useAverageExpensesAmount();
  const deferredValue = useDeferredValue(search);

  const remappedExpenses = useMemo(
    () =>
      expenses.map((expense) => {
        const { remainingExpenseAmount } = calculateRemainingExpenseAmount(expense);
        return {
          ...expense,
          fullyExecuted: Boolean(remainingExpenseAmount === 0),
          averageExpenseAmount: averageExpensesAmount?.find(
            (averageExpense) => averageExpense.id === expense.expenseRef?._id
          )?.average,
        };
      }),
    [expenses, averageExpensesAmount]
  );

  const handleSortingChange = (value: string) => {
    setSorting(value);
    setSortingOrder(
      // eslint-disable-next-line no-nested-ternary
      value === sorting ? (sortingOrder === 'asc' ? 'desc' : 'asc') : sortingOrder
    );
  };

  const orderedAndFilteredExpenses = useMemo(
    () =>
      orderBy(
        matchSorter(remappedExpenses, deferredValue, {
          keys: [
            'expenseRef.name',
            'balanceSnapshotRef.balanceRef.name',
            'amount',
            'notes.*.content',
            'executionUpdatesRef.*.notes.*.content',
            'executionUpdatesRef.*.businessName',
            'executionUpdatesRef.*.amount',
          ],
        }),
        [sorting, 'createdAt'],
        sortingOrder
      ),
    [remappedExpenses, deferredValue, sorting, sortingOrder]
  );

  return (
    <Stack>
      <SearchBar
        search={search}
        sorting={sorting}
        sortingOrder={sortingOrder}
        handleSortingChange={handleSortingChange}
        onSearch={handleSearchChange}
        onResetSearch={handleResetSearch}
        sortingOptions={SORTING_OPTIONS}
      />
      <CustomSpacer vertical size="xsmall" />
      <CustomLoadingGuard loading={loading}>
        {orderedAndFilteredExpenses.length > 0 ? (
          orderedAndFilteredExpenses.map((expense) => (
            <ExpanseItemMemo
              key={expense._id.toString()}
              expense={expense}
              searchValue={deferredValue}
            />
          ))
        ) : (
          <Stack
            sx={{
              height: '100%',
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5">{t('translations:emptyStates.expenses')}</Typography>
          </Stack>
        )}
      </CustomLoadingGuard>
    </Stack>
  );
}
