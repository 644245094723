import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AVERAGE_WIDGET_TYPES } from '../../../../../shared-resources/CONSTANTS';
import { IAverageWidget } from '../../../../../shared-resources/INTERFACES';
import { AverageGenericList } from './AverageGenericList';

interface Props {
  data?: IAverageWidget;
}

export function AverageWidgetLists(props: Props): React.ReactElement | null {
  const { data } = props;
  const { t } = useTranslation();

  if (!data) return null;

  const {
    averageExpensesPerMonthPerExpense,
    averageExpensesPerMonthPerType,
    averageExpensesPerMonthPerBalance,
    averageExpensesPerMonth,
    averageIncomesPerMonth,
    averageIncomesPerMonthPerBalance,
  } = data;

  return (
    <Grid container spacing={2}>
      <AverageGenericList
        items={averageIncomesPerMonthPerBalance}
        type={AVERAGE_WIDGET_TYPES.INCOME}
        totalExpenses={averageExpensesPerMonth}
        totalIncomes={averageIncomesPerMonth}
        title={t('translations:widgets.incomesListTitle')}
      />
      <AverageGenericList
        items={averageExpensesPerMonthPerExpense}
        type={AVERAGE_WIDGET_TYPES.EXPENSE}
        totalExpenses={averageExpensesPerMonth}
        totalIncomes={averageIncomesPerMonth}
        title={t('translations:widgets.expensesListTitle')}
      />
      <AverageGenericList
        items={averageExpensesPerMonthPerType}
        type={AVERAGE_WIDGET_TYPES.TYPE}
        totalExpenses={averageExpensesPerMonth}
        totalIncomes={averageIncomesPerMonth}
        title={t('translations:widgets.typesListTitle')}
      />
      <AverageGenericList
        items={averageExpensesPerMonthPerBalance}
        type={AVERAGE_WIDGET_TYPES.BALANCE}
        totalExpenses={averageExpensesPerMonth}
        totalIncomes={averageIncomesPerMonth}
        title={t('translations:widgets.balancesListTitle')}
      />
    </Grid>
  );
}
