import { Button, Card, ListSubheader, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSearchQueryParam } from 'hooks/useSearchQueryParam';
import { matchSorter } from 'match-sorter';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from 'views/Period/Expanses/SearchBar';
import { IExpenseSnapshotPopulated } from '../../../../shared-resources/INTERFACES';
import { useDebounce } from '../../hooks/useDebounce';
import { useAppSelector } from '../../hooks/useReactRedux';
import { COMPONENT_MODES } from '../../utils/constants';
import { AddEditExpenseSnapshot } from '../../views/AddEditExpenseSnapshot/AddEditExpenseSnapshot';
import ExpanseItemSummary from '../../views/Period/ExpanseItem/ExpanseItemSummary';
import { PartialExecute } from '../../views/Period/ExpanseItem/PartialExecute';
import CustomButton from '../CustomButton';
import CustomModal from '../CustomModal';
import CustomSpacer from '../CustomSpacer';
import { UploadFromFileContext } from './UploadFromFileContext';

enum ModalTypes {
  PARTIAL = 'partial',
  NEW = 'new',
}

interface Props {
  handleClose: () => void;
}

interface InitialModalState {
  open: boolean;
  expense?: IExpenseSnapshotPopulated;
  type: ModalTypes;
}
const initialModalState: InitialModalState = {
  open: false,
  expense: undefined,
  type: ModalTypes.NEW,
};

export const FilesExpensesModal = (props: Props) => {
  const { handleClose } = props;
  const { expenses } = useAppSelector((state) => state.expenses);
  const { expensesModalFile, selectedBalances, onSuccessfulExecute } =
    useContext(UploadFromFileContext);
  const theme = useTheme();
  const [executeModel, setExecuteModel] = useState(initialModalState);
  const { handleSearchChange, handleResetSearch, search } = useSearchQueryParam({
    queryKey: 'e-s',
  });
  const debouncedSearch = useDebounce(search, 500);
  const { t } = useTranslation();
  const matchedExpenses = expenses.filter(
    (expense) => expense.balanceSnapshotRef._id === expensesModalFile?.balanceSnapshotRef
  );
  const filteredExpenses = matchSorter(matchedExpenses, debouncedSearch, {
    keys: ['expenseRef.name', 'balanceSnapshotRef.balanceRef.name', 'amount'],
  });

  const expenseBalanceRef = selectedBalances?.[expensesModalFile?.cardNumber as string];

  const handlePartialExecute = (expense?: IExpenseSnapshotPopulated) => {
    if (expense) {
      setExecuteModel({
        open: true,
        expense,
        type: ModalTypes.PARTIAL,
      });
    } else {
      setExecuteModel({
        open: true,
        type: ModalTypes.NEW,
      });
    }
  };

  const handleCloseExecute = () => {
    setExecuteModel(initialModalState);
  };

  return (
    <CustomModal
      sx={{
        [theme.breakpoints.up('md')]: {
          width: '90%',
        },
        [theme.breakpoints.up('lg')]: {
          width: '90%',
        },
        [theme.breakpoints.up('xl')]: {
          width: '75%',
        },
      }}
      open
      onClose={handleClose}
    >
      <ListSubheader>
        <Card
          sx={{
            px: 2,
            py: 1,
          }}
        >
          <Stack flexDirection="row" width="100%" alignItems="center">
            <div>
              <CustomButton variant="contained" onClick={() => handlePartialExecute()}>
                <Typography noWrap>{t('translations:period.floatButton.newExpense')}</Typography>
              </CustomButton>
            </div>
            <CustomSpacer horizontal size="small" />
            <SearchBar
              displayCard={false}
              search={search}
              onSearch={handleSearchChange}
              onResetSearch={handleResetSearch}
              isSticky={false}
            />
          </Stack>
        </Card>
      </ListSubheader>
      <Stack
        sx={{
          width: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 400,
        }}
      >
        {filteredExpenses.map((expense) => (
          <Button key={expense._id.toString()} onClick={() => handlePartialExecute(expense)}>
            <ExpanseItemSummary expense={expense} hideActions />
          </Button>
        ))}
        {executeModel.open && executeModel.type === ModalTypes.PARTIAL && (
          <PartialExecute
            expense={executeModel.expense as IExpenseSnapshotPopulated}
            onClose={handleCloseExecute}
            predefinedData={{
              amount: expensesModalFile?.amount || 0,
              uuid: expensesModalFile?.uuid,
              businessName: expensesModalFile.businessName,
            }}
            transaction={expensesModalFile}
            onSuccessfulExecute={() => onSuccessfulExecute(expensesModalFile?.uuid as string)}
          />
        )}
        {executeModel.open && executeModel.type === ModalTypes.NEW && (
          <AddEditExpenseSnapshot
            mode={COMPONENT_MODES.ADD}
            onClose={handleCloseExecute}
            onSuccessfulExecute={() => onSuccessfulExecute(expensesModalFile?.uuid as string)}
            predefinedData={{
              amount: expensesModalFile?.amount || 0,
              executeOnDone: true,
              balanceSnapshotRef: expenseBalanceRef,
              uuid: expensesModalFile?.uuid,
              businessName: expensesModalFile.businessName,
            }}
            transaction={expensesModalFile}
          />
        )}
      </Stack>
    </CustomModal>
  );
};
