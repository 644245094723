import { Autocomplete, Card, CardContent, CardHeader, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IExpense, IOverTimeWidget } from '../../../../shared-resources/INTERFACES';
import { LineChart, LineChartProps } from '../../components/Charts/LineChart';
import { useCurrency } from '../../hooks/useCurrency';
import { getDate } from '../../utils/helpers';

const formatOverTimeData = (data: IOverTimeWidget[], selectedExpenses: string[]) => {
  const returnData: LineChartProps['data'] = [];
  // Get all expenses to unique expense array
  const expensesIds: string[] = [];
  const uniqueExpenses: IExpense[] = [];

  data.forEach((item) => {
    item.expensesPerMonth.forEach((expense) => {
      const { expenseRef } = expense;
      if (
        expenseRef &&
        !uniqueExpenses.some((uniqueExpense) => uniqueExpense._id === expenseRef._id)
      ) {
        uniqueExpenses.push(expenseRef);
      }
    });
  });

  data.forEach((item) => {
    item.expensesPerMonth.forEach((expense) => {
      const expenseId = expense.expenseRef?._id.toString();
      if (expenseId && !expensesIds.includes(expenseId)) {
        expensesIds.push(expenseId);
      }
    });
  });
  const periodIds = data.map((item) => item.periodRef._id.toString());
  // For each period, get the expenses
  periodIds.forEach((periodId) => {
    const period = data.find((item) => item.periodRef._id.toString() === periodId);
    if (period) {
      const expenses = period.expensesPerMonth;
      // For each expense, get the amount
      expensesIds.forEach((expenseId) => {
        const expense = expenses.find((item) => item.expenseRef?._id.toString() === expenseId) ?? {
          amount: 0,
          expenseRef: {
            name: uniqueExpenses.find((item) => item._id.toString() === expenseId)?.name,
          },
        };
        if (expense && selectedExpenses.includes(expenseId)) {
          const expenseName = expense.expenseRef?.name;
          if (expenseName) {
            const expenseData = returnData.find((item) => item.id === expenseName);
            if (expenseData) {
              expenseData.data.push({
                x: getDate({
                  date: period.periodRef.startDate,
                  format: 'MM/YYYY',
                }),
                y: expense.amount,
              });
            } else {
              returnData.push({
                id: expenseName,
                data: [
                  {
                    x: getDate({
                      date: period.periodRef.startDate,
                      format: 'MM/YYYY',
                    }),
                    y: expense.amount,
                  },
                ],
              });
            }
          }
        }
      });
    }
  });

  return {
    graphData: returnData,
    expenses: uniqueExpenses,
  };
};

interface Props {
  data?: IOverTimeWidget[];
  isSingleMonth: boolean;
}

export function ExpensesPerMonthWidget(props: Props): React.ReactElement {
  const { data = [], isSingleMonth } = props;
  const [selectedExpenses, setSelectedExpenses] = React.useState<string[]>([]);
  const { expenses, graphData } = formatOverTimeData(data, selectedExpenses);
  const { t } = useTranslation();
  const { formatAmount } = useCurrency();

  const handleChange = (newValue: typeof expenses) => {
    setSelectedExpenses(newValue.map((item) => item._id.toString()));
  };

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        title
        sx={{
          pb: 0,
        }}
      />
      <Stack direction="row" spacing={2} sx={{ px: 2 }}>
        {graphData.map((expense) => {
          const average = expense.data.reduce((acc, curr) => acc + curr.y, 0) / expense.data.length;
          return (
            <Typography
              key={expense.id}
              variant="body2"
              color="text.secondary"
              sx={{
                backgroundColor: (theme) => theme.palette.primary.dark,
                color: (theme) => theme.palette.primary.contrastText,
                p: 1,
                borderRadius: 1,
              }}
            >
              {`${expense.id}: ${formatAmount(average)}`}
            </Typography>
          );
        })}
      </Stack>
      <CardContent>
        <Stack alignItems="center" flexDirection="row">
          <Autocomplete
            size="small"
            fullWidth
            multiple
            id="tags-outlined"
            options={expenses}
            noOptionsText={t('translations:common.noOptions')}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('translations:widgets.expensesPerMonthWidget.selectExpense')}
              />
            )}
          />
        </Stack>
      </CardContent>
      {!isSingleMonth && (
        <CardContent
          sx={{
            height: 400,
            pt: 0,
          }}
        >
          {selectedExpenses.length > 0 ? (
            <LineChart data={graphData} />
          ) : (
            <Typography variant="body2" color="text.secondary" align="center">
              {t('translations:widgets.expensesPerMonthWidget.selectExpenseDescription')}
            </Typography>
          )}
        </CardContent>
      )}
    </Card>
  );
}
