import { CardHeader, Stack, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { CompanyTypes } from '../../../../shared-resources/INTERFACES'
import CustomButton from '../../components/CustomButton'
import { CustomCardContent } from '../../components/CustomCardContent'
import { CustomGenericSelection } from '../../components/CustomGenericSelection'
import CustomModal from '../../components/CustomModal'
import CustomSpacer from '../../components/CustomSpacer'
import { i18n } from '../../i18n/i18n'
import { postScraperSecret } from '../../utils/apiUtils'
import { COMPONENT_MODES } from '../../utils/constants'
import { getComponentModeTitle } from '../../utils/helpers'

type Props = {
  onClose: (closeOptions: { fetchData: boolean }) => void
}

const validationSchema = Yup.object().shape({
  companyType: Yup.string().required(i18n.t('translations:errors.required')),
  p: Yup.string().required(i18n.t('translations:errors.required')),
  u: Yup.string().required(i18n.t('translations:errors.required')),
})

const OPTIONS = Object.values(CompanyTypes).map((element) => ({
  label: element,
  value: element,
}))

export function AddScraperSecret({ onClose }: Props): React.ReactElement {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      companyType: CompanyTypes.max,
      u: '',
      p: '',
    },
    validationSchema,
    onSubmit,
  })

  async function onSubmit() {
    setIsLoading(true)
    try {
      await postScraperSecret({
        companyType: formik.values.companyType,
        u: formik.values.u,
        p: formik.values.p,
      })
      onClose({
        fetchData: true,
      })
    } catch (error) {
      //
    }
    setIsLoading(false)
  }

  return (
    <CustomModal
      onClose={() =>
        onClose({
          fetchData: false,
        })
      }
    >
      <form
        style={{
          height: '100%',
        }}
        onSubmit={formik.handleSubmit}
      >
        <Stack flexDirection="column" justifyContent="space-between" height="100%" overflow="auto">
          <CustomCardContent>
            <CardHeader title={getComponentModeTitle(COMPONENT_MODES.ADD)} />
            <TextField
              size="small"
              fullWidth
              name="u"
              label={t('translations:common.userName')}
              value={formik.values.u}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.u && formik.errors.u)}
              helperText={formik.touched.u && formik.errors.u}
              autoFocus
            />
            <CustomSpacer vertical size="medium" />
            <TextField
              size="small"
              fullWidth
              name="p"
              label={t('translations:common.password')}
              value={formik.values.p}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.p && formik.errors.p)}
              helperText={formik.touched.p && formik.errors.p}
            />
            <CustomSpacer vertical size="medium" />
            <CustomGenericSelection
              onChange={(value) => formik.setFieldValue('companyType', value)}
              selected={formik.values.companyType}
              options={OPTIONS}
              fullWidth
            />
            <CustomSpacer vertical size="large" />
          </CustomCardContent>
          <CustomButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            isLoading={isLoading}
          >
            {getComponentModeTitle(COMPONENT_MODES.ADD)}
          </CustomButton>
        </Stack>
      </form>
    </CustomModal>
  )
}
