import { Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import CustomTypography from 'components/CustomTypography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { EXPANSE_TYPES } from '../../../../../shared-resources/CONSTANTS'
import { IExpenseSnapshotPopulated, INote } from '../../../../../shared-resources/INTERFACES'
import CustomActionMoreButton from '../../../components/ActionMoreButton'
import CustomIcon from '../../../components/CustomIcon'
import CustomSpacer from '../../../components/CustomSpacer'
import { CustomTag } from '../../../components/CustomTag'
import ExecuteBadge from '../../../components/ExecuteBadge'
import { FileIcon } from '../../../components/FileIcon'
import NotesMenu from '../../../components/NotesMenu'
import { EXPENSE_ITEM_ACTIONS } from '../../../utils/buttonActions'
import { ACTION_ITEMS } from '../../../utils/constants'
import { getDate } from '../../../utils/helpers'
import { COMMON_ICONS, EXPENSE_TYPE_EMOJIS } from '../../../utils/icons'
import { ExpensesIndicator } from '../PeriodSummary/Summary/ExpensesIndicator'
import { ExpanseAmountInfo } from './ExpanseAmountInfo'
import { ExpanseItemContext } from './ExpanseItemContext'

type Props = {
  expense: IExpenseSnapshotPopulated
  handleAction?: ({
    actionType,
    selectedExpense,
  }: {
    actionType: ACTION_ITEMS
    selectedExpense: IExpenseSnapshotPopulated
  }) => void
  hideActions?: boolean
  searchValue?: string
}

const remappingActions = (isFullyExecuted: boolean) => {
  const remappedActions = EXPENSE_ITEM_ACTIONS.map((action) => {
    if (action.action === ACTION_ITEMS.EXECUTE && isFullyExecuted) {
      return {
        ...action,
        disabled: true,
      }
    }
    if (action.action === ACTION_ITEMS.CLOSE_EXPENSE && isFullyExecuted) {
      return {
        ...action,
        disabled: true,
      }
    }
    return action
  })
  return remappedActions
}

export default function ExpanseItemSummary(props: Props): React.ReactElement {
  const { hideActions, handleAction, expense, searchValue, ...rest } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { isFullyExecuted, fullExecutionIndication } = React.useContext(ExpanseItemContext)
  const opacity = isFullyExecuted && fullExecutionIndication ? 0.5 : 1
  const actions = remappingActions(isFullyExecuted)

  return (
    <Grid
      container
      {...rest}
      alignItems="center"
      spacing={isMobile ? 1 : 0}
      sx={{
        marginInlineEnd: 2,
      }}
    >
      <Grid item sm={3} xs={12}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" width="100%">
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            width="100%"
            sx={{
              opacity,
            }}
          >
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <Typography>
                {EXPENSE_TYPE_EMOJIS?.[expense?.expenseRef?.type || EXPANSE_TYPES.OTHER]}
              </Typography>
              <CustomSpacer horizontal size="small" />
              <CustomTypography searchValue={searchValue} noWrap>
                {expense?.expenseRef?.name}
              </CustomTypography>
              {expense.uuid && (
                <>
                  <CustomSpacer horizontal size="small" />
                  <FileIcon />
                </>
              )}
              {expense?.expenseRef?.isPeriodical && (
                <>
                  <CustomSpacer horizontal size="small" />
                  <CustomIcon icon={COMMON_ICONS.PERIODICAL} color={theme.palette.success.light} />
                </>
              )}
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <Typography variant="caption">{t('translations:common.createdAt')}:</Typography>
              <CustomSpacer horizontal size="small" />
              <Typography variant="caption">{getDate({ date: expense.createdAt })}</Typography>
            </Stack>
          </Stack>
          {isMobile && !hideActions && (
            <Stack flexDirection="row" alignItems="center" justifyContent="flex-end">
              <CustomTag
                sx={{
                  opacity: expense.executionUpdatesRef.length === 0 ? 0.3 : 1,
                }}
              >
                {expense.executionUpdatesRef.length}
              </CustomTag>
              <CustomActionMoreButton
                actions={actions}
                onClick={(action) =>
                  handleAction?.({
                    actionType: action,
                    selectedExpense: expense,
                  })
                }
              />
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid
        item
        sm={2}
        xs={12}
        justifyContent="flex-start"
        sx={{
          opacity,
        }}
      >
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <CustomTag
            searchValue={searchValue}
            backgroundColor={expense?.balanceSnapshotRef?.balanceRef?.color}
          >
            {expense?.balanceSnapshotRef?.balanceRef?.name || t('translations:common.unknown')}
          </CustomTag>
        </Stack>
      </Grid>
      <Grid
        item
        sm={4}
        xs={12}
        sx={{
          opacity,
        }}
      >
        <ExpanseAmountInfo searchValue={searchValue} expense={expense} />
      </Grid>
      <Grid item sm={1} xs={1} justifyContent="flex-end">
        {expense.averageExpenseAmount && (
          <ExpensesIndicator
            totalExpenses={expense.amount}
            totalAverageExpenses={expense.averageExpenseAmount}
            amountThreshold={50}
          />
        )}
      </Grid>
      <Grid
        item
        sm={2}
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: isMobile ? 'space-between' : 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            opacity,
          }}
        >
          <ExecuteBadge expense={expense}>
            {expense.duoDate && (
              <Stack direction="column" justifyContent="flex-start" alignItems="center">
                <Typography variant="caption">{t('translations:common.duoDate')}:</Typography>
                <CustomSpacer horizontal size="small" />
                <Typography variant="caption">
                  {getDate({ date: expense.duoDate, format: 'DD/MM/YYYY' })}
                </Typography>
              </Stack>
            )}
          </ExecuteBadge>
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: theme.spacing(8),
          }}
        >
          <NotesMenu notes={expense.notes as INote[]} />
        </Stack>
        {!isMobile && !hideActions && (
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-end">
            <CustomTag
              sx={{
                opacity: expense.executionUpdatesRef.length === 0 ? 0.3 : 1,
              }}
            >
              {expense.executionUpdatesRef.length}
            </CustomTag>
            <CustomActionMoreButton
              actions={actions}
              onClick={(action) =>
                handleAction?.({
                  actionType: action,
                  selectedExpense: expense,
                })
              }
            />
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}
