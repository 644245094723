import { Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { useCurrency } from 'hooks/useCurrency';
import { useTranslation } from 'react-i18next';
import { COMMON_ICONS } from 'utils/icons';
import CustomIcon from '../../../../components/CustomIcon';
import CustomLoading from '../../../../components/CustomLoading';
import { i18n } from '../../../../i18n/i18n';

const LARGE_OFFSET = 0.045; // 4.5%
const MEDIUM_OFFSET = 0.03; // 3%
const SMALL_OFFSET = 0.015; // 1.5%

enum Classification {
  LARGE = 'Large',
  MEDIUM = 'Medium',
  SMALL = 'Small',
  VERY_SMALL = 'Very Small',
}

const enum Trend {
  HIGHER = 'Higher',
  LOWER = 'Lower',
  EQUAL = 'Equal',
}

interface Props {
  totalExpenses: number;
  loading?: boolean;
  totalAverageExpenses: number;
  amountThreshold?: number;
}

const calculateDeviation = ({ totalExpenses, totalAverageExpenses }: Omit<Props, 'loading'>) => {
  const deviation = Math.abs(totalExpenses - totalAverageExpenses);

  if (deviation >= LARGE_OFFSET * totalAverageExpenses) {
    return { deviation, classification: Classification.LARGE };
  }
  if (deviation >= MEDIUM_OFFSET * totalAverageExpenses) {
    return { deviation, classification: Classification.MEDIUM };
  }
  if (deviation >= SMALL_OFFSET * totalAverageExpenses) {
    return { deviation, classification: Classification.SMALL };
  }
  return { deviation, classification: Classification.VERY_SMALL };
};

interface GenerateDeviationNotice {
  deviation: string | number;
  deviationPercentage: number;
  trend: Trend;
}

const generateDeviationNotice = ({
  deviation,
  deviationPercentage,
  trend,
}: GenerateDeviationNotice) => {
  switch (trend) {
    case Trend.HIGHER:
      return i18n.t('translations:deviationAlert.higher', {
        deviation,
        deviationPercentage: deviationPercentage.toFixed(2),
      });
    case Trend.LOWER:
      return i18n.t('translations:deviationAlert.lower', {
        deviation,
        deviationPercentage: deviationPercentage.toFixed(2),
      });
    default:
      return i18n.t('translations:deviationAlert.equal');
  }
};

export const ExpensesIndicator = (props: Props) => {
  const { amountThreshold, totalExpenses, loading, totalAverageExpenses } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const { classification, deviation } = calculateDeviation({
    totalExpenses,
    totalAverageExpenses,
  });
  const { formatAmount } = useCurrency();
  const formattedDeviation = formatAmount(deviation);
  const deviationPercentage = (deviation / totalAverageExpenses) * 100;
  const trend =
    // eslint-disable-next-line no-nested-ternary
    totalExpenses === totalAverageExpenses
      ? Trend.EQUAL
      : totalExpenses > totalAverageExpenses
      ? Trend.HIGHER
      : Trend.LOWER;
  const icon =
    // eslint-disable-next-line no-nested-ternary
    trend === Trend.EQUAL
      ? COMMON_ICONS.EQUAL_TREND
      : trend === Trend.HIGHER
      ? COMMON_ICONS.TREND_UP
      : COMMON_ICONS.TREND_DOWN;

  return amountThreshold && deviation < amountThreshold ? null : (
    <Tooltip
      title={
        <Typography align="center" variant="body2">
          {loading
            ? t('translations:common.loading')
            : generateDeviationNotice({
                deviation: formattedDeviation,
                deviationPercentage,
                trend,
              })}
        </Typography>
      }
    >
      <div>
        {loading ? (
          <CustomLoading size={10} isCircular />
        ) : (
          <CustomIcon
            icon={icon}
            style={{
              color:
                // eslint-disable-next-line no-nested-ternary
                classification === Classification.LARGE
                  ? theme.palette.error.main
                  : // eslint-disable-next-line no-nested-ternary
                  classification === Classification.MEDIUM
                  ? theme.palette.warning.main
                  : classification === Classification.SMALL
                  ? theme.palette.warning.light
                  : theme.palette.success.light,
            }}
          />
        )}
      </div>
    </Tooltip>
  );
};
