import Alert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import axios, { AxiosError, AxiosResponse } from 'axios'
import * as React from 'react'
import { i18n } from '../i18n/i18n'
import { FLOAT_PADDING } from '../utils/constants'

const AUTO_HIDE_DURATION = {
  SUCCESS: 1000,
  ERROR: 3000,
}
interface SnackConfigI {
  message: string
  severity: AlertProps['severity']
  open: boolean
  autoHideDuration?: number
}

export const AxiosInterceptors = () => {
  const [config, setConfig] = React.useState<SnackConfigI>()

  React.useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => {
      if (response.config.method === 'get') return response

      setConfig({
        message: i18n.t('translations:snackbar.success'),
        severity: 'success',
        open: true,
        autoHideDuration: AUTO_HIDE_DURATION.SUCCESS,
      })
      return response
    }

    const errInterceptor = (error: Error | AxiosError) => {
      setConfig({
        message: i18n.t('translations:snackbar.error'),
        severity: 'error',
        open: true,
        autoHideDuration: AUTO_HIDE_DURATION.ERROR,
      })
      return error
    }

    const responseInterceptor = axios.interceptors.response.use(resInterceptor, errInterceptor)

    return () => {
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [])

  const handleClose = () => {
    setConfig(undefined)
  }

  return !config ? null : (
    <Snackbar
      open={config?.open}
      autoHideDuration={config?.autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ bottom: { xs: FLOAT_PADDING + 70, sm: FLOAT_PADDING } }}
    >
      <Alert
        onClose={handleClose}
        severity={config?.severity}
        sx={{ width: '100%' }}
        variant="filled"
      >
        {config?.message}
      </Alert>
    </Snackbar>
  )
}
