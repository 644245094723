import { Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CRUD_OPERATION } from '../../../../shared-resources/CONSTANTS'
import { IEventPopulated } from '../../../../shared-resources/INTERFACES'
import CustomSpacer from '../../components/CustomSpacer'
import { CustomTag } from '../../components/CustomTag'
import { User } from '../../components/User'
import { getDate } from '../../utils/helpers'

interface Props {
  event: IEventPopulated
}

const determineEntityName = (entitySnapshot: Props['event']['entityData']['entitySnapshot']) => {
  if (entitySnapshot instanceof Object) {
    if ('name' in entitySnapshot) {
      return entitySnapshot.name
    }

    if ('balanceRef' in entitySnapshot) {
      return entitySnapshot.balanceRef.name
    }

    if ('expenseRef' in entitySnapshot) {
      return entitySnapshot?.expenseRef?.name
    }
  }
  return null
}

export const EntityData = (props: Props) => {
  const { event } = props
  const { operation, entityData, userRef } = event
  const { entitySnapshot, entityType } = entityData
  const entityName = determineEntityName(entitySnapshot)
  const { t } = useTranslation()
  const { palette } = useTheme()
  const operationColor =
    operation === CRUD_OPERATION.DELETE ? palette.error.main : palette.warning.main

  return (
    <Stack direction="column" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <CustomTag backgroundColor={operationColor}>
          {t(`translations:operations.${operation}`)}
        </CustomTag>
        <CustomSpacer horizontal size="small" />
        <Typography variant="caption">
          {getDate({
            date: event?.timestamp,
            format: 'DD/MM/YYYY, HH:mm',
          })}
        </Typography>
      </Stack>
      <CustomSpacer vertical size="small" />
      <User user={userRef} />
      <CustomSpacer vertical size="small" />
      <Typography variant="body2" color="text.secondary">
        {t('translations:history.entityName')}: {entityName}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {t('translations:history.entityType')}: {t(`translations:entityNames.${entityType}`)}
      </Typography>
    </Stack>
  )
}
