import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, darken } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Badge from '@mui/material/Badge';
import CustomButton from 'components/CustomButton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NOTE_MODELS } from '../../../../../shared-resources/CONSTANTS';
import { IExpenseSnapshotPopulated } from '../../../../../shared-resources/INTERFACES';
import CustomDialog from '../../../components/CustomDialog';
import {
  deleteExpenseSnapshot,
  moveExecutionUpdate,
  postExecutionUpdate,
  putExpenseSnapshot,
} from '../../../utils/apiUtils';
import { ACTION_ITEMS, COMPONENT_MODES } from '../../../utils/constants';
import { AddEditExpenseSnapshot } from '../../AddEditExpenseSnapshot/AddEditExpenseSnapshot';
import { AddEditNote } from '../../AddEditNote/AddEditNote';
import { ExpanseItemContext } from './ExpanseItemContext';
import ExpanseItemDetails from './ExpanseItemDetails';
import ExpanseItemSummary from './ExpanseItemSummary';
import { PartialExecute } from './PartialExecute';

type Props = {
  expense: IExpenseSnapshotPopulated;
  fullExecutionIndication?: boolean;
  searchValue?: string;
  disableActions?: boolean;
  executeUpdateId?: string;
  onMoved?: () => void;
};

type IInit = {
  actionType?: ACTION_ITEMS;
  open: boolean;
  expense: IExpenseSnapshotPopulated;
};

const openModalInit: IInit = {
  open: false,
  expense: {} as IExpenseSnapshotPopulated,
};

export const ExpenseItem = (props: Props) => {
  const {
    fullExecutionIndication = true,
    expense,
    searchValue,
    disableActions,
    executeUpdateId,
    onMoved,
  } = props;
  const [openModal, setOpenModal] = React.useState(openModalInit);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const thereIsNoteInExecution = expense.executionUpdatesRef.some(
    (execution) => execution?.notes?.length > 0
  );
  const displayNoteBadge = thereIsNoteInExecution && !expanded;

  React.useEffect(() => {
    if (searchValue) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [searchValue]);

  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const handleSelect = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!executeUpdateId) return;
    event.stopPropagation();

    setIsLoading(true);
    try {
      await moveExecutionUpdate({
        id: executeUpdateId,
        newExpenseSnapshotRef: expense._id.toString(),
      });
      onMoved?.();
    } catch (error) {
      //
    }
    setIsLoading(false);
  };

  const amountExecuted = expense.executionUpdatesRef.reduce((acc, curr) => acc + curr.amount, 0);
  const isFullyExecuted = expense.amount === amountExecuted;

  const handleAction = ({
    actionType,
    selectedExpense,
  }: {
    actionType: ACTION_ITEMS;
    selectedExpense: IExpenseSnapshotPopulated;
  }) => {
    setOpenModal({
      open: true,
      expense: selectedExpense,
      actionType,
    });
  };

  const handleModalClose = () => {
    setOpenModal(openModalInit);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteExpenseSnapshot(openModal.expense._id.toString());
      handleModalClose();
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const handleExecuteExpense = async () => {
    setIsLoading(true);
    const executedAmount = expense?.executionUpdatesRef?.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    const amount = openModal.expense.amount - executedAmount;

    try {
      const newExecutionUpdate = {
        amount: Number(amount),
        expenseSnapshotRef: expense._id.toString(),
      };
      await postExecutionUpdate(newExecutionUpdate);
      handleModalClose();
    } catch (resError) {
      //
    } finally {
      setIsLoading(false);

      if (expanded) {
        handleExpand();
      }
    }
  };

  const handleCloseExpense = async () => {
    // set expense amount to the executed amount
    setIsLoading(true);
    const executedAmount = expense?.executionUpdatesRef?.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    try {
      await putExpenseSnapshot({
        newExpenseSnapshot: { amount: executedAmount },
        expenseSnapshotId: expense._id.toString(),
      });
      handleModalClose();
    } catch (resError) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ExpanseItemContext.Provider
      value={{
        handleAction,
        isFullyExecuted,
        expense,
        fullExecutionIndication,
      }}
    >
      <Accordion expanded={expanded} onChange={handleExpand} disableGutters>
        <AccordionSummary
          expandIcon={
            <Badge
              color="primary"
              variant="dot"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              invisible={!displayNoteBadge}
            >
              <ExpandMoreIcon />
            </Badge>
          }
          sx={{
            backgroundColor: (theme) =>
              isFullyExecuted
                ? darken(theme.palette.background.default, 0.1)
                : theme.palette.background.default,
            '&:hover': {
              opacity: 0.7,
            },
            '> div': {
              my: 0.5,
            },
          }}
        >
          <ExpanseItemSummary
            searchValue={searchValue}
            expense={expense}
            handleAction={handleAction}
            hideActions={disableActions}
          />
          {executeUpdateId && (
            <CustomButton
              onClick={handleSelect}
              color="primary"
              isLoading={isLoading}
              disabled={isLoading}
            >
              {t('translations:common.select')}
            </CustomButton>
          )}
        </AccordionSummary>
        {expanded && (
          <AccordionDetails sx={{ bgcolor: 'background.default' }}>
            <ExpanseItemDetails expense={expense} searchValue={searchValue} />
          </AccordionDetails>
        )}
      </Accordion>
      {openModal.open && openModal.actionType === ACTION_ITEMS.EDIT && (
        <AddEditExpenseSnapshot
          mode={COMPONENT_MODES.EDIT}
          expenseId={openModal.expense?._id.toString()}
          onClose={() => setOpenModal(openModalInit)}
          onSuccessfulExecute={() => setOpenModal(openModalInit)}
        />
      )}
      {openModal.open && openModal.actionType === ACTION_ITEMS.ADD_NOTE && (
        <AddEditNote
          onClose={() => setOpenModal(openModalInit)}
          documentRef={openModal.expense._id.toString()}
          modalName={NOTE_MODELS.EXPENSE_SNAPSHOT}
        />
      )}
      {openModal.open && openModal.actionType === ACTION_ITEMS.PARTIAL_EXECUTE && (
        <PartialExecute
          expense={openModal.expense}
          onClose={handleModalClose}
          onSuccessfulExecute={expanded ? handleExpand : undefined}
        />
      )}
      {openModal.open && openModal.actionType === ACTION_ITEMS.DELETE && (
        <CustomDialog
          onConfirm={handleDelete}
          onClose={handleModalClose}
          title={t('translations:expenses.actions.deleteExpanse')}
          description={t('translations:expenses.actions.deleteExpanseDescription')}
          isLoading={isLoading}
        />
      )}
      {openModal.open && openModal.actionType === ACTION_ITEMS.EXECUTE && (
        <CustomDialog
          onConfirm={handleExecuteExpense}
          onClose={handleModalClose}
          title={t('translations:expenses.actions.execute')}
          description={t('translations:expenses.actions.executeDescription')}
          isLoading={isLoading}
        />
      )}
      {openModal.open && openModal.actionType === ACTION_ITEMS.CLOSE_EXPENSE && (
        <CustomDialog
          onConfirm={handleCloseExpense}
          onClose={handleModalClose}
          title={t('translations:expenses.actions.closeExpense')}
          description={t('translations:expenses.actions.closeExpenseDescription')}
          isLoading={isLoading}
        />
      )}
    </ExpanseItemContext.Provider>
  );
};

export const ExpanseItemMemo = React.memo(ExpenseItem);
