import { useSearchQueryParam } from 'hooks/useSearchQueryParam';
import { orderBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from 'views/Period/Expanses/SearchBar';
import { ExcludedBusinessName } from '../../../../../shared-resources/INTERFACES';
import CustomDialog from '../../../components/CustomDialog';
import CustomLoadingGuard from '../../../components/CustomLoadingGuard';
import CustomSpacer from '../../../components/CustomSpacer';
import { useDebounce } from '../../../hooks/useDebounce';
import { deleteExcludedBusinessName, getExcludedBusinessNames } from '../../../utils/apiUtils';
import { ManagementPageHeader } from '../ManagementPageHeader';
import { ExcludedBusinessNamesItem } from './ExcludedBusinessNamesItem';

export default function ExcludedBusinessNames(): React.ReactElement {
  const [excludedBusinessNames, setExcludedBusinessNames] = React.useState<ExcludedBusinessName[]>(
    []
  );
  const [loading, setLoading] = React.useState(true);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState({
    idToDelete: '',
    open: false,
  });
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { handleSearchChange, search, handleResetSearch } = useSearchQueryParam({
    queryKey: 's',
  });
  const debounceSearch = useDebounce(search, 500);
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchExcludedBusinessNames();
  }, []);

  const fetchExcludedBusinessNames = async (showLoading = true) => {
    setLoading(showLoading);
    try {
      const data = await getExcludedBusinessNames();
      setExcludedBusinessNames(data);
    } catch (error) {
      //
    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    setIsDeleting(true);
    try {
      await deleteExcludedBusinessName(id);
      fetchExcludedBusinessNames(false);
      closeDeleteModal();
    } catch (error) {
      //
    }
    setIsDeleting(false);
  };

  const openDeleteModal = (id: string) => {
    setDeleteConfirmation({
      idToDelete: id,
      open: true,
    });
  };

  const closeDeleteModal = () => {
    setDeleteConfirmation({
      idToDelete: '',
      open: false,
    });
  };

  return (
    <div>
      <ManagementPageHeader header={t('translations:excludedBusinessName.title')} />
      <CustomSpacer vertical size="medium" />
      <SearchBar search={search} onSearch={handleSearchChange} onResetSearch={handleResetSearch} />
      <CustomSpacer vertical size="medium" />
      <CustomLoadingGuard loading={loading}>
        {orderBy(
          matchSorter(excludedBusinessNames, debounceSearch, {
            keys: ['name'],
          }),
          'createdAt',
          'desc'
        ).map((excludedBusinessName) => (
          <>
            <ExcludedBusinessNamesItem
              excludedBusinessName={excludedBusinessName}
              handleDelete={openDeleteModal}
            />
            <CustomSpacer vertical size="medium" />
          </>
        ))}
      </CustomLoadingGuard>
      {deleteConfirmation.open && (
        <CustomDialog
          onClose={closeDeleteModal}
          onConfirm={() => handleDelete(deleteConfirmation.idToDelete)}
          title={t('translations:common.delete')}
          description={t('translations:common.deleteGenericDesc')}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
}
