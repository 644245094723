import * as React from 'react'
import CustomLoading from './CustomLoading'

interface IProps {
  loading: boolean
  children: React.ReactNode
}
export default function CustomLoadingGuard(props: IProps): React.ReactElement {
  const { children, loading } = props
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return loading ? <CustomLoading /> : <>{children}</>
}
