import { useAppSelector } from 'hooks/useReactRedux'
import { useEffect, useState } from 'react'
import { getAverageExpenses } from 'utils/apiUtils'
import { IAverageExpensesAmount } from '../../../../../shared-resources/INTERFACES'

export const useAverageExpensesAmount = () => {
  const { expenses } = useAppSelector((state) => state.expenses)
  const [averageExpensesAmount, setAverageExpensesAmount] = useState<IAverageExpensesAmount[]>()
  const expenseIds = expenses.map((expense) => expense.expenseRef?._id).filter((id) => id)

  useEffect(() => {
    if (!expenseIds.length) return

    const fetchData = async () => {
      try {
        const { averages } = await getAverageExpenses(expenseIds as string[])
        setAverageExpensesAmount(averages)
      } catch (error) {
        setAverageExpensesAmount([])
      }
    }

    fetchData()
  }, [expenses])

  return { averageExpensesAmount }
}
