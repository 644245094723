import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { MainContext } from '../context/MainContext'

type Props = {
  children: React.ReactElement
}

export default function Protected(props: Props): React.ReactElement {
  const { children } = props
  const { isLoggedIn } = React.useContext(MainContext)
  if (!isLoggedIn) {
    return <Navigate to="/" replace />
  }

  return children
}
