import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Button, FormHelperText, Menu, MenuItem, Stack, useTheme } from '@mui/material'
import { CustomTag } from 'components/CustomTag'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BALANCE_TYPES, COMMON_STATUES } from '../../../../../shared-resources/CONSTANTS'
import { IPopulatedBalance } from '../../../../../shared-resources/INTERFACES'
import CustomIcon from '../../../components/CustomIcon'
import CustomSpacer from '../../../components/CustomSpacer'
import { getBalancesByHouseholdId } from '../../../utils/apiUtils'
import { BALANCE_TYPE_ICONS } from '../../../utils/icons'

type Props = {
  onChange: (balanceRef: string) => void
  selectedBalance?: string
  error?: boolean
  helperText?: string | false
  disabled?: boolean
  filterBalances?: string[]
}

interface BalanceItemProps {
  name: string
  type: BALANCE_TYPES
}

const BalanceItem = ({ name, type }: BalanceItemProps) => (
  <Stack direction="row" alignItems="center">
    <CustomIcon icon={BALANCE_TYPE_ICONS[type]} />
    <CustomSpacer horizontal size="small" />
    {name}
  </Stack>
)

export function BalanceSelection(props: Props): React.ReactElement {
  const { onChange, selectedBalance, error, helperText, disabled, filterBalances } = props
  const [balances, setBalances] = React.useState<IPopulatedBalance[]>([])
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const selected = balances.find((b) => b._id === selectedBalance)
  const { palette } = useTheme()
  const { warning } = palette

  React.useEffect(() => {
    fetchBalances()
  }, [])

  const fetchBalances = async () => {
    try {
      const data = await getBalancesByHouseholdId()
      const filteredBalances = data.filter((balance) => !filterBalances?.includes(balance._id))
      setBalances(filteredBalances)
    } catch (resError) {
      //
    }
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (balanceRef: IPopulatedBalance) => {
    onChange(balanceRef._id.toString())
    handleClose()
  }

  return (
    <>
      <Button
        disabled={balances.length === 0 || disabled}
        variant="outlined"
        onClick={handleOpen}
        fullWidth
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderLeft: (theme) => `5px solid ${selected?.color ?? theme.palette.primary.main}`,
          '&:hover': {
            borderLeft: (theme) => `5px solid ${selected?.color ?? theme.palette.primary.main}`,
          },
        }}
      >
        {selected?.name ? (
          <BalanceItem name={selected.name} type={selected.type} />
        ) : (
          t('translations:expenses.addEditExpense.selectBalance')
        )}
        <CustomSpacer horizontal size="medium" />
        <CustomIcon icon={faChevronDown} />
      </Button>
      <FormHelperText error={error}>{helperText}</FormHelperText>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {balances.map((balance) => {
          const isInActive = balance.status === COMMON_STATUES.INACTIVE

          return (
            <MenuItem
              disabled={isInActive}
              key={balance._id.toString()}
              onClick={() => handleChange(balance)}
              sx={{
                borderLeft: `5px solid ${balance.color}`,
              }}
              selected={selected?._id.toString() === balance._id.toString()}
            >
              <Stack direction="column">
                {isInActive && (
                  <CustomTag backgroundColor={warning.main}>
                    {t('translations:common.inactive')}
                  </CustomTag>
                )}
                <BalanceItem name={balance.name} type={balance.type} />
              </Stack>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
