import { amber, deepOrange, grey } from '@mui/material/colors';

export const convertToRgbaBackground = (rgbValues: string, opacity = 0.5): string => {
  return rgbValues.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
};

export const getDesignTokens = (mode: 'dark' | 'light') => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: amber,
          divider: amber[200],
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: deepOrange,
          divider: deepOrange[700],
          background: {
            default: deepOrange[900],
            paper: deepOrange[900],
          },
          text: {
            primary: '#fff',
            secondary: grey[500],
          },
        }),
  },
});
