import { Stack } from '@mui/material';
import { CustomGenericSelection } from 'components/CustomGenericSelection';
import { useLocalStorageState } from 'hooks/useLocalStorageState';
import { useAppSelector } from 'hooks/useReactRedux';
import { orderBy } from 'lodash';
import * as React from 'react';
import { i18n } from 'src/i18n/i18n';
import { LocalStorageKeys } from 'src/types/localStorageKeys';
import { getDate } from 'utils/helpers';
import { WIDGETS } from '../../../../shared-resources/BASE_ENDPOINTS';
import { OvertimeWidgetTimeUnit } from '../../../../shared-resources/CONSTANTS';
import { IOverallWidget } from '../../../../shared-resources/INTERFACES';
import CustomLoadingGuard from '../../components/CustomLoadingGuard';
import CustomSpacer from '../../components/CustomSpacer';
import { getWidgetData } from '../../utils/apiUtils';
import { AverageWidgetLists } from './AverageWidget/AverageWidgetLists';
import { AverageWidgetTopBar } from './AverageWidget/AverageWidgetTopBar';
import { ExpensesPerMonthWidget } from './ExpensesPerMonthWidget';
import { OverTimeWidget } from './OverTimeWidget';

const TIME_UNITS = [
  {
    label: i18n.t(`translations:dateAndTime.${OvertimeWidgetTimeUnit.LAST_6_MONTHS}`),
    value: OvertimeWidgetTimeUnit.LAST_6_MONTHS,
  },
  {
    label: i18n.t(`translations:dateAndTime.${OvertimeWidgetTimeUnit.LAST_12_MONTHS}`),
    value: OvertimeWidgetTimeUnit.LAST_12_MONTHS,
  },
  {
    label: i18n.t(`translations:dateAndTime.${OvertimeWidgetTimeUnit.ALL_TIME}`),
    value: OvertimeWidgetTimeUnit.ALL_TIME,
  },
];

export function OverallWidget(): React.ReactElement {
  const [data, setData] = React.useState<IOverallWidget>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { loading: loadingPeriods, periods } = useAppSelector((state) => state.allPeriod);
  const [timeUnit, setTimeUnit] = useLocalStorageState<OvertimeWidgetTimeUnit | string>(
    LocalStorageKeys.DASHBOARD_DATE_FILTER,
    OvertimeWidgetTimeUnit.LAST_12_MONTHS
  );
  const isSingleMonth = !Object.values(OvertimeWidgetTimeUnit).includes(
    timeUnit as OvertimeWidgetTimeUnit
  );

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getWidgetData({
          timeUnit,
          widgetName: WIDGETS.OVERALL_WIDGET,
        });
        setData(response);
      } catch (error) {
        //
      }
      setLoading(false);
    };

    if (!timeUnit) return;
    fetchData();
  }, [timeUnit]);

  const sortedPeriods = orderBy(periods, ['startDate'], 'desc').map((p) => ({
    label: getDate({ date: p.startDate, format: 'MM/YYYY' }),
    value: p._id,
  }));

  return (
    <CustomLoadingGuard loading={loading}>
      <Stack direction="row" spacing={1}>
        <CustomGenericSelection
          options={TIME_UNITS}
          onChange={(value) => setTimeUnit(value)}
          selected={timeUnit}
        />
        <CustomGenericSelection
          options={sortedPeriods}
          onChange={(value) => setTimeUnit(value)}
          selected={timeUnit}
          disabled={loadingPeriods || !periods.length}
        />
      </Stack>
      <CustomSpacer vertical size="medium" />
      <AverageWidgetTopBar data={data?.averageWidget} />
      <CustomSpacer vertical size="medium" />
      {!isSingleMonth && <OverTimeWidget data={data?.overTimeWidget} />}
      <CustomSpacer vertical size="medium" />
      <AverageWidgetLists data={data?.averageWidget} />
      <CustomSpacer vertical size="medium" />
      <ExpensesPerMonthWidget data={data?.overTimeWidget} isSingleMonth={isSingleMonth} />
    </CustomLoadingGuard>
  );
}
