import { TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { IHousehold } from '../../../../../shared-resources/INTERFACES'
import CustomButton from '../../../components/CustomButton'
import { CustomCardContent } from '../../../components/CustomCardContent'
import CustomModal from '../../../components/CustomModal'
import { CustomSignoutButton } from '../../../components/CustomSignoutButton'
import CustomSpacer from '../../../components/CustomSpacer'
import { i18n } from '../../../i18n/i18n'
import { postHousehold, putHousehold } from '../../../utils/apiUtils'
import { COMPONENT_MODES } from '../../../utils/constants'
import { getComponentModeTitle } from '../../../utils/helpers'

interface AddEditBalanceProps {
  mode: COMPONENT_MODES
  household?: IHousehold
  isInInitialState?: boolean
  onClose: () => void
  onSuccess: () => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('translations:errors.required')),
})

export default function AddEditHousehold(props: AddEditBalanceProps): React.ReactElement {
  const { mode, household, onClose, onSuccess, isInInitialState } = props
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const formik = useFormik({
    initialValues: {
      name: household?.name || '',
    } as IHousehold,
    validationSchema,
    onSubmit,
  })

  const postBalance = async (newHousehold: IHousehold) => {
    setLoading(true)
    try {
      await postHousehold(newHousehold)
      // on success
      onSuccess?.()
    } catch (error) {
      //
    }
    setLoading(false)
  }

  const putBalance = async (newHousehold: IHousehold) => {
    setLoading(true)
    if (!household?._id) return
    try {
      await putHousehold({
        name: newHousehold.name,
        id: household?._id,
      })
      onSuccess?.()
    } catch (error) {
      //
    }
    setLoading(false)
  }

  function onSubmit() {
    if (mode === COMPONENT_MODES.ADD) {
      postBalance(formik.values)
    }
    if (mode === COMPONENT_MODES.EDIT) {
      putBalance(formik.values)
    }
  }

  return (
    <CustomModal onClose={onClose}>
      <CustomCardContent>
        <Typography variant="h4">{getComponentModeTitle(mode)}</Typography>
        {isInInitialState && <Typography>{t('translations:others.householdInitial')}</Typography>}
        <CustomSpacer vertical size="medium" />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            size="small"
            fullWidth
            id="name"
            name="name"
            label={t('translations:common.description')}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <CustomSpacer vertical size="large" />
          <CustomButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            isLoading={loading}
          >
            {getComponentModeTitle(mode)}
          </CustomButton>
        </form>
        {isInInitialState && (
          <>
            <CustomSpacer vertical size="large" />
            <CustomSignoutButton fullWidth />
          </>
        )}
      </CustomCardContent>
    </CustomModal>
  )
}
