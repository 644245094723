/* eslint-disable array-callback-return */
import { FormControlLabel, Stack, Switch, Toolbar, Typography, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CustomIcon from 'components/CustomIcon';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContext } from 'src/context/MainContext';
import { COMMON_ICONS } from 'utils/icons';
import { version } from '../../../../package.json';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { DRAWER_WIDTH, IN_ROUTE_TYPES, LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { BASE_APP_ROUTES, MODULE_APP_ROUTES } from '../../utils/inAppRoutes';
import { CustomSignoutButton } from '../CustomSignoutButton';
import { CurrentUser } from './CurrentUser';
import { NavGroupedItem } from './NavGroupedItem';
import { NavItem } from './NavItem';

interface Props {
  open: boolean;
  handleDrawerToggle: () => void;
  onCloseDrawer: () => void;
}

export function CustomResponsiveDrawer(props: Props) {
  const { open, handleDrawerToggle, onCloseDrawer } = props;
  const { toggleColorMode } = useContext(MainContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { setValue } = useLocalStorage(LOCAL_STORAGE_KEYS.LAST_ROUTE);

  const redirectTo = (path: string) => {
    navigate(path);
    setValue(path);
  };

  const handleListItemClick = (path: string) => {
    redirectTo(path);
    onCloseDrawer();
  };

  const drawer = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Toolbar>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={theme.palette.mode === 'dark'}
                onChange={toggleColorMode}
              />
            }
            label={
              <CustomIcon
                icon={
                  theme.palette.mode === 'dark' ? COMMON_ICONS.DARK_MODE : COMMON_ICONS.LIGHT_MODE
                }
              />
            }
          />
        </Toolbar>
        <Divider />
        <CurrentUser />
        <Divider />
        <List>
          {MODULE_APP_ROUTES.map((route) => {
            if (route.type === IN_ROUTE_TYPES.ITEM) {
              return (
                <NavItem
                  key={route.path}
                  path={route.path}
                  onClick={() => handleListItemClick(route.path)}
                  icon={route.icon}
                  name={route.name}
                />
              );
            }
            if (route.type === IN_ROUTE_TYPES.GROUP) {
              return (
                <NavGroupedItem
                  key={route.name}
                  routes={route.routes}
                  onClick={handleListItemClick}
                  name={route.name}
                  icon={route.icon}
                />
              );
            }
          })}
        </List>
        <Divider />
        <List>
          {BASE_APP_ROUTES.map((route) => {
            if (route.type === IN_ROUTE_TYPES.ITEM) {
              return (
                <NavItem
                  key={route.path}
                  path={route.path}
                  onClick={() => handleListItemClick(route.path)}
                  icon={route.icon}
                  name={route.name}
                />
              );
            }
            if (route.type === IN_ROUTE_TYPES.GROUP) {
              return (
                <NavGroupedItem
                  key={route.name}
                  routes={route.routes}
                  onClick={handleListItemClick}
                  name={route.name}
                  icon={route.icon}
                />
              );
            }
          })}
        </List>
        <Divider />
      </div>
      <Stack sx={{ m: 2 }} flexDirection="column" alignItems="center">
        <CustomSignoutButton fullWidth />
        <Typography variant="caption">{`v${version}`}</Typography>
      </Stack>
    </div>
  );

  return (
    <>
      <Drawer
        SlideProps={{
          direction: 'left',
        }}
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: DRAWER_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: DRAWER_WIDTH,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
}
