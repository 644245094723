import { CardHeader, Stack, TextField } from '@mui/material';
import { CustomDatePicker } from 'components/CustomDatePicker';
import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { IPeriod } from '../../../../shared-resources/INTERFACES';
import CustomButton from '../../components/CustomButton';
import { CustomCardContent } from '../../components/CustomCardContent';
import CustomLoadingGuard from '../../components/CustomLoadingGuard';
import CustomModal from '../../components/CustomModal';
import CustomSpacer from '../../components/CustomSpacer';
import { useAppSelector } from '../../hooks/useReactRedux';
import { i18n } from '../../i18n/i18n';
import { postPeriod, putPeriod } from '../../utils/apiUtils';
import { APP_ROUTES, COMPONENT_MODES, DATE_PICKER_FORMAT } from '../../utils/constants';
import { getComponentModeTitle } from '../../utils/helpers';

type Props = {
  mode: COMPONENT_MODES;
  onClose: () => void;
};

const validationSchema = Yup.object().shape({
  endDate: Yup.string().required(i18n.t('translations:errors.required')),
  startDate: Yup.string().required(i18n.t('translations:errors.required')),
});

export function AddEditPeriod({ mode, onClose }: Props): React.ReactElement {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const { period } = useAppSelector((state) => state.currentPeriod);
  const navigate = useNavigate();
  const startDate =
    mode === COMPONENT_MODES.EDIT
      ? period.startDate
      : moment(period?.startDate).add(period?.startDate ? 1 : 0, 'month');
  const endDate =
    mode === COMPONENT_MODES.EDIT
      ? period.endDate
      : moment(period?.endDate).add(1, 'month').format();

  const formik = useFormik({
    initialValues: {
      startDate,
      endDate,
      _id: period?._id,
    } as IPeriod,
    validationSchema,
    onSubmit,
  });

  async function onSubmit() {
    if (mode === COMPONENT_MODES.ADD) {
      handlePostPeriod(formik.values);
    }
    if (mode === COMPONENT_MODES.EDIT) {
      handlePutExpense(formik.values);
    }
  }

  const handlePostPeriod = async (values: IPeriod) => {
    setLoading(true);
    try {
      const newPeriod = await postPeriod(values);
      onClose();
      navigate(`/${APP_ROUTES.PERIOD}/${newPeriod._id}`);
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePutExpense = async (values: IPeriod) => {
    setLoading(true);
    try {
      await putPeriod(values);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  // eslint-disable-next-line comma-spacing
  const handleChangeByKey = <T,>({ key, value }: { key: string; value: T }) => {
    formik.setFieldValue(key, value);
  };

  return (
    <CustomModal onClose={onClose}>
      <CustomLoadingGuard loading={loading}>
        <form
          style={{
            height: '100%',
          }}
          onSubmit={formik.handleSubmit}
        >
          <Stack
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            overflow="auto"
          >
            <Stack flexDirection="column">
              <CardHeader title={getComponentModeTitle(mode)} />
              <CustomCardContent>
                <CustomDatePicker
                  inputFormat={DATE_PICKER_FORMAT}
                  label={t('translations:common.startDate')}
                  value={formik.values.startDate}
                  onChange={(newDate) => {
                    handleChangeByKey({
                      key: 'startDate',
                      value: moment(newDate).format(),
                    });
                    handleChangeByKey({
                      key: 'endDate',
                      value: moment(newDate).add(1, 'month').format(),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                      helperText={
                        formik.touched.startDate && formik.errors.startDate
                          ? formik.errors.startDate
                          : ''
                      }
                    />
                  )}
                />
                <CustomSpacer vertical size="medium" />
                <CustomDatePicker
                  inputFormat={DATE_PICKER_FORMAT}
                  label={t('translations:common.endDate')}
                  value={moment(formik.values.endDate)}
                  onChange={(newDate) =>
                    handleChangeByKey({
                      key: 'endDate',
                      value: moment(newDate).format(),
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                      helperText={formik.touched.endDate && formik.errors.endDate}
                    />
                  )}
                />
                <CustomSpacer vertical size="large" />
              </CustomCardContent>
            </Stack>
            <CustomButton
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              isLoading={loading}
            >
              {getComponentModeTitle(mode)}
            </CustomButton>
          </Stack>
        </form>
      </CustomLoadingGuard>
    </CustomModal>
  );
}
